import { FETCH_TICKET_QUOTEIDS_START, FETCH_TICKET_QUOTEIDS_ERROR, FETCH_TICKET_QUOTEIDS_SUCCESS, FETCH_QUOTEID_DATA_START, FETCH_QUOTEID_DATA_ERROR, FETCH_QUOTEID_DATA_SUCCESS, FETCH_VENDOR_DUE_PAYMENT_SUCCESS, FETCH_REFERENCE_LIST_START, FETCH_REFERENCE_LIST_SUCCESS, FETCH_PROCESS_LIST_START, FETCH_PROCESS_LIST_SUCCESS, FETCH_VENDOR_DUE_START, FETCH_VENDOR_DUE_SUCCESS, FETCH_VENDOR_DUE_PAYMENT_START, FETCH_BANK_DATA_SUCCESS, FETCH_BANK_DATA_START, FETCH_VENDOR_SUMMARY_START, FETCH_VENDOR_SUMMARY_SUCCESS, FETCH_INVENTORY_SUCCESS, FETCH_INVOICE_DATA_SUCCESS, FETCH_REFERENCE_LIST_QUOTEID_SUCCESS } from './actiontypes';

import { db } from '../connection';
import { fetch, fetchOrderByChild } from '../helper/firebaseFunctions';
import { dateChanger } from '../helper/dateChanger';
import { debug } from 'util';
import moment from 'moment';

export function fetchvendorquoteids() {
	return (dispatch) => {
		dispatch({ type: FETCH_TICKET_QUOTEIDS_START })
		fetch('/delivery/webPortal', 'deliverySiteMapping')
			.then(value => {
				dispatch({ type: FETCH_TICKET_QUOTEIDS_SUCCESS, payload: value });
			}).catch(error => {
				dispatch({ type: FETCH_TICKET_QUOTEIDS_ERROR, payload: "Oops! Error" });
			})
	}
}


export function fetchquoteid(siteId) {
	return (dispatch) => {
		console.log(new Date().getTime());
		dispatch({ type: FETCH_QUOTEID_DATA_START })
		Promise.all([fetchSiteDataAndUser(siteId), fetch('sites', siteId), fetch('delivery/webPortal/sites', siteId), fetch('delivery/Issue/statusbysite/', siteId)])
			.then(value => {
				let siteData = value[0] ? value[0] : {};
				let deliveryData = value[1] ? value[1] : {};
				let webPortalData = value[2] ? value[2] : {};
				let otherData = value[3] ? value[3] : {};
				let dataMain = { ...siteData, ...deliveryData, ...webPortalData, ...otherData };
				let { vendor, inc, size_D, mSite_selectedSize, mUser_Contact, acdbDcdb = "", size_del = "" } = dataMain;
				let { mContact_FirstName, mContact_LastName, mContact_City } = mUser_Contact;
				let size = size_del ? size_del : (size_D ? size_D : mSite_selectedSize ? +mSite_selectedSize : 0);
				let data = { vendor, inc, size, name: `${mContact_FirstName} ${mContact_LastName}`, mContact_City, acdbDcdb };
				console.log(data);
				dispatch({ type: FETCH_QUOTEID_DATA_SUCCESS, payload: data });
			}).catch(error => {
				dispatch({ type: FETCH_QUOTEID_DATA_ERROR, payload: "Oops! Error" });
			})
	}
}

export function fetchvendorduepayment(vendorId) {
	console.log("fetchVendorDuePayment", vendorId);
	return (dispatch) => {
		dispatch({ type: FETCH_VENDOR_DUE_PAYMENT_START })
		let requestProcessedURL = `paymentsModule/vendorList/${vendorId}`;
		fetch(requestProcessedURL, "duePayment")
			.then(value => {
				dispatch({ type: FETCH_VENDOR_DUE_PAYMENT_SUCCESS, duePayment: value })
			})
			.catch(error => {
				console.log(error)
			})
	}
}

export function fetchVendorSummary(){
	return (dispatch) => {
		dispatch({ type: FETCH_VENDOR_SUMMARY_START })
		fetch("paymentsModule", "vendorList")
		.then(value => {
			console.log(value);
			let vendorSummary = [];
			Object.keys(value?value:{}).forEach(key => {
				vendorSummary.push({
					vendor:key,
					payment:value[key].duePayment
				})
			})
			dispatch({ type: FETCH_VENDOR_SUMMARY_SUCCESS, payload: vendorSummary })
		})
		.catch(error => {
			console.log(error)
		})
	}
}

export function fetchquoteidprocessedtransaction(vendorId) {
	console.log(vendorId);
	return (dispatch) => {
		dispatch({ type: FETCH_PROCESS_LIST_START });
		dispatch({ type: FETCH_VENDOR_DUE_START });
		let processedURL = `paymentsModule/processedList`;
		let requestProcessedURL = `paymentsModule/vendorList`;
		Promise.all([fetchOrderByChild(processedURL, 'vendor', vendorId), fetch(requestProcessedURL, vendorId)])
			.then(values => {
				let processedData = values[0];
				let requestedDetails = values[1];
				let processedDataPromise = processedData ? Object.keys(processedData).map(key => {
					return fetchUserInfo(processedData[key].responsibleAccount, processedData[key])
				}) : [];
				let requestedDetailsPromise = requestedDetails ? Object.keys(requestedDetails).map(key => {
					if (key !== "duePayment") {
						return fetchUserInfo(requestedDetails[key].responsible, requestedDetails[key])
					}
				}) : [];
				let combinePromise = [...processedDataPromise, ...requestedDetailsPromise];
				Promise.all(combinePromise)
					.then(values => {
						let finalData = values.filter(val => val !== undefined);
						let ledgerData = finalData.map(row => {
							row.dateView = row.date?moment(parseInt(row.date)).format("DD MMM, YYYY HH:mm:ss"):"";
							row.acceptedOnView = row.acceptedOn?moment(parseInt(row.acceptedOn)).format("DD MMM, YYYY HH:mm:ss"):"";
							return row;
						})
						dispatch({ type: FETCH_PROCESS_LIST_SUCCESS, payload: ledgerData });
						dispatch({ type: FETCH_VENDOR_DUE_SUCCESS, payload: requestedDetails ? requestedDetails.duePayment : "Not Present" });
					}).catch(error => {
						console.log(error);
					})
			}).catch(error => {
				console.log(error);
			})
	}
}


export function fetchBankTransactions(bankName){
	console.log("Bank Name", bankName);
	return (dispatch) => {
		dispatch({ type: FETCH_BANK_DATA_START });
		console.log("bank");
		fetchBankData(bankName).then(data => {
			console.log(data);
			dispatch({ type: FETCH_BANK_DATA_SUCCESS, payload: data });
		})
		.catch(e => {
			console.log(e);
		})
	}
}

async function fetchBankData(bank){
	try{
		let processedList = (await db.ref(`paymentsModule/processedList`).once('value')).val();
		let bankData = [];
		Object.values(processedList?processedList:{}).map(val => {
			if(val.bankName && val.bankName == bank){
				val.viewDate = val.date?moment(parseInt(val.date)).format("DD MMM, YYYY HH:mm:ss"):'';
				bankData.push(val);
			}
		});
		console.log(bankData);
		return bankData;
	}
	catch(e){
		console.log("BANK FETCH ERROR", e);
		return e;
	}
}

// FETCH REQUEST LIST

export function fetchrequestlist() {
	return (dispatch) => {
		fetch('paymentsModule', 'requestList')
			.then(val => {
				// let data = {};

				// let promiseMap = Object.keys(val).map(key => {
				// 	return fetch('users', val[key].responsible)
				// 		.then(userInfo => {
				// 			let { mUser_Contact } = userInfo;
				// 			let { mContact_FirstName, mContact_LastName } = mUser_Contact;
				// 			return { ...val[key], responsible: `${mContact_FirstName} ${mContact_LastName}` }
				// 		}).catch(error => {
				// 			return error;
				// 		})
				// });
				// Promise.all(promiseMap).then(value => {

				// 	let finalData = {};
				// 	value.forEach(val => {
				// 		finalData[val.requestKey] = val;
				// 	})
					dispatch({ type: FETCH_REFERENCE_LIST_SUCCESS, payload: val });
				// }).catch(error => {
				// 	console.log(error);
				// });
			}).catch(error => {
				console.log(error);
			});
	}
}

export function fetchrequestlistbyquoteid(quoteId, transactionType, status = []) {
	return (dispatch) => {
		let statusMap = [ "", "Approved 1", "Approved 2", "Processed" ];
		fetchOrderByChild('paymentsModule/requestList', 'quoteId', quoteId)
			.then(val => {
				let data = Object.values(val || {}).filter(row => {
					return row.transactionType == transactionType &&
					status.includes(row.status)
				}).map(row => {
					return {
						date: row.date,
						vendor: row.vendor,
						transactionType: row.transactionType,
						size: row.sizeP,
						amount: row.price,
						status: statusMap[row.status]
					}
				});
				dispatch({ type: FETCH_REFERENCE_LIST_QUOTEID_SUCCESS, payload: data });
			}).catch(error => {
				console.log(error);
			});
	}
}

// FETCH REQUEST SUMMARY
export function fetchrequestsummary(list) {
	return (dispatch) => {
		let promiseMap = list.map(key => {
			return fetch('paymentsModule/processLedgerData', key)
		});
		Promise.all(promiseMap).then(val => {
			console.log(val);
		}).catch(error => {
			console.log(error);
		})
	}
}


/////////////////////
// HELPER FUNCTION //
/////////////////////

// FORM DATA

function fetchSiteDataAndUser(siteId) {
	return new Promise((resolve, reject) => {
		fetch('delivery/sites', siteId)
			.then(value => {
				let { siteOwnerId, responsilbleIdDelevery } = value;
				Promise.all([fetch('users', siteOwnerId), fetch('users', responsilbleIdDelevery)])
					.then(userObj => {
						let { mUser_Contact } = userObj[1];
						let { mContact_FirstName, mContact_LastName } = mUser_Contact;
						let responsibleName = `${mContact_FirstName} ${mContact_LastName}`;
						resolve({ ...value, ...userObj[0], responsibleName })
					}).catch(error => {
						console.log(error);
					})
			}).catch(error => {
				reject(error);
				return;
			});
	});
}


function fetchUserInfo(userId, data) {
	return new Promise((resolve, reject) => {
		fetch('users', userId)
			.then(val => {
				let { mUser_Contact } = val;
				let { mContact_FirstName, mContact_LastName } = mUser_Contact;
				let responsibleName = `${mContact_FirstName} ${mContact_LastName}`;
				resolve({ ...data, responsible: responsibleName });
			})
			.catch(error => {
				reject(error);
			})
	});
}
// TABLE DATA

export function fetchInvoiceData(){
	return (dispatch) => {
		db.ref(`paymentsModule/invoices`).once('value')
		.then(snapshot => {
			dispatch({ type: FETCH_INVOICE_DATA_SUCCESS, payload: snapshot.val() || {} });
		})
		.catch(e => {
			console.log(e);
		})
	}
}
// Inventory

export function fetchInventory(){
	return (dispatch) => {
		inventoryData().then(data => {
			dispatch({type:FETCH_INVENTORY_SUCCESS, payload:data})
		})
	}	
}


async function inventoryData(){
	try{
		let data = (await db.ref(`paymentsModule/inventory`).once('value')).val();
		let inventoryData = {};
		Object.keys(data?data:{}).map(type => {
			Object.values(data[type]).map(vendorData => {
				inventoryData[type] = inventoryData[type]?[...inventoryData[type],...Object.values(vendorData)]:[...Object.values(vendorData)]
			})
		})
		return inventoryData?inventoryData:{};
	}
	catch(e){
		return {};
	}
}