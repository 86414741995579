import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom';

import ReactTable from "react-table";

import { styleSheet } from '../styles/styleSheet';

import { fetchvendorquoteids, fetchquoteid, fetchrequestlist, fetchrequestsummary, fetchquoteidprocessedtransaction, fetchvendorduepayment, fetchBankTransactions, fetchVendorSummary, fetchInvoiceData, fetchInventory } from '../actions/fetchvendortickets';
import { requestNewPayment, approvePayment, requestNewTransaction, updateInvoiceData, uploadInvoiceFile, downloadInvoice, updateInvoiceApprovalData, approveRejectSeletedPayments } from '../actions/setvendortickets';
import { fetchmapping } from '../actions/fetchmapping';

import { VendorButtonComponent, InputDynamicType, ModalReceiptView, ProcessedTable, FilterView, PurchaseSellFields, FilterViewInventory } from '../components/VendorComponent.jsx';
import { Restrict } from '../components/Restrict';
import { Loader } from "../components/Loader.jsx";
import { columnPaymentReference, columnPaymentCompleteReference, columnBanks, columnPaymentSummary, columnInvoices, panelsInventory, inverterInventory, acdbInventory } from '../config/column_fields';
import { changeToViewDate, changeToViewDateTime, changeToInputDate } from '../helper/dateChanger';
import { CSVLink } from 'react-csv';
import { priceCalculator, access, purchaseFormAccess, sellFormAccess } from '../helper/vendorHelper';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import '../styles/all.css'
import { debug } from 'util';
import { timingSafeEqual } from 'crypto';

const inputObject = function (type, label, name, defaultValue, value, divType, placeholder, handleOnChange, options, actualValue) {
	this.type = type;
	this.name = name;
	this.label = label;
	this.defaultValue = defaultValue;
	this.value = value;
	this.divType = divType;
	this.placeholder = placeholder;
	this.handleOnChange = handleOnChange;
	this.options = options;
	this.actualValue = actualValue;
}

const brandOptions = [
	{ value: 'vikram', label: 'Vikram' },
	{ value: 'adani', label: 'Adani' },
	{ value: 'tata', label: 'TATA' },
	{ value: 'trina', label: 'Trina' },
	{ value: 'canadian solar', label: 'Canadian Solar' },
	{ value: 'RenewSys', label: 'RenewSys' },
	{ value: 'Alpex Solar', label: 'Alpex Solar' },
	{ value: 'ZunRoof', label: 'ZunRoof' },
	{ value: 'Rayzon', label: 'Rayzon' },
	{ value: 'Saatvik', label: 'Saatvik' },
	{ value: 'Waaree', label: 'Waaree' }
];
const panelTypeOptions = [
	{ value: 'poly 300', label: 'Poly 300' },
	{ value: 'poly 310', label: 'Poly 310' },
	{ value: 'poly 315', label: 'Poly 315' },
	{ value: 'poly 320', label: 'Poly 320' },
	{ value: 'poly 325', label: 'Poly 325' },
	{ value: 'poly 330', label: 'Poly 330' },
	{ value: 'mono 340', label: 'Mono 340' },
	{ value: 'mono 345', label: 'Mono 345' },
	{ value: 'poly 335', label: 'Poly 335' },
	{ value: 'mono 370', label: 'Mono 370' },
	{ value: 'mono 375', label: 'Mono 375' },
	{ value: 'mono 380', label: 'Mono 380' },
	{ value: 'poly 340', label: 'Poly 340' },
	{ value: 'mono 365', label: 'Mono 365' },
	{ value: 'mono 395', label: 'Mono 395' },
	{ value: 'mono 400', label: 'Mono 400' },
	{ value: 'mono 405', label: 'Mono 405' },
	{ value: 'mono 530', label: 'Mono 530' },
	{ value: 'mono 535', label: 'Mono 535' },
	{ value: 'mono 540', label: 'Mono 540' },
	{ value: 'mono 545', label: 'Mono 545' },
	{ value: 'mono 560', label: 'Mono 560' }
];

const inverterOptions = [
	{ value: 'Growatt', label: 'Growatt' },
	{ value: 'Solax', label: 'Solax' },
	{ value: 'SAJ', label: 'SAJ' },
	{ value: 'Delta', label: 'Delta' },
	{ value: 'ABB', label: 'ABB' },
	{ value: 'EVVO', label: 'EVVO' },
	{ value: 'Enertech', label: 'Enertech' },
	{ value: 'Ksolare', label: 'Ksolare' },
	{ value: 'PowerOne', label: 'PowerOne' },
	{ value: 'LivGuard', label: 'LivGuard' },
	{ value: 'Solis', label: 'Solis' },
	{ value: 'Havells', label: 'Havells' },
	{ value: 'Invergy', label: 'Invergy' },
	{ value: 'Goodwe', label: 'Goodwe' },
	{ value: 'Deye', label: 'Deye' }
];
const materialOptions = [
	{ value: 'Structure', label: 'Structure' },
	{ value: 'Battery', label: 'Battery' }
]
const v2PaymentOptions = [
	{ value: 'p1', label: 'P1' },
	{ value: 'p2', label: 'P2' },
	{ value: 'p3', label: 'P3' },
	{ value: 'p4', label: 'P4' }
];
const v1PaymentOptions = [
	{ value: 'p1', label: 'P1' },
	{ value: 'p2', label: 'P2' },
	{ value: 'p3', label: 'P3' },
	{ value: 'p4', label: 'P4' },
	{ value: 'adj', label: 'Adj' }
];
const otherTransactionOptions = [
	{ value: 'Panel Refund', label: 'Panel Refund' },
	{ value: 'Inverter refund', label: 'Inverter refund' },
	{ value: 'V2 Refund', label: 'V2 Refund' },
	{ value: 'V1 Refund', label: 'V1 Refund' },
	{ value: 'TDS', label: 'TDS' },
	{ value: 'Adjustment', label: 'Adjustment' },
	{ value: 'Structure Refund', label: 'Structure Refund' },
	{ value: 'Other Refund', label: 'Other Refund' },
	{ value: 'Transporter Payment', label: 'Transporter Payment' },
	{ value: 'TCS', label: 'TCS' }
];

const transactionOptions = [
	{ value: 'panels', label: 'Panels' },
	{ value: 'inverter', label: 'Inverter' },
	{ value: 'structure', label: 'Structure' },
	{ value: 'v2', label: 'V2' },
	{ value: 'v1', label: 'V1' },
	{ value: 'Panel Refund', label: 'Panel Refund' },
	{ value: 'Inverter refund', label: 'Inverter refund' },
	{ value: 'V2 Refund', label: 'V2 Refund' },
	{ value: 'V1 Refund', label: 'V1 Refund' },
	{ value: 'TDS', label: 'TDS' },
	{ value: 'Adjustment', label: 'Adjustment' },
	{ value: 'Structure Refund', label: 'Structure Refund' },
	{ value: 'Other Refund', label: 'Other Refund' },
	{ value: 'Transporter Payment', label: 'Transporter Payment' },
	{ value: 'TCS', label: 'TCS' }
];

const phaseOptions = [
	{ value: 'Single', label: 'Single' },
	{ value: 'Triple', label: 'Triple' },
];

const progressStatus = ["Initiated", "Approved 1", "Approved 2", "Processed", "Rejected 1", "Rejected 2", "Approved 0", "Rejected 0"];

const bankOptions = [
	{ value: 'IDFC', label: 'IDFC 2' },
	{ value: 'HDFC', label: 'HDFC' },
	{ value: 'RBL', label: 'RBL' },
	{ value: 'CITI', label: 'CITI' },
	{ value: 'cashfree', label: 'Cashfree' },
	{ value: 'TCS', label: 'TCS' }
]

const acdbMakeOptions = [
	{ value: 'D1', label: 'D1' },
	{ value: 'D2', label: 'D2' },
	{ value: 'D2+', label: 'D2+' },
	{ value: 'A1', label: 'A1' },
	{ value: 'A2', label: 'A2' }
]

const cityOptions = [
	{ value: 'Lucknow', label: 'Lucknow' },
	{ value: 'Hyderabad', label: 'Hyderabad' },
	{ value: 'Ahmedabad', label: 'Ahmedabad' },
	{ value: 'Bangalore', label: 'Bangalore' },
	{ value: 'Gurgaon', label: 'Gurgaon' }
]

const businessTypeOptions = [
	{ label: "B2B", value: "B2B" },
	{ label: "B2C", value: "B2C" }
]

class Vendor extends Component {

	constructor(props) {
		super(props);
		this.reactTable = React.createRef();
		this.downloadRef = React.createRef();
		this.downloadInvoiceRef = React.createRef();
		this.state = {
			recieved: false,
			error: null,
			bankTransactions: [],
			quoteIdList: [],
			quoteIdProcess: [],
			statusFilter:[],
			paymentTypeFilter: [],
			quoteIdProcessTransaction: [],
			processLedgerData: [],
			bankData: [],
			processLedgerDataMain: [],
			transactionTypeFilter: [],
			transactionTypeFilterInvoice: [],
			invoiceTransactions: [],
			tableData: [],
			tableDataMain: [],
			vendorMap: [],
			filterStartDate: 0,
			filterEndDate: 9999999999999,
			dueAmount: null,
			recieptView: false,
			currentSelected: 'panels',
			vendorSummary: [],
			inventoryData:{},
			vendorMapping:{},
			openWarningsModal:false,
			warnings:[],
			invoiceTableData:[],
			invoiceData:{},
			invoiceFile:'',
			transactionViewData: [],
			invoiceViewData: [],
			inventoryVendorSelected: [],
			inventoryMakeSelected: [],
			inventoryBrandSelected: [],
			inventoryPhaseSelected: [],
			inventoryCitySelected: [],
			quoteId_Structure: new inputObject("text", "Quote ID", "quoteId", "", "", "selectDiv", "Quote ID", this.handleOnSelectChange, []),
			name_Structure: new inputObject("text", "Name", "name", "", "", "inputDiv", "Name", this.handleInputChange, []),
			epc_Structure: new inputObject("text", "EPC", "epc", "", "", "inputDiv", "EPC", this.handleInputChange, []),
			brand_Structure: new inputObject("text", "Brand", "brand", "", "", "selectDiv", "Brand", this.handleOnSelectChange, brandOptions),
			make_Structure: new inputObject("text", "Make", "make", "", "", "selectDiv", "Make", this.handleOnSelectChange, panelTypeOptions),
			date_Structure: new inputObject("date", "Date", "date", changeToInputDate(new Date()), changeToInputDate(new Date()), "inputDiv", "Date", this.handleInputChange, [], new Date().getTime()),
			sizeP_Structure: new inputObject("text", "System Size", "sizeP", "", "", "inputDiv", "System Size", this.handleInputChange, []),
			exactWatt_Structure: new inputObject("text", "Exact Watt", "exactWatt", "", "", "inputDiv", "Exact Watt", this.handleInputChange, []),
			perWatt_Structure: new inputObject("text", "Per Watt Price", "perWatt", "", "", "inputDiv", "Per Watt Price", this.handleInputChange, []),
			payment_Structure: new inputObject("text", "Payment %", "payment", "", "", "inputDiv", "Payment", this.handleInputChange, []),
			freight_Structure: new inputObject("text", "Freight", "freight", "", "", "inputDiv", "Freight", this.handleInputChange, []),
			gst_Structure: new inputObject("text", "GST %", "gst", "12", "12", "inputDiv", "GST", this.handleInputChange, [], "12"),
			price_Structure: new inputObject("text", "Price", "price", "", "", "inputDiv", "Price", this.handleInputChange, []),
			vendor_Structure: new inputObject("text", "vendor", "vendor", "", "", "selectDiv", "vendor", this.handleOnSelectChange, []),
			reference_Structure: new inputObject("text", "Reference", "reference", "", "", "inputDiv", "Reference", this.handleInputChange, []),
			status_Structure: new inputObject("text", "Status", "status", "", "", "inputDiv", "Status", this.handleInputChange, []),
			invoiceNumber_Structure: new inputObject("text", "Invoice No.", "invoiceNumber", "", "", "inputDiv", "Invoice No.", this.handleInputChange, []),
			transactionType_Structure: new inputObject("text", "Trans_Type", "transactionType", "", "", "selectDiv", "Trans_Type", this.handleOnSelectChange, otherTransactionOptions),
			inverterSize_Structure: new inputObject("text", "Inverter Size", "inverterSize", "", "", "inputDiv", "Inverter Size", this.handleInputChange, []),
			costOfInverter_Structure: new inputObject("text", "Inverter Cost", "costOfInverter", "", "", "inputDiv", "Cost of Inverter", this.handleInputChange, []),
			costOfMatrial_Structure: new inputObject("text", "Material Cost", "costOfMatrial", "", "", "inputDiv", "Cost of Material", this.handleInputChange, []),
			installerFee_Structure: new inputObject("text", "Installer Fee", "installerFee", "", "", "inputDiv", "Per Kw", this.handleInputChange, []),
			zunroofFeeInput_Structure: new inputObject("text", "Zunroof Fee Input", "zunroofFeeInput", "", "", "inputDiv", "Zunroof Fee Input", this.handleInputChange, []),
			phase_Structure: new inputObject("text", "Phase", "phase", "", "", "selectDiv", "phase", this.handleOnSelectChange, phaseOptions),
			installerCharge_Structure: new inputObject("text", "Installer", "installerCharge", "", "", "inputDiv", "Installer Charge", this.handleInputChange, []),
			material_Structure: new inputObject("text", "Material", "material", "", "", "selectDiv", "Material", this.handleOnSelectChange, materialOptions),
			paymentType_Structure: new inputObject("text", "Payment Type", "paymentType", "", "", "selectDiv", "Payment Type", this.handleOnSelectChange, v2PaymentOptions),
			v1Charge_Structure: new inputObject("text", "V1 Charge", "v1Charge", "", "", "inputDiv", "V1 Charge", this.handleInputChange, []),
			zunroofFee_Structure: new inputObject("text", "Zunroof Fee", "zunroofFee", "", "", "inputDiv", "Zunroof Fee", this.handleInputChange, []),
			invoiceAmount_Structure: new inputObject("text", "Invoice Amount", "invoiceAmount", "", "", "inputDiv", "Invoice Amount", this.handleInputChange, []),
			comments_Structure: new inputObject("text", "Comments", "comments", "", "", "inputDiv", "Comments", this.handleInputChange, []),
			adjustmentAmount_structure: new inputObject("text", "Adjustment Amount", "adjustmentAmount", "", "", "inputDiv", "Adjustment Amount", this.handleInputChange, []),
			systemPrice_Structure: new inputObject("text", "System Price", "systemPrice", "", "", "inputDiv", "System Price", this.handleInputChange, []),
			bankName_Structure: new inputObject("text", "Bank", "bankName", "", "", "selectDiv", "Bank", this.handleOnSelectChange, bankOptions),
			purchaseVendor_Structure:new inputObject("text", "Purchase vendor", "purchaseVendor", "", "", "selectDiv", "Purchase vendor", this.handleOnSelectChange, []),
			count_Structure:new inputObject("text", "Count", "count", "", "", "inputDiv", "Count", this.handleInputChange, []),
			perUnitPrice_Structure: new inputObject("text", "Per Unit Price", "perUnitPrice", "", "", "inputDiv", "Per Unit Price", this.handleInputChange, []),
			city_Structure: new inputObject("text", "City", "city", "", "", "selectDiv", "City", this.handleOnSelectChange, cityOptions),
			businessType_Structure: new inputObject("text", "Type", "businessType", "", "", "selectDiv", "Type", this.handleOnSelectChange, businessTypeOptions),
			inventoryConfig:{
				'panels': panelsInventory,
				'inverter': inverterInventory,
				'ACDB': acdbInventory,
				'DCDB': acdbInventory
			},
			selectedTransactions: {}
		}
	}

	componentWillMount() {
		let { checkReciptSummary, onApproveClick, checkWarningsApproval, checkWarningsProcurement, updateInvoiceRequiredStatus, updateInvoiceApprovalStatus, uploadInvoice, downloadInvoice, onFileUpload, onCheckTransaction, canSelectCheckbox, onChangeSelectAll } = this;
		let { user, fetchvendorquoteids, fetchrequestlist, fetchmapping, fetchVendorSummary, fetchInvoiceData, fetchInventory } = this.props;
		let { selectedTransactions } = this.state;
		if (user && user.level >= 1600) {
			fetchvendorquoteids();
			fetchrequestlist();
			fetchmapping();
			fetchVendorSummary();
			fetchInvoiceData();
			fetchInventory();
			this.setState({
				currentSelected: user.level === 1800 ? "accounts" : user.level >= 1600 ? this.state.currentSelected : "",
				formName: user.level === 1800 ? "Accounts" : user.level >= 1600 ? this.state.formName : ""
			});
		}
		let columnUpdate = columnPaymentReference.map(val => {
			let columnData = { ...val };
			if (columnData.accessor === 'checkbox') {
				columnData.Cell = row => canSelectCheckbox(row.original)?<div><input 
						type="checkbox"
						onChange = {(e) => onCheckTransaction(e, row.original.requestKey)}
						checked = {this.state.selectedTransactions[row.original.requestKey] ? true : false}
						// checked={true}
					/></div>:"-Disabled-"
				columnData.Header = (user && (user.level == 1800 || user.level == 2600)) ? <div><input 
											type="checkbox"
											onChange={(e) => onChangeSelectAll(e)}
										/></div> : "-Disabled-"
			}
			if (columnData.accessor === 'requestKey') {
				columnData.Cell = row => <a href="javascript:void(0);"><div onClick={() => this.onOpenTransactionModal(row.original.requestKey)}>{row.original.requestKey}</div></a>
			}
			if (columnData.accessor === 'leisureIdList') {
				columnData.Cell = row => <div><Link to="#" onClick={() => { checkReciptSummary(row.value, 'recieptView') }}>Click to View</Link></div>
			}
			if (columnData.accessor === 'approvalStatus') {
				columnData.Cell = row => {
					return row.original.status === 0 && (this.props.user.phoneNumber == '+918448389952' || this.props.user.phoneNumber == '+919205893362' || this.props.user.phoneNumber == '+918448580352' || this.props.user.phoneNumber == '+918448389958' || this.props.user.level == 2600  || (row.original.transactionType == 'TDS' && this.props.user.level == 1800)) ? (
						<div className="btn-approval-main">
							<div className="btn-approval-child">
								<button className="btn btn-success" onClick={() => { (row.original.type=='Purchase'||row.original.type=='Sell')?checkWarningsProcurement(row.original.type, 'approval', row, 6):checkWarningsApproval(row, 6) }}>Accept 0</button>
							</div>
							<div className="btn-approval-child">
								<button className="btn btn-warning" onClick={() => { onApproveClick(row, 7) }}>Reject 0</button>
							</div>
						</div>
					) : row.original.status === 6 && (this.props.user.level >= 2500 || (row.original.transactionType == 'TDS' && this.props.user.level == 1800)) ? (
						<div className="btn-approval-main">
							<div className="btn-approval-child">
								<button className="btn btn-success" onClick={() => { (row.original.type=='Purchase'||row.original.type=='Sell')? checkWarningsProcurement(row.original.type, 'approval', row, 1):checkWarningsApproval(row, 1) }}>Accept 1</button>
							</div>
							<div className="btn-approval-child">
								<button className="btn btn-warning" onClick={() => { onApproveClick(row, 4) }}>Reject 1</button>
							</div>
						</div>
					) : row.original.status === 1 && (this.props.user.level === 1800 || this.props.user.level === 2600) ? (
						<div className="btn-approval-main">
							<div className="btn-approval-child">
								<button className="btn btn-success" onClick={() => { onApproveClick(row, 2) }}>Accept 2</button>
							</div>
							<div className="btn-approval-child">
								<button className="btn btn-warning" onClick={() => { onApproveClick(row, 5) }}>Reject 2</button>
							</div>
						</div>
					) : progressStatus[row.original.status]
				}
			}
			if(columnData.accessor == 'inverterSize'){
				columnData.Cell = row => <div>{row.original.inverterSize?row.original.inverterSize:row.original.sizeP}</div>
			}
			return columnData;
		});
		let invoiceColumnUpdate = columnInvoices.map(val => {
			let columnData = { ...val };
			if (columnData.accessor === 'invoiceStatus') {
				columnData.Cell = row => {
					let { invoiceData = {} } = this.state;
					let invoiceStatus = (invoiceData[row.original.requestKey] && invoiceData[row.original.requestKey].invoiceStatus)?(invoiceData[row.original.requestKey].invoiceStatus):0;
					return !invoiceStatus?(
						<div className="btn-approval-main" style={{textAlign:'center'}}>
							<div className="btn-approval-child">
								<button className="btn btn-success" onClick={() => { updateInvoiceRequiredStatus(row, 1) }}>Yes</button>
							</div>
							<div className="btn-approval-child">
								<button className="btn btn-warning" onClick={() => { updateInvoiceRequiredStatus(row, 3) }}>No</button>
							</div>
						</div>
					) : invoiceStatus == 1 ? (
						<div>
						<input type="file" onChange={(e) => onFileUpload(e)}/>
						<button className="btn btn-success" onClick={() => { uploadInvoice(row) }}>Upload</button>
						</div>
					) : invoiceStatus == 2 ? (
						<button className="btn btn-success" onClick={() => { downloadInvoice(row, 1) }}>Download</button>
					) : 'Not Required'
				}
			}
			if (columnData.accessor === 'approvalStatus') {
				columnData.Cell = row => {
					let { invoiceData = {} } = this.state;
					let invoiceStatus = (invoiceData[row.original.requestKey] && invoiceData[row.original.requestKey].invoiceStatus)?(invoiceData[row.original.requestKey].invoiceStatus):0;
					let approvalStatus = (invoiceData[row.original.requestKey] && invoiceData[row.original.requestKey].approvalStatus)?(invoiceData[row.original.requestKey].approvalStatus):0;
					return invoiceStatus == 2?(
						!approvalStatus&&(this.props.user.level == 1800 || this.props.user.level == 2600)?(<div className="btn-approval-main" style={{textAlign:'center'}}>
							<div className="btn-approval-child">
								<button className="btn btn-success" onClick={() => { updateInvoiceApprovalStatus(row, 1) }}>Approve</button>
							</div>
							<div className="btn-approval-child">
								<button className="btn btn-warning" onClick={() => { updateInvoiceApprovalStatus(row, 2) }}>Reject</button>
							</div>
						</div>
						): (approvalStatus == 1 ? 'Approved':approvalStatus == 2?'Rejected':'')
					) : ""
				}
			}
			return columnData;
		});
		this.setState({ 
			columnPaymentReference: columnUpdate,
			columnInvoice: invoiceColumnUpdate
		});
	}

	componentWillReceiveProps({ loading, quoteIdList, quoteIdDetails, dataReference, employeeMap, vendorMap, dueAmount, processLedgerData, error, success, duePayment, bankData, vendorSummary, invoiceData, inventoryData }) {
		if(success) {
			this.resetForm();
		}
		if (error) {
			// this.setState({
			// 	error
			// });
		}
		if (duePayment >= 0) {
			let { currentSelected } = this.state;
			let price = priceCalculator(currentSelected, { ...this.state, duePayment });
			let keys = Object.keys(price);
			let values = Object.values(price);
			let setObject = {};
			keys.forEach((key, index) => {
				setObject[key] = { ...this.state[key], value: values[index], actualValue: values[index], defaultValue: values[index] }
			});
			this.setState(setObject);
		}

		if (Object.keys(quoteIdList).length > 0) {
			this.setState({
				recieved: true,
				quoteId_Structure: { ...this.state.quoteId_Structure, options: Object.keys(quoteIdList).map(siteId => { return { label: quoteIdList[siteId], value: siteId } }) }
			})
		}

		if (vendorMap) {
			let columnUpdate = this.state.columnPaymentReference.map(val => {
				let columnData = { ...val };
				if (columnData.accessor === 'vendor') {
					columnData.Cell = row => <div>{vendorMap[row.value] ? vendorMap[row.value].name : ""}</div>
				}
				return columnData;
			});
			let columnInvoiceUpdate = this.state.columnInvoice.map(val => {
				let columnData = { ...val };
				if (columnData.accessor === 'vendor') {
					columnData.Cell = row => <div>{vendorMap[row.value] ? vendorMap[row.value].name : ""}</div>
				}
				return columnData;
			});
			this.setState({
				columnPaymentReference: columnUpdate,
				columnInvoice:columnInvoiceUpdate,
				vendorMap: Object.values(vendorMap).map(val => { return { label: val.name, value: val.id } }),
				vendor_Structure: { ...this.state.vendor_Structure, options: Object.values(vendorMap).map(val => { return { label: val.name, value: val.id } }) },
				purchaseVendor_Structure: { ...this.state.purchaseVendor_Structure, options: Object.values(vendorMap).map(val => { return { label: val.name, value: val.id } }) },
				vendorMapping: vendorMap
			})
			this.changeVendorOptions(this.state.currentSelected);
		}
		let vendorPaymentSummary = [];
		if (vendorMap && vendorSummary) {
			vendorSummary.forEach(data => {
				vendorPaymentSummary.push({
					payment: data.payment,
					vendor: vendorMap[data.vendor] ? vendorMap[data.vendor].name : ''
				})
			})
		}
		let updatedInventoryData = {};
		if(inventoryData && vendorMap){
			if(inventoryData.panels){
				updatedInventoryData.panels = inventoryData.panels.map(val => {
					val.vendorName = vendorMap[val.vendor] ? vendorMap[val.vendor].name : '';
					return val;
				})
			}
			if(inventoryData.inverter){
				updatedInventoryData.inverter = inventoryData.inverter.map(val => {
					val.vendorName = vendorMap[val.vendor] ? vendorMap[val.vendor].name : '';
					return val;
				})
			}
			if(inventoryData.ACDB){
				updatedInventoryData.ACDB = inventoryData.ACDB.map(val => {
					val.vendorName = vendorMap[val.vendor] ? vendorMap[val.vendor].name : '';
					return val;
				})
			}
			if(inventoryData.DCDB){
				updatedInventoryData.DCDB = inventoryData.DCDB.map(val => {
					val.vendorName = vendorMap[val.vendor] ? vendorMap[val.vendor].name : '';
					return val;
				})
			}
		}
		let bankTableData = bankData;
		if(bankData && vendorMap){
			bankTableData = bankData.map(row => {
				row.vendorName = vendorMap[row.vendor] ? vendorMap[row.vendor].name : '';
				return row;
			})
		}
		let { name, size, vendor, inc, acdbDcdb } = quoteIdDetails;
		let { currentSelected } = this.state;
		if(currentSelected == 'v2' && vendorMap){
			let defaultV2 = Object.values(vendorMap).find(val => val.name == inc);
			if(defaultV2){
				defaultV2 = {label:defaultV2.name, value: defaultV2.id};
				this.setState({
					vendor_Structure:{ ...this.state.vendor_Structure, value: defaultV2, actualValue: defaultV2.value }
				})
			}
		}
		let invoiceTableData = Object.values(dataReference).filter(transaction => (transaction.status == 2 || transaction.status == 3))
		this.setState({
			dueAmount: dueAmount,
			processLedgerData: processLedgerData ? processLedgerData : [],
			processLedgerDataMain: processLedgerData ? processLedgerData : [],
			bankData: bankTableData,
			tableData: dataReference,
			tableDataMain: dataReference,
			name_Structure: { ...this.state.name_Structure, value: name, defaultValue: name, actualValue: name },
			sizeP_Structure: { ...this.state.sizeP_Structure, value: size, defaultValue: size, actualValue: size },
			epc_Structure: { ...this.state.epc_Structure, value: vendor, defaultValue: vendor, actualValue: vendor },
			acdbDcdb_Structure: { ...this.state.acdbDcdb_Structure, value: acdbDcdb, defaultValue: acdbDcdb, actualValue: acdbDcdb },
			status_Structure: { ...this.state.status_Structure, value: "Initiated", defaultValue: "Initiated", actualValue: 0 },
			transactionType_Structure: { ...this.state.transactionType_Structure, value: this.state.currentSelected, defaultValue: this.state.currentSelected, actualValue: this.state.currentSelected },
			vendorSummary: vendorPaymentSummary,
			invoiceData,
			invoiceTableData,
			invoiceTableViewData: invoiceTableData,
			inventoryData:updatedInventoryData,
			inventoryDataView: updatedInventoryData
		}, () => { this.applyFilter() });

	}

	canSelectCheckbox = (row) => {
		return ((row.transactionType == 'TDS' || row.status == 1) && (this.props.user.level == 1800 || this.props.user.level == 2600) && ([0, 1, 6].includes(row.status)));
	}

	onCheckTransaction = (e, requestKey) => {
		let value = e.target.checked;
		this.setState(prevState => ({
			selectedTransactions: {
				...prevState.selectedTransactions,
				[requestKey]: value
			}
		}))
	}

	onChangeSelectAll = (e) => {
		let value = e.target.checked;
		if(!this.reactTable.current) return;
		if(!value) {
			this.setState({ selectedTransactions: {} });
			return;
		}
		let selectedTransactions = {};
		this.reactTable.current.getResolvedState().sortedData.forEach(row => {
			if(this.canSelectCheckbox(row._original)) selectedTransactions[row._original.requestKey] = value;
		})
		this.setState({ selectedTransactions });
	}

	onApproveRejectSelected = (value) => {
		// console.log("onApproveRejectSelected", value);
		// console.log(this.state.selectedTransactions);
		let { dataReference, approveRejectSeletedPayments } = this.props;
		let { selectedTransactions } = this.state;
		approveRejectSeletedPayments({ dataReference, value, selectedTransactions });
		this.setState({
			selectedTransactions: {}
		})
	}

	updateInvoiceRequiredStatus = (row, value) => {
		this.props.updateInvoiceData({ id: row.original.requestKey, value, invoiceData:this.state.invoiceData });
	}

	updateInvoiceApprovalStatus = (row, value) => {
		this.props.updateInvoiceApprovalData({ id: row.original.requestKey, value, invoiceData:this.state.invoiceData });
	}

	uploadInvoice = (row) => {
		let { requestKey } = row.original;
		let { invoiceFile } = this.state;
		if(requestKey && invoiceFile){
			this.props.uploadInvoiceFile({ requestKey, file: invoiceFile, userId: this.props.user.phoneNumber, invoiceData:this.state.invoiceData })
		}
	}

	downloadInvoice = (row) => {
		let { requestKey } = row.original;
		if(requestKey){
			this.props.downloadInvoice({ requestKey })
		}
	}

	onFileUpload = (e) => {
		this.setState({
			invoiceFile: e.target.files[0]
		})
	}

	handleOnViewChange = (e) => {
		let gst = '12';
		let sizeText = 'Inverter Size';
		switch (e.target.value) {
			case "panels":
				this.setState({
					currentSelected: e.target.value, formName: "Panel",
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value },
					brand_Structure: { ...this.state.brand_Structure, options: brandOptions },
					make_Structure: { ...this.state.make_Structure, options: panelTypeOptions }
				});
				gst = '12';
				break;
			case "inverter":
				this.setState({
					currentSelected: e.target.value, formName: "Inverter",
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value },
					brand_Structure: { ...this.state.brand_Structure, options: inverterOptions }
				});
				gst = '12';
				break;
			case "structure":
				this.setState({
					currentSelected: e.target.value, formName: "Structure",
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value }
				});
				gst = '18';
				sizeText = 'Size';
				break;
			case "v2":
				this.setState({
					currentSelected: e.target.value, formName: "V2",
					paymentType_Structure: { ...this.state.paymentType_Structure, options: v2PaymentOptions },
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value }
				});
				gst = '18';
				break;
			case "v1":
				this.setState({
					currentSelected: e.target.value, formName: "V1",
					paymentType_Structure: { ...this.state.paymentType_Structure, options: v1PaymentOptions },
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value }
				});
				break;
			case "other":
				this.setState({
					currentSelected: e.target.value, formName: "Other",
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value }
				});
				break;
			case "accounts":
				this.setState({
					currentSelected: e.target.value, formName: "Accounts",
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value }
				});
				break;
			case "ACDB":
				this.setState({
					currentSelected: e.target.value, formName: "ACDB",
					make_Structure: { ...this.state.make_Structure, options: acdbMakeOptions },
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value }
				})
				break;
			case "DCDB":
				this.setState({
					currentSelected: e.target.value, formName: "DCDB",
					make_Structure: { ...this.state.make_Structure, options: acdbMakeOptions },
					transactionType_Structure: { ...this.state.transactionType_Structure, value: e.target.value, defaultValue: e.target.value, actualValue: e.target.value },
				})
				break;
			default:
				this.setState({
					selectView: e.target.name
				})
				break;
		}
		this.changeVendorOptions(e.target.value);
		this.setState({
			price_Structure: new inputObject("text", "Price", "price", "", "", "inputDiv", "Price", this.handleInputChange, []),
			v1Charge_Structure: new inputObject("text", "V1 Charge", "v1Charge", "", "", "inputDiv", "V1 Charge", this.handleInputChange, []),
			zunroofFee_Structure: new inputObject("text", "Zunroof Fee", "zunroofFee", "", "", "inputDiv", "Zunroof Fee", this.handleInputChange, []),
			installerCharge_Structure: new inputObject("text", "Installer", "installerCharge", "", "", "inputDiv", "Installer Charge", this.handleInputChange, []),
			name_Structure: new inputObject("text", "Name", "name", "", "", "inputDiv", "Name", this.handleInputChange, []),
			epc_Structure: new inputObject("text", "EPC", "epc", "", "", "inputDiv", "EPC", this.handleInputChange, []),
			date_Structure: new inputObject("date", "Date", "date", changeToInputDate(new Date()), changeToInputDate(new Date()), "inputDiv", "Date", this.handleInputChange, [], new Date().getTime()),
			sizeP_Structure: new inputObject("text", "System Size", "sizeP", "", "", "inputDiv", "System Size", this.handleInputChange, []),
			exactWatt_Structure: new inputObject("text", "Exact Watt", "exactWatt", "", "", "inputDiv", "Exact Watt", this.handleInputChange, []),
			perWatt_Structure: new inputObject("text", "Per Watt Price", "perWatt", "", "", "inputDiv", "Per Watt Price", this.handleInputChange, []),
			payment_Structure: new inputObject("text", "Payment %", "payment", "", "", "inputDiv", "Payment", this.handleInputChange, []),
			freight_Structure: new inputObject("text", "Freight", "freight", "", "", "inputDiv", "Freight", this.handleInputChange, []),
			gst_Structure: new inputObject("text", "GST %", "gst", gst, gst, "inputDiv", "GST", this.handleInputChange, [], gst),
			reference_Structure: new inputObject("text", "Reference", "reference", "", "", "inputDiv", "Reference", this.handleInputChange, []),
			status_Structure: new inputObject("text", "Status", "status", "", "", "inputDiv", "Status", this.handleInputChange, []),
			invoiceNumber_Structure: new inputObject("text", "Invoice No.", "invoiceNumber", "", "", "inputDiv", "Invoice No.", this.handleInputChange, []),
			inverterSize_Structure: new inputObject("text", sizeText, "inverterSize", "", "", "inputDiv", sizeText, this.handleInputChange, []),
			costOfInverter_Structure: new inputObject("text", "Inverter Cost", "costOfInverter", "", "", "inputDiv", "Cost of Inverter", this.handleInputChange, []),
			costOfMatrial_Structure: new inputObject("text", "Material Cost", "costOfMatrial", "", "", "inputDiv", "Cost of Material", this.handleInputChange, []),
			installerFee_Structure: new inputObject("text", "Installer Fee", "installerFee", "", "", "inputDiv", "Per Kw", this.handleInputChange, []),
			zunroofFeeInput_Structure: new inputObject("text", "Zunroof Fee Input", "zunroofFeeInput", "", "", "inputDiv", "Zunroof Fee Input", this.handleInputChange, []),
			invoiceAmount_Structure: new inputObject("text", "Invoice Amount", "invoiceAmount", "", "", "inputDiv", "Invoice Amount", this.handleInputChange, []),
			comments_Structure: new inputObject("text", "Comments", "comments", "", "", "inputDiv", "Comments", this.handleInputChange, []),
			adjustmentAmount_structure: new inputObject("text", "Adjustment Amount", "adjustmentAmount", "", "", "inputDiv", "Adjustment Amount", this.handleInputChange, []),
			systemPrice_Structure: new inputObject("text", "System Price", "systemPrice", "", "", "inputDiv", "System Price", this.handleInputChange, []),
			acdbDcdb_Structure: new inputObject("text", "ACDB/DCDB", "acdbDcdb", "", "", "inputDiv", "ACDB/DCDB", this.handleInputChange, [])
		})
		// this.resetForm('a');
	}

	changeVendorOptions = (selectedOption) => {
		let updatedMap = [];
		let key = "panelPermission";
		switch (selectedOption) {
			case "panels":
				key = "panelPermission";
				break;
			case "inverter":
				key = "inverterPermission";
				break;
			case "structure":
				key = "structurePermission";
				break;
			case "v2":
				key = "v2Permission";
				break;
			case "v1":
				key = "v1Permission";
				break;
			case "other":
				key = "";
				break;
			case "accounts":
				key = "";
				break;
			default:
				key="";
				break;
		}
		if (this.props.vendorMap) {
			Object.values(this.props.vendorMap ? this.props.vendorMap : {}).map(val => {
				if (!key || val[key]) {
					updatedMap.push({
						label: val.name,
						value: val.id
					});
				}
			})
		}
		this.setState({
			vendor_Structure: { ...this.state.vendor_Structure, options: updatedMap },
			purchaseVendor_Structure: { ...this.state.purchaseVendor_Structure, options: updatedMap }
		})
	}

	handleOnSelectChange = (value, name) => {
		let { fetchquoteid, fetchquoteidprocessedtransaction, fetchBankTransactions } = this.props;
		switch (name) {
			case "quoteId":
				this.setState({ quoteId: value.label, quoteId_Structure: { ...this.state.quoteId_Structure, value: value, actualValue: value.label } }, () => { fetchquoteid(value.value); })
				break;
			case "quoteIdProcess":
				fetchquoteidprocessedtransaction(value.value);
				this.setState({
					[name]: value
				}, () => { this.applyFilter() })
				break;
			case "transactionTypeFilter":
				this.setState({
					[name]: value
				}, () => { this.applyFilter() })
				break;
			case "statusFilter":
				this.setState({
					[name]: value
				}, () => { this.applyFilter() })
				break;
			case "bankTransactions":
				fetchBankTransactions(value.value);
				this.setState({
					[name]: value
				}, () => { this.applyFilter() })
				break;
			default:
				this.setState({ [`${name}_Structure`]: { ...this.state[`${name}_Structure`], value: value, actualValue: value.value } })
				break;
		}
	}

	handleOnSelectChangeTransaction = (value, name) => {
		this.setState({
			[name]: value
		})
	}

	handleDateInputChange = (e) => {
		let { name, value } = e.target;
		this.setState({
			[`${name}_Structure`]: { ...this.state[`${name}_Structure`], value: value, actualValue: value }
		})
	}

	handleInputChange = (e) => {
		let { name, value } = e.target;
		console.log("INPUT CHANGE", name, value);
		if(name == 'date'){
			this.setState({
				[`${name}_Structure`]: { ...this.state[`${name}_Structure`], value: value, actualValue: value?moment(value,"YYYY-MM-DD").valueOf():'' }
			})
			return;
		}
		if(name == 'count' && isNaN(value)){
			return;
		}
		if (name != 'payment' && name != 'gst') {
			this.setState({
				[`${name}_Structure`]: { ...this.state[`${name}_Structure`], value: value, actualValue: value }
			})
		}
		else {
			if (value <= 100) {
				this.setState({
					[`${name}_Structure`]: { ...this.state[`${name}_Structure`], value: value, actualValue: value }
				})
			}
		}
	}

	handleOnSaveClick = (type) => {
		this.onCloseWarningModal();
		let { requestNewPayment, user, requestNewTransaction } = this.props;
		let { currentSelected } = this.state;
		let formObject = {};
		let accessObj = {};
		let referenceData = {...this.props.dataReference};
		switch(type){
			case 'Purchase':
				accessObj = {...purchaseFormAccess};
				break;
			case 'Sell':
				accessObj = { ...sellFormAccess };
				break;
			default:
				accessObj = { ...access };
				break;
		}
		Object.keys(accessObj[currentSelected]).forEach(key => {
			let { actualValue, name } = this.state[key];
			if (actualValue) {
				formObject[name] = actualValue;
			}
		});
		if (currentSelected === "accounts") {
			requestNewPayment({ user, formObject, currentSelected });
		} else {
			formObject['status'] = 0;
			requestNewTransaction({ user, formObject, currentSelected, type, referenceData });
		}
		// this.resetForm();
	}

	checkWarnings = () => {
        let referenceData = {...this.props.dataReference};
		let { currentSelected } = this.state;
		let warnings = [];
		let { price_Structure, sizeP_Structure, quoteId_Structure, exactWatt_Structure, gst_Structure, freight_Structure, inverterSize_Structure } = this.state;
		if(currentSelected == "accounts"){
			this.handleOnSaveClick('Form');
			return;
		}
		if(parseFloat(price_Structure.actualValue)/parseFloat(sizeP_Structure.actualValue) >= 30000){
			warnings.push("Price is greater than INR 30,000/kW");
		}
		let totalSpendOnQuoteId = parseFloat(price_Structure.actualValue);
		Object.values(referenceData?referenceData:{}).forEach(val => {
			if([0,1,2,3,6].includes(val.status) && val.quoteId == quoteId_Structure.actualValue){
				totalSpendOnQuoteId += parseFloat(val.price);
			}
		})
		if(totalSpendOnQuoteId/parseFloat(sizeP_Structure.actualValue) > 60000){
			warnings.push("Total spend on QID is exceedinng INR 60,000/kW");
		}
		let totalSpendV2AndStructure = parseFloat(price_Structure.actualValue);
		switch (currentSelected) {
			case "panels":
				let totalSpendPanel = parseFloat(price_Structure.actualValue);
				Object.values(referenceData?referenceData:{}).forEach(val => {
					if([0,1,2,3,6].includes(val.status) && val.transactionType == 'panels' && val.quoteId == quoteId_Structure.actualValue){
						totalSpendPanel += parseFloat(val.price);
					}
				})
				if(totalSpendPanel/parseFloat(sizeP_Structure.actualValue)>28000){
					warnings.push("Total Panel Cost fo QID is exceeding INR 28,000/kW");
				}
				if(parseFloat(exactWatt_Structure.actualValue)/(parseFloat(sizeP_Structure.actualValue) * 1000) > 1.2){
					warnings.push("Exact Wattage is more than 120% of system size");
				}
				if(gst_Structure.actualValue > 12){
					warnings.push("GST is more than 12%");
				}
				if(parseFloat(freight_Structure.actualValue)/parseFloat(sizeP_Structure.actualValue) > 2000){
					warnings.push("Freight cost is more than INR 2,000/KW");
				}
				break;
			case "inverter":
				let totalSpendInverter = parseFloat(price_Structure.actualValue);
				Object.values(referenceData?referenceData:{}).forEach(val => {
					if([0,1,2,3,6].includes(val.status) && val.transactionType == 'inverter' && val.quoteId == quoteId_Structure.actualValue){
						totalSpendInverter += parseFloat(val.price);
					}
				})
				if(totalSpendInverter/parseFloat(sizeP_Structure.actualValue) > 10000){
					warnings.push("Total Inverter Cost fo QID is exceeding INR 10,000/kW");
				}
				if(parseFloat(inverterSize_Structure.actualValue)/parseFloat(sizeP_Structure.actualValue) > 1.2){
					warnings.push("Size is more than 120% of system size");
				}
				if(gst_Structure.actualValue > 18){
					warnings.push("GST is more than 18%");
				}
				if(parseFloat(freight_Structure.actualValue) > 2000){
					warnings.push("Freight cost is more than INR 2,000");
				}
				break;
			case "structure":
				Object.values(referenceData?referenceData:{}).forEach(val => {
					if([0,1,2,3,6].includes(val.status) && (val.transactionType == 'structure' || val.transactionType == 'v2') && val.quoteId == quoteId_Structure.actualValue){
						totalSpendV2AndStructure += parseFloat(val.price);
					}
				})
				if(totalSpendV2AndStructure/parseFloat(sizeP_Structure.actualValue)>19000){
					warnings.push("Total V2+Structure Cost fo QID is exceeding INR 19,000/kW");
				}
				if(parseFloat(inverterSize_Structure.actualValue)/parseFloat(sizeP_Structure.actualValue) > 1.2){
					warnings.push("Size is more than 120% of system size");
				}
				if(gst_Structure.actualValue > 18){
					warnings.push("GST is more than 18%");
				}
				break;
			case "v2":
				Object.values(referenceData?referenceData:{}).forEach(val => {
					if([0,1,2,3,6].includes(val.status) && (val.transactionType == 'structure' || val.transactionType == 'v2') && val.quoteId == quoteId_Structure.actualValue){
						totalSpendV2AndStructure += parseFloat(val.price);
					}
				})
				if(totalSpendV2AndStructure/parseFloat(sizeP_Structure.actualValue)>19000){
					warnings.push("Total V2+Structure Cost fo QID is exceeding INR 19,000/kW");
				}
				if(parseFloat(inverterSize_Structure.actualValue)/parseFloat(sizeP_Structure.actualValue) > 1.2){
					warnings.push("Size is more than 120% of system size");
				}
				if(gst_Structure.actualValue > 18){
					warnings.push("GST is more than 18%");
				}
				break;
			default:
				break;
		}
		if(warnings.length > 0){
			this.setState({
				openWarningsModal:true,
				warnings:warnings,
				warningType:'formSubmit',
				formType:'Form'
			});	
		}
		else{
			this.handleOnSaveClick('Form');
		}
	}
	
	checkWarningsProcurement = (type, checkType, row, value) => {
		let warnings = [];
		if(checkType == 'formSubmit'){
			let { currentSelected, gst_Structure, freight_Structure } = this.state;
			switch(currentSelected){
				case 'panels':
					if(gst_Structure.actualValue > 12){
						warnings.push("GST is more than 12%");
					}
					break;
				case 'inverter':
					if(gst_Structure.actualValue > 18){
						warnings.push("GST is more than 18%");
					}
					if(parseFloat(freight_Structure.actualValue) > 2000){
						warnings.push("Freight cost is more than INR 2,000");
					}
					break;
				default:
					break;
			}
			if(warnings.length > 0){
				this.setState({
					openWarningsModal:true,
					warnings:warnings,
					warningType:checkType,
					formType:type
				});	
			}
			else{
				this.handleOnSaveClick(type);
			}
		}
		else{
			let data = { ...row.original };
			let { gst, freight, transactionType } = data;
			switch(transactionType){
				case 'panels':
					if(gst > 12){
						warnings.push("GST is more than 12%");
					}
					break;
				case 'inverter':
					if(gst > 18){
						warnings.push("GST is more than 18%");
					}
					if(parseFloat(freight) > 2000){
						warnings.push("Freight cost is more than INR 2,000");
					}
					break;
				default:
					break;
			}
			if(warnings.length > 0){
				this.setState({
					approvalRow:row,
					approvalValue:value,
					warningType:'approval',
					formType:type
				}, () => {
					this.setState({
						openWarningsModal:true,
						warnings:warnings
					})
				});	
			}
			else{
				this.onApproveClick(row, value);
			}
		}
	}

	resetForm = (e) => {
		let gst = this.state.gst_Structure.value;
		let sizeText = this.state.inverterSize_Structure.label;
		this.setState({
			price_Structure: new inputObject("text", "Price", "price", "", "", "inputDiv", "Price", this.handleInputChange, []),
			v1Charge_Structure: new inputObject("text", "V1 Charge", "v1Charge", "", "", "inputDiv", "V1 Charge", this.handleInputChange, []),
			zunroofFee_Structure: new inputObject("text", "Zunroof Fee", "zunroofFee", "", "", "inputDiv", "Zunroof Fee", this.handleInputChange, []),
			installerCharge_Structure: new inputObject("text", "Installer", "installerCharge", "", "", "inputDiv", "Installer Charge", this.handleInputChange, []),
			name_Structure: new inputObject("text", "Name", "name", "", "", "inputDiv", "Name", this.handleInputChange, []),
			epc_Structure: new inputObject("text", "EPC", "epc", "", "", "inputDiv", "EPC", this.handleInputChange, []),
			date_Structure: new inputObject("date", "Date", "date", changeToInputDate(new Date()), changeToInputDate(new Date()), "inputDiv", "Date", this.handleInputChange, [], new Date().getTime()),
			sizeP_Structure: new inputObject("text", "System Size", "sizeP", "", "", "inputDiv", "System Size", this.handleInputChange, []),
			exactWatt_Structure: new inputObject("text", "Exact Watt", "exactWatt", "", "", "inputDiv", "Exact Watt", this.handleInputChange, []),
			perWatt_Structure: new inputObject("text", "Per Watt Price", "perWatt", "", "", "inputDiv", "Per Watt Price", this.handleInputChange, []),
			payment_Structure: new inputObject("text", "Payment %", "payment", "", "", "inputDiv", "Payment", this.handleInputChange, []),
			freight_Structure: new inputObject("text", "Freight", "freight", "", "", "inputDiv", "Freight", this.handleInputChange, []),
			gst_Structure: new inputObject("text", "GST %", "gst", gst, gst, "inputDiv", "GST", this.handleInputChange, [], gst),
			reference_Structure: new inputObject("text", "Reference", "reference", "", "", "inputDiv", "Reference", this.handleInputChange, []),
			status_Structure: new inputObject("text", "Status", "status", "", "", "inputDiv", "Status", this.handleInputChange, []),
			invoiceNumber_Structure: new inputObject("text", "Invoice No.", "invoiceNumber", "", "", "inputDiv", "Invoice No.", this.handleInputChange, []),
			inverterSize_Structure: new inputObject("text", sizeText, "inverterSize", "", "", "inputDiv", "Inverter Size", this.handleInputChange, []),
			costOfInverter_Structure: new inputObject("text", "Inverter Cost", "costOfInverter", "", "", "inputDiv", "Cost of Inverter", this.handleInputChange, []),
			costOfMatrial_Structure: new inputObject("text", "Material Cost", "costOfMatrial", "", "", "inputDiv", "Cost of Material", this.handleInputChange, []),
			installerFee_Structure: new inputObject("text", "Installer Fee", "installerFee", "", "", "inputDiv", "Per Kw", this.handleInputChange, []),
			zunroofFeeInput_Structure: new inputObject("text", "Zunroof Fee Input", "zunroofFeeInput", "", "", "inputDiv", "Zunroof Fee Input", this.handleInputChange, []),
			invoiceAmount_Structure: new inputObject("text", "Invoice Amount", "invoiceAmount", "", "", "inputDiv", "Invoice Amount", this.handleInputChange, []),
			comments_Structure: new inputObject("text", "Comments", "comments", "", "", "inputDiv", "Comments", this.handleInputChange, []),
			adjustmentAmount_structure: new inputObject("text", "Adjustment Amount", "adjustmentAmount", "", "", "inputDiv", "Adjustment Amount", this.handleInputChange, []),
			systemPrice_Structure: new inputObject("text", "System Price", "systemPrice", "", "", "inputDiv", "System Price", this.handleInputChange, [])
		})
	}

	handleOnCalculateClick = () => {
		let { state } = this;
		let { currentSelected, vendor_Structure } = state;
		let { fetchvendorduepayment } = this.props;
		//debugger
		if (currentSelected === "accounts") {
			fetchvendorduepayment(vendor_Structure.actualValue)
			return;
		}
		let price = priceCalculator(currentSelected, state);
		let keys = Object.keys(price);
		let values = Object.values(price);
		let setObject = {};
		keys.forEach((key, index) => {
			setObject[key] = { ...this.state[key], value: values[index], actualValue: values[index], defaultValue: values[index] }
		});
		this.setState(setObject, () => {
			console.log(this.state.price_Structure);
		});
	}

	handleOnFilterChange = (e) => {
		let { name, value } = e.target;
		let dateValue = 0;
		if (name === "filterStartDate") {
			dateValue = value ? new Date(value).setHours(0, 0, 0, 0) : 0;
		} else if (name === "filterEndDate") {
			dateValue = value ? new Date(value).setHours(23, 59, 59, 59) : 9999999999999;
		}
		this.setState({
			[name]: dateValue
		}, () => { this.applyFilter() });
	}

	handleOnFilterChangeTransaction = (e) => {
		let { name, value } = e.target;
		let dateValue = 0;
		if (name === "filterStartDate") {
			dateValue = value ? new Date(value).setHours(0, 0, 0, 0) : 0;
		} else if (name === "filterEndDate") {
			dateValue = value ? new Date(value).setHours(23, 59, 59, 59) : 9999999999999;
		}
		this.setState({
			[name]: dateValue
		});
	}

	applyFilter = () => {
		let { quoteIdProcessTransaction, quoteIdProcess, filterStartDate, filterEndDate, transactionTypeFilter, selectView, processLedgerDataMain, tableDataMain, statusFilter, invoiceTableData, transactionTypeFilterInvoice, invoiceTransactions, paymentTypeFilter } = this.state;
		let finalData = [];
		statusFilter = statusFilter || {};
		switch (selectView) {
			case "ledgerView":
				finalData = processLedgerDataMain.filter(val => {
					let { date } = val;
					return date >= filterStartDate && date <= filterEndDate;
				});
				this.setState({
					processLedgerData: finalData
				})
				break;
			case "transactionView":
				finalData = Object.values(tableDataMain).filter(val => {
					let { date, transactionType, vendor, status, paymentType } = val;
					return date >= filterStartDate && date <= filterEndDate && (!transactionTypeFilter.length || transactionTypeFilter.map(val => val.value).includes(transactionType)) && (!quoteIdProcessTransaction.length || quoteIdProcessTransaction.map(val => val.value).includes(vendor)) && (!statusFilter.length || statusFilter.map(val => val.value).includes(status)) && (!paymentTypeFilter.length || paymentTypeFilter.map(val => val.value).includes(paymentType));
				});
				this.setState({
					tableData: finalData
				})
				break;
			case "invoiceView":
				finalData = invoiceTableData.filter(val => {
					let { date, transactionType, vendor } = val;
					return date >= filterStartDate && date <= filterEndDate && (!transactionTypeFilterInvoice.length || transactionTypeFilterInvoice.map(val => val.value).includes(transactionType)) && (!invoiceTransactions.length || invoiceTransactions.map(val => val.value).includes(vendor));
				});
				this.setState({
					invoiceTableViewData: finalData
				})
				break;
			default:
				break;
		}
	}


	applyFilterTransaction = () => {
		let { quoteIdProcessTransaction, filterStartDate, filterEndDate, transactionTypeFilter, selectView, tableDataMain, statusFilter, paymentTypeFilter } = this.state;
		let finalData = Object.values(tableDataMain).filter(val => {
			let { date, transactionType, vendor, status, paymentType } = val;
			return date >= filterStartDate && date <= filterEndDate && (!transactionTypeFilter.length || transactionTypeFilter.map(val => val.value).includes(transactionType)) && (!quoteIdProcessTransaction.length || quoteIdProcessTransaction.map(val => val.value).includes(vendor)) && (!statusFilter.length || statusFilter.map(val => val.value).includes(status)) && (!paymentTypeFilter.length || paymentTypeFilter.map(val => val.value).includes(paymentType));
		});
		this.setState({
			tableData: finalData
		})
	}

	checkReciptSummary = (list, name) => {
		this.onOpenModal(name);
		this.props.fetchrequestsummary(list);
	}

	onApproveClick = (row, value) => {
		this.onCloseWarningModal();
		let { user, approvePayment, dataReference } = this.props;
		let data = { ...row.original };
		data.status = value;
		approvePayment({ user, id: data.requestKey, value, dataReference, data });
		this.setState({
			tableData: { ...this.state.tableData, [data.requestKey]: data }
		})
	}

	checkWarningsApproval = (row, value) => {
		let data = { ...row.original };
		let transactionType = data.transactionType;
		let referenceData = {...this.props.dataReference};
		let warnings = [];
		let { price, sizeP, quoteId, exactWatt, gst, freight, inverterSize } = data;
		if(parseFloat(price)/parseFloat(sizeP) >= 30000){
			warnings.push("Price is greater than INR 30,000/kW");
		}
		let totalSpendOnQuoteId = 0;
		Object.values(referenceData?referenceData:{}).forEach(val => {
			if([0,1,2,3,6].includes(val.status) && val.quoteId == quoteId){
				totalSpendOnQuoteId += parseFloat(val.price);
			}
		})
		if(totalSpendOnQuoteId/parseFloat(sizeP) > 60000){
			warnings.push("Total spend on QID is exceedinng INR 60,000/kW");
		}
		let totalSpendV2AndStructure = 0;
		switch (transactionType) {
			case "panels":
				let totalSpendPanel = 0;
				Object.values(referenceData?referenceData:{}).forEach(val => {
					if([0,1,2,3,6].includes(val.status) && val.transactionType == 'panels' && val.quoteId == quoteId){
						totalSpendPanel += parseFloat(val.price);
					}
				})
				if(totalSpendPanel/parseFloat(sizeP)>28000){
					warnings.push("Total Panel Cost fo QID is exceeding INR 28,000/kW");
				}
				if(parseFloat(exactWatt)/(parseFloat(sizeP) * 1000) > 1.2){
					warnings.push("Exact Wattage is more than 120% of system size");
				}
				if(gst > 12){
					warnings.push("GST is more than 12%");
				}
				if(parseFloat(freight)/parseFloat(sizeP) > 2000){
					warnings.push("Freight cost is more than INR 2,000/KW");
				}
				break;
			case "inverter":
				let totalSpendInverter = 0;
				Object.values(referenceData?referenceData:{}).forEach(val => {
					if([0,1,2,3,6].includes(val.status) && val.transactionType == 'inverter' && val.quoteId == quoteId){
						totalSpendInverter += parseFloat(val.price);
					}
				})
				if(totalSpendInverter/parseFloat(sizeP) > 10000){
					warnings.push("Total Inverter Cost fo QID is exceeding INR 10,000/kW");
				}
				if(parseFloat(inverterSize)/parseFloat(sizeP) > 1.2){
					warnings.push("Size is more than 120% of system size");
				}
				if(gst > 18){
					warnings.push("GST is more than 18%");
				}
				if(parseFloat(freight) > 2000){
					warnings.push("Freight cost is more than INR 2,000");
				}
				break;
			case "structure":
				Object.values(referenceData?referenceData:{}).forEach(val => {
					if([0,1,2,3,6].includes(val.status) && (val.transactionType == 'structure' || val.transactionType == 'v2') && val.quoteId == quoteId){
						totalSpendV2AndStructure += parseFloat(val.price);
					}
				})
				if(totalSpendV2AndStructure/parseFloat(sizeP)>19000){
					warnings.push("Total V2+Structure Cost fo QID is exceeding INR 19,000/kW");
				}
				if(parseFloat(inverterSize)/parseFloat(sizeP) > 1.2){
					warnings.push("Size is more than 120% of system size");
				}
				if(gst > 18){
					warnings.push("GST is more than 18%");
				}
				break;
			case "v2":
				Object.values(referenceData?referenceData:{}).forEach(val => {
					if([0,1,2,3,6].includes(val.status) && (val.transactionType == 'structure' || val.transactionType == 'v2') && val.quoteId == quoteId){
						totalSpendV2AndStructure += parseFloat(val.price);
					}
				})
				if(totalSpendV2AndStructure/parseFloat(sizeP)>19000){
					warnings.push("Total V2+Structure Cost fo QID is exceeding INR 19,000/kW");
				}
				if(parseFloat(inverterSize)/parseFloat(sizeP) > 1.2){
					warnings.push("Size is more than 120% of system size");
				}
				if(gst > 18){
					warnings.push("GST is more than 18%");
				}
				break;
			default:
				break;
		}
		if(warnings.length > 0){
			this.setState({
				approvalRow:row,
				approvalValue:value,
				warningType:'approval',
				formType:'Form'
			}, () => {
				this.setState({
					openWarningsModal:true,
					warnings:warnings
				})
			});	
		}
		else{
			this.onApproveClick(row, value);
		}
	}

	onOpenModal = (name) => {
		this.setState({
			[name]: true
		})
	}

	onCloseModal = (name) => {
		this.setState({
			[name]: false
		})
	}

	onOpenTransactionModal = (id) => {
		let selectedTransactionType = this.props.dataReference[id]['transactionType'];
		let type = this.props.dataReference[id]['type'];
		let accessObj;
		switch(type){
			case 'Purchase':
				accessObj = purchaseFormAccess;
				break;
			case 'Sell':
				accessObj = sellFormAccess;
				break;
			case 'Form':
				accessObj = access;
				break;
			default:
				accessObj = access;
				break;
		}
		selectedTransactionType = accessObj[selectedTransactionType]?selectedTransactionType:"other";
		let selectedTransactionDetails = {...this.props.dataReference[id]};
		selectedTransactionDetails.date = changeToInputDate(selectedTransactionDetails.date);
		selectedTransactionDetails.vendor = this.props.vendorMap[selectedTransactionDetails.vendor].name;
		selectedTransactionDetails.status = progressStatus[selectedTransactionDetails.status];
		if(selectedTransactionDetails.type == 'Sell'){
			selectedTransactionDetails.purchaseVendor = this.props.vendorMap[selectedTransactionDetails.purchaseVendor].name;
		}
		this.setState({
			openTransactionModal: true,
			selectedTransaction: id,
			selectedTransactionDetails,
			selectedTransactionType
		})
	}

	onCloseTransactionModal = (e) => {
		this.setState({
			openTransactionModal: false
		})
	}

	onCloseWarningModal = () => {
		this.setState({
			openWarningsModal: false
		})
	}

	// resetForm = (e) => {
	// 	let formFields = ["v1Charge_Structure", "installerCharge_Structure", "zunroofFee_Structure", "price_Structure"];
	// 	formFields.forEach(key => {
	// 		this.setState({
	// 			[key]:{...this.state[key], value:'', actualValue:''}
	// 		})
	// 	});
	// }

	downloadTransactionData = () => {
		let { vendorMap } = this.props;
		let { tableData } = this.state;
		let transactionTableData = JSON.parse(JSON.stringify(tableData));
		let { downloadRef } = this;
		let dataToDownload = Object.values(transactionTableData||{}).map(transaction => {
			transaction.date = changeToViewDateTime(transaction.date)||"";
			transaction.approvalStatus = progressStatus[transaction.status]||"";
			transaction.vendor = (vendorMap[transaction.vendor]||{}).name||"";
			transaction.inverterSize = transaction.inverterSize || transaction.sizeP;
			return transaction;
		})
		this.setState({ transactionViewData : dataToDownload }, () => {
			downloadRef.current.link.click();
		})
	}

	downloadInvoiceData = () => {
		let { vendorMap } = this.props;
		let { invoiceTableViewData, invoiceData = {} } = this.state;
		let invoiceTableData = JSON.parse(JSON.stringify(invoiceTableViewData));
		let { downloadInvoiceRef } = this;
		let dataToDownload = invoiceTableData.map(transaction => {
			transaction.date = changeToViewDateTime(transaction.date)||"";
			transaction.vendor = (vendorMap[transaction.vendor]||{}).name||"";
			let invoiceStatus = (invoiceData[transaction.requestKey] && invoiceData[transaction.requestKey].invoiceStatus)?(invoiceData[transaction.requestKey].invoiceStatus):0;
			let approvalStatus = (invoiceData[transaction.requestKey] && invoiceData[transaction.requestKey].approvalStatus)?(invoiceData[transaction.requestKey].approvalStatus):0;
			transaction.invoiceStatus = !invoiceStatus?"":((invoiceStatus==1||invoiceStatus==2)?"Required":"Not Required");
			transaction.approvalStatus = invoiceStatus==2?(approvalStatus==1?"Approved":approvalStatus==2?"Rejected":""):""
			return transaction;
		})
		console.log("dataToDownload", dataToDownload)
		this.setState({ invoiceViewData : dataToDownload }, () => {
			downloadInvoiceRef.current.link.click();
		})
	}

	applyFilterInventory = () => {
		console.log("applyFilterInventory")
		let { inventoryData, inventoryDataView, currentSelected, inventoryVendorSelected, inventoryBrandSelected, inventoryMakeSelected, inventoryPhaseSelected, inventoryCitySelected } = this.state;
		console.log("inventoryVendorSelected", inventoryVendorSelected, currentSelected, inventoryDataView, inventoryBrandSelected, inventoryMakeSelected);
		inventoryData = JSON.parse(JSON.stringify(inventoryData));
		let data=[], filteredData = [];
		switch (currentSelected) {
			case "panels":
				data = JSON.parse(JSON.stringify(inventoryData[currentSelected]));
				filteredData = data.filter(row => {
					return (
						(!inventoryVendorSelected.length || inventoryVendorSelected.map(val => val.value).includes(row.vendor)) &&
						(!inventoryBrandSelected.length || inventoryBrandSelected.map(val => val.value).includes(row.brand)) &&
						(!inventoryMakeSelected.length || inventoryMakeSelected.map(val => val.value).includes(row.make))
					)
				})
				console.log("fd", filteredData);
				this.setState({
					inventoryDataView:{
						...this.state.inventoryDataView,
						[currentSelected]: filteredData
					}
				})
				break;
			case "inverter":
				data = JSON.parse(JSON.stringify(inventoryData[currentSelected]));
				filteredData = data.filter(row => {
					return (
						(!inventoryVendorSelected.length || inventoryVendorSelected.map(val => val.value).includes(row.vendor)) &&
						(!inventoryBrandSelected.length || inventoryBrandSelected.map(val => val.value).includes(row.brand)) &&
						(!inventoryPhaseSelected.length || inventoryPhaseSelected.map(val => val.value).includes(row.phase))
					)
				})
				console.log("fd", filteredData);
				this.setState({
					inventoryDataView:{
						...this.state.inventoryDataView,
						[currentSelected]: filteredData
					}
				})
				break;
			case "ACDB":
				data = JSON.parse(JSON.stringify(inventoryData[currentSelected]));
				filteredData = data.filter(row => {
					return (
						(!inventoryVendorSelected.length || inventoryVendorSelected.map(val => val.value).includes(row.vendor)) &&
						(!inventoryCitySelected.length || inventoryCitySelected.map(val => val.value).includes(row.city)) &&
						(!inventoryMakeSelected.length || inventoryMakeSelected.map(val => val.value).includes(row.make))
					)
				})
				console.log("fd", filteredData);
				this.setState({
					inventoryDataView:{
						...this.state.inventoryDataView,
						[currentSelected]: filteredData
					}
				})
				break;
			case "DCDB":
				data = JSON.parse(JSON.stringify(inventoryData[currentSelected]));
				filteredData = data.filter(row => {
					return (
						(!inventoryVendorSelected.length || inventoryVendorSelected.map(val => val.value).includes(row.vendor)) &&
						(!inventoryCitySelected.length || inventoryCitySelected.map(val => val.value).includes(row.city)) &&
						(!inventoryMakeSelected.length || inventoryMakeSelected.map(val => val.value).includes(row.make))
					)
				})
				console.log("fd", filteredData);
				this.setState({
					inventoryDataView:{
						...this.state.inventoryDataView,
						[currentSelected]: filteredData
					}
				})
				break;
			default:
				break;
		}
	}

	render() {

		let { state, props, handleOnSaveClick, checkWarnings,handleOnViewChange, form, handleOnCalculateClick, onCloseModal, handleOnSelectChange, handleOnFilterChange, onCloseWarningModal, onApproveClick, checkWarningsProcurement, handleOnSelectChangeTransaction, handleOnFilterChangeTransaction, applyFilterTransaction, downloadRef, downloadTransactionData, applyFilter, downloadInvoiceData, downloadInvoiceRef, applyFilterInventory } = this;
		let { user, error, success } = props;
		let { recieved, currentSelected, quoteId, formName, tableData, recieptView, columnPaymentReference, vendorMap, quoteIdProcess, processLedgerData, dueAmount, selectView, transactionTypeFilter, bankData, bankTransactions, vendorSummary, statusFilter, selectedTransactionType, selectedTransactionDetails, warnings, warningType, approvalRow, approvalValue, inventoryData, vendorMapping, formType, quoteIdProcessTransaction, invoiceTableData, columnInvoice, transactionViewData, inventoryConfig, invoiceTransactions, invoiceTableViewData, invoiceViewData, inventoryVendorSelected, inventoryMakeSelected, inventoryBrandSelected, inventoryCitySelected, inventoryPhaseSelected, inventoryDataView, paymentTypeFilter } = state;
		let accessObjectTransaction = {};
		switch((selectedTransactionDetails||{}).type){
			case 'Purchase':
				accessObjectTransaction = {...purchaseFormAccess};
				break;
			case 'Sell':
				accessObjectTransaction = { ...sellFormAccess };
				break;
			default:
				accessObjectTransaction = { ...access };
				break;
		}
		if (this.props.user === null) {
			return <Redirect to="/login?redirect_uri=vendor" />;
		}

		if (this.props.user.level < 1600) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		const modalStyles = {
			modal:{
				width:'100%',
				maxWidth:'100%'
			}
		}
		if (recieved) {
			return (
				<div>
					<div className="container-fluid">
						{
							success ? <div className="alert alert-success" role="alert">
								{success}
							</div> : ""
						}
						{
							error ? <div className="alert alert-danger" role="alert">
								{error}
							</div> : ""
						}
						<div className="formButtons heading-margin">
							<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "formView" ? "select" : ""}`} name="formView" onClick={handleOnViewChange}>Form</button>
							<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "transactionView" ? "select" : ""}`} name="transactionView" onClick={handleOnViewChange}>Transaction</button>
							<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "ledgerView" ? "select" : ""}`} name="ledgerView" onClick={handleOnViewChange}>Ledger</button>
							<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "invoiceView" ? "select" : ""}`} name="invoiceView" onClick={handleOnViewChange}>Invoice</button>
							{(this.props.user && (this.props.user.level == 1800 || this.props.user.level == 2600)) ? <button className={`btn btn-primary paymentViewButton btn-color ${selectView === "summary" ? "select" : ""}`} name="summary" onClick={handleOnViewChange}>Payment Summary</button> : ""}
							{(this.props.user && (this.props.user.level == 1800 || this.props.user.level == 2600)) ? <button className={`btn btn-primary paymentViewButton btn-color ${selectView === "bankView" ? "select" : ""}`} name="bankView" onClick={handleOnViewChange}>Bank</button> : ""}
							<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "purchaseView" ? "select" : ""}`} name="purchaseView" onClick={handleOnViewChange}>Purchase</button>
							<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "sellView" ? "select" : ""}`} name="sellView" onClick={handleOnViewChange}>Sell</button>
							<button className={`btn btn-primary paymentViewButton btn-color ${selectView === "inventoryView" ? "select" : ""}`} name="inventoryView" onClick={handleOnViewChange}>Inventory</button>
						</div>
						{
							selectView === "formView" ? <div>
								<VendorButtonComponent
									handleOnViewChange={handleOnViewChange}
									user={user}
								/>
								<ModalReceiptView
									recieptView={recieptView}
									onCloseModal={onCloseModal}
								/>
								{
									user.level >= 1600 && access[currentSelected] ? <div className="mainBox container-fluid">
										<div className="card formCard shadow">
											<div className="card-title container-fluid">
												<div className="formTitle">
													<div className="formSubPart1">
														<h4> {formName ? formName : "Form Name"} </h4>
													</div>
													<div className="formSubPart2">
														{currentSelected != "other" ? <button className="btn btn-success" style={{ margin: "0 10px" }} onClick={handleOnCalculateClick}>Calculate</button> : ""
														}

														<button className="btn btn-success" onClick={checkWarnings}>Submit</button>
													</div>
												</div>
											</div>
											<div className="card-body">
												<form className="form request_form row">
													{
														Object.keys(access[currentSelected]).map((value, index) => { return access[currentSelected][value].read[user.level] ? <InputDynamicType key={index} editable={access[currentSelected][value].write[user.level]} {...this.state[value]} /> : "" })
													}
												</form>
											</div>
										</div>
									</div> : ""
								}
							</div> : ""
						}
							<Modal open={this.state.openWarningsModal} onClose={this.onCloseWarningModal.bind(this)} closeOnEsc={false}>
							<div style={{padding:'10px'}}>
								<div style={{fontWeight:'bold', fontSize:'18px'}}>
									{"The transaction fails to meet following criteria. Do you want to continue?"}
								</div>
								<div style={{marginTop:"20px"}}>
								{
									Object.values(warnings).map((val,i) => {
									return <div style={{fontSize:"15px", margin:"3px"}}>{(i+1) + "." +val}</div>;
									})
								}
								</div>
							</div>
							<div style={{textAlign:'center'}}>
								{warningType == 'formSubmit'?<button className="btn btn-warning" style={{margin:"5px", fontSize:"15px"}} onClick={()=>handleOnSaveClick(formType)}>Yes</button>:<button className="btn btn-warning" style={{margin:"5px", fontSize:"15px"}} onClick={() => onApproveClick(approvalRow, approvalValue)}>Yes</button>
								}
								<button className="btn btn-success" style={{margin:"5px", fontSize:"15px"}} onClick={onCloseWarningModal}>No</button>
							</div> 
							</Modal>
						{
							selectView === "transactionView" ? <div style={{ marginTop: 40 }}>
								<FilterView
									placeholder={"Select Vendor"}
									name={"quoteIdProcessTransaction"}
									value={quoteIdProcessTransaction}
									options={vendorMap}
									handleOnChange={handleOnSelectChangeTransaction}
									handleOnFilterChange={handleOnFilterChangeTransaction}
									selectView={selectView}
									transactionOptions={transactionOptions}
									transactionTypeFilter={transactionTypeFilter}
									statusOptions={progressStatus.map((val, index) => { return ({ label: val, value: index }) })}
									statusFilter={statusFilter}
									data={Object.values(tableData)}
									columns={columnPaymentReference}
									applyFilter={applyFilterTransaction}
									downloadRef={downloadRef}
									downloadTransactionData={downloadTransactionData}
									transactionViewData={transactionViewData}
									paymentTypeOptions={v2PaymentOptions}
									paymentTypeFilter={paymentTypeFilter}
								/>
								<div style={{ textAlign: 'center' }}>
									<h3>Transaction Table</h3>
								</div>
								{ (this.props.user.level == 1800 || this.props.user.level == 2600) && Object.values(this.state.selectedTransactions).find(val => val == true) ? <div className="btn-approval-main mb-3" style={{width: '12%'}}>
									<div className="btn-approval-child">
										<button className="btn btn-success" onClick={() => { this.onApproveRejectSelected(2) }}>Accept 2</button>
									</div>
									<div className="btn-approval-child">
										<button className="btn btn-warning" onClick={() => { this.onApproveRejectSelected(5) }}>Reject 2</button>
									</div>
								</div> : "" }
								{/* <CSVLink filename={"Transaction_View_Data.csv"} data={Object.values(tableData)} headers={columnPaymentReference.map((val,index) => {return {label:val.Header,key:val.accessor}})} type="button" className="btn btn-info">
									Download
								</CSVLink> */}
								<ReactTable
									data={Object.values(tableData)}
									columns={[ ...columnPaymentReference ]}
									ref={this.reactTable}
									defaultSorted={[{ id: "date", desc: true }]}
									filterable={true}
									style={{ textAlign: 'center' }}
									defaultPageSize={10}
									defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
								/>
								<div>
									<Modal styles={modalStyles} open={this.state.openTransactionModal} onClose={this.onCloseTransactionModal.bind(this)} closeOnEsc={false}>
										<div style={{padding:'10px'}}>
											<div style={{fontWeight:'bold', textAlign:'center', fontSize:'20px'}}>
												{"Transaction Details"}
											</div>
											<form className="form request_form row" style={{marginTop:'20px'}}>
												{	
													this.state.selectedTransaction?Object.keys(accessObjectTransaction[selectedTransactionType]).map((value, index) => { return accessObjectTransaction[selectedTransactionType][value].read[user.level] ? <InputDynamicType key={index} editable={false} {...this.state[value]} defaultValue={selectedTransactionDetails[value.split('_')[0]]}/> : "" }):""
												}
											</form>
										</div>
									</Modal>
								</div>
							</div> : ""
						}

						{
							selectView === "ledgerView" ? <div>
								<FilterView
									placeholder={"Select Vendor"}
									name={"quoteIdProcess"}
									value={quoteIdProcess}
									options={vendorMap}
									handleOnChange={handleOnSelectChange}
									handleOnFilterChange={handleOnFilterChange}
									selectView={selectView}
									data={processLedgerData}
									columns={columnPaymentCompleteReference}
								/>

								<ProcessedTable
									data={processLedgerData}
									columns={columnPaymentCompleteReference}
									dueAmount={dueAmount}
									quoteIdProcess={quoteIdProcess}
								/>
							</div> : ""
						}

						{
							selectView === "invoiceView" ? <div>
								<FilterView
									placeholder={"Select Vendor"}
									name={"invoiceTransactions"}
									value={invoiceTransactions}
									options={vendorMap}
									handleOnChange={handleOnSelectChangeTransaction}
									handleOnFilterChange={handleOnFilterChangeTransaction}
									selectView={selectView}
									transactionOptions={transactionOptions}
									transactionTypeFilter={transactionTypeFilter}
									statusOptions={progressStatus.map((val, index) => { return ({ label: val, value: index }) })}
									statusFilter={statusFilter}
									data={invoiceTableViewData}
									columns={columnInvoices}
									applyFilter={applyFilter}
									downloadRef={downloadInvoiceRef}
									downloadTransactionData={downloadInvoiceData}
									transactionViewData={invoiceViewData}
								/>
								<ReactTable
									data={invoiceTableViewData}
									columns={[{
										Header: 'Id',
										accessor: 'requestKey',
										Cell: row => <a href="javascript:void(0);"><div onClick={() => this.onOpenTransactionModal(row.original.requestKey)}>{row.original.requestKey}</div></a>
									},...columnInvoice]}
									defaultSorted={[{ id: "date", desc: true }]}
									filterable={true}
									style={{ textAlign: 'center' }}
									defaultPageSize={10}
									defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
								/>
								<Modal styles={modalStyles} open={this.state.openTransactionModal} onClose={this.onCloseTransactionModal.bind(this)} closeOnEsc={false}>
										<div style={{padding:'10px'}}>
											<div style={{fontWeight:'bold', textAlign:'center', fontSize:'20px'}}>
												{"Transaction Details"}
											</div>
											<form className="form request_form row" style={{marginTop:'20px'}}>
												{	
													this.state.selectedTransaction?Object.keys(access[selectedTransactionType]).map((value, index) => { return access[selectedTransactionType][value].read[user.level] ? <InputDynamicType key={index} editable={false} {...this.state[value]} defaultValue={selectedTransactionDetails[value.split('_')[0]]}/> : "" }):""
												}
											</form>
										</div>
									</Modal>
							</div> : ""
						}

						{
							(selectView === "summary" && (this.props.user && (this.props.user.level == 1800 || this.props.user.level == 2600))) ? <div>
								<CSVLink filename={"Payment_Summary.csv"} data={vendorSummary} headers={columnPaymentSummary.map((val,index) => {return {label:val.Header,key:val.accessor}})} type="button" className="btn btn-info" style={{marginBottom:'20px'}}>
									Download
								</CSVLink>
								<ReactTable
									data={vendorSummary}
									columns={columnPaymentSummary}
									defaultSorted={[{ id: "date", desc: true }]}
									filterable={true}
									style={{ textAlign: 'center' }}
									defaultPageSize={10}
									defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
								/>
							</div> : ""
						}

						{
							(selectView === "bankView" && (this.props.user && (this.props.user.level == 1800 || this.props.user.level == 2600))) ? <div>
								<FilterView
									placeholder={"Select Bank"}
									name={"bankTransactions"}
									value={bankTransactions}
									options={bankOptions}
									handleOnChange={handleOnSelectChange}
									handleOnFilterChange={handleOnFilterChange}
									data={bankData}
									columns={columnBanks}
									user={user}
									selectView={selectView}
								/>
								{/* <CSVLink filename={"Bank_View_Data.csv"} data={bankData} headers={columnBanks.map((val,index) => {return {label:val.Header,key:val.csvAccessor||val.accessor}})} type="button" className="btn btn-info">Download</CSVLink> */}
								<ReactTable
									data={bankData}
									columns={columnBanks}
									defaultSorted={[{ id: "date", desc: true }]}
									filterable={true}
									style={{ textAlign: 'center' }}
									defaultPageSize={10}
									defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
								/>
							</div> : ""
						}
						{
							(selectView === "purchaseView") ? <div>
							<PurchaseSellFields
								handleOnViewChange={handleOnViewChange}
								user={user}
							/>
							<ModalReceiptView
								recieptView={recieptView}
								onCloseModal={onCloseModal}
							/>
							{
								user.level >= 1600 && ["panels", "inverter", "ACDB", "DCDB"].includes(currentSelected) ? <div className="mainBox container-fluid">
									<div className="card formCard shadow">
										<div className="card-title container-fluid">
											<div className="formTitle">
												<div className="formSubPart1">
													<h4> {formName ? formName : "Form Name"} </h4>
												</div>
												<div className="formSubPart2">
													{currentSelected != "other" ? <button className="btn btn-success" style={{ margin: "0 10px" }} onClick={handleOnCalculateClick}>Calculate</button> : ""
													}

													<button className="btn btn-success" onClick={() => checkWarningsProcurement('Purchase', 'formSubmit', {}, 6)}>Submit</button>
												</div>
											</div>
										</div>
										<div className="card-body">
											<form className="form request_form row">
												{
													Object.keys(purchaseFormAccess[currentSelected]?purchaseFormAccess[currentSelected]:{}).map((value, index) => { return purchaseFormAccess[currentSelected][value].read[user.level] ? <InputDynamicType key={index} editable={purchaseFormAccess[currentSelected][value].write[user.level]} {...this.state[value]} /> : "" })
												}
											</form>
										</div>
									</div>
								</div> : ""
							}
						</div> : ""
						}
						{
							(selectView === "sellView") ? <div>
								<PurchaseSellFields
								handleOnViewChange={handleOnViewChange}
								user={user}
							/>
							<ModalReceiptView
								recieptView={recieptView}
								onCloseModal={onCloseModal}
							/>
							{
								user.level >= 1600 && ["panels", "inverter", "ACDB", "DCDB"].includes(currentSelected) ? <div className="mainBox container-fluid">
									<div className="card formCard shadow">
										<div className="card-title container-fluid">
											<div className="formTitle">
												<div className="formSubPart1">
													<h4> {formName ? formName : "Form Name"} </h4>
												</div>
												<div className="formSubPart2">
													{currentSelected != "other" ? <button className="btn btn-success" style={{ margin: "0 10px" }} onClick={handleOnCalculateClick}>Calculate</button> : ""
													}

													<button className="btn btn-success" onClick={() => checkWarningsProcurement('Sell', 'formSubmit', {}, 6)}>Submit</button>
												</div>
											</div>
										</div>
										<div className="card-body">
											<form className="form request_form row">
												{
													Object.keys(sellFormAccess[currentSelected]?sellFormAccess[currentSelected]:{}).map((value, index) => { return sellFormAccess[currentSelected][value].read[user.level] ? <InputDynamicType key={index} editable={sellFormAccess[currentSelected][value].write[user.level]} {...this.state[value]} /> : "" })
												}
											</form>
										</div>
									</div>
								</div> : ""
							}
							</div> : ""
						}
						{
							(selectView === "inventoryView") ? <div>
								<PurchaseSellFields
									handleOnViewChange={handleOnViewChange}
									user={user}
								/>
								{/* <CSVLink filename={`${currentSelected}.csv`} data={inventoryData && inventoryData[currentSelected]?inventoryData[currentSelected]:[]} headers={(inventoryConfig[currentSelected]||[]).map((val,index) => {return {label:typeof val.Header!='function'?val.Header:val.accessor,key:val.accessor}})} type="button" className="btn btn-info" style={{marginTop:'20px'}}>
									Download
								</CSVLink> */}
								{/* {
									["panels", "inverter", "ACDB", "DCDB"].includes(currentSelected)?<div style={{marginTop:'30px'}}><ReactTable
									data={inventoryData[currentSelected]}
									columns={(inventoryConfig[currentSelected]||[])}
									defaultSorted={[{ id: "date", desc: true }]}
									filterable={true}
									style={{ textAlign: 'center' }}
									defaultPageSize={10}
									defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
								/></div>:""
								} */}
								{
									currentSelected == "panels" ? <div style={{ marginTop: '30px' }}>
										<FilterViewInventory
											handleOnChange={handleOnSelectChangeTransaction}
											vendorOptions={vendorMap}
											inventoryVendorSelected={inventoryVendorSelected}
											brandOptions={brandOptions}
											inventoryBrandSelected={inventoryBrandSelected}
											makeOptions={panelTypeOptions}
											inventoryMakeSelected={inventoryMakeSelected}
											currentSelected={currentSelected}
											applyFilter={applyFilterInventory}
											data={inventoryDataView && inventoryDataView[currentSelected]?inventoryDataView[currentSelected]:[]}
											headers={(inventoryConfig[currentSelected]||[]).map((val,index) => {return {label:typeof val.Header!='function'?val.Header:val.accessor,key:val.accessor}})}
										/>
										<ReactTable
											data={inventoryDataView[currentSelected]}
											columns={(inventoryConfig[currentSelected] || [])}
											defaultSorted={[{ id: "date", desc: true }]}
											filterable={true}
											style={{ textAlign: 'center' }}
											defaultPageSize={10}
											defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
										/></div> : ""
								}
								{
									currentSelected == "inverter" ? <div style={{ marginTop: '30px' }}>
										<FilterViewInventory
											handleOnChange={handleOnSelectChangeTransaction}
											vendorOptions={vendorMap}
											inventoryVendorSelected={inventoryVendorSelected}
											brandOptions={inverterOptions}
											inventoryBrandSelected={inventoryBrandSelected}
											phaseOptions={phaseOptions}
											inventoryPhaseSelected={inventoryPhaseSelected}
											currentSelected={currentSelected}
											applyFilter={applyFilterInventory}
											data={inventoryDataView && inventoryDataView[currentSelected]?inventoryDataView[currentSelected]:[]}
											headers={(inventoryConfig[currentSelected]||[]).map((val,index) => {return {label:typeof val.Header!='function'?val.Header:val.accessor,key:val.accessor}})}
										/>
										<ReactTable
											data={inventoryDataView[currentSelected]}
											columns={(inventoryConfig[currentSelected] || [])}
											defaultSorted={[{ id: "date", desc: true }]}
											filterable={true}
											style={{ textAlign: 'center' }}
											defaultPageSize={10}
											defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
										/></div> : ""
								}
								{
									currentSelected == "ACDB" ? <div style={{ marginTop: '30px' }}>
										<FilterViewInventory
											handleOnChange={handleOnSelectChangeTransaction}
											vendorOptions={vendorMap}
											inventoryVendorSelected={inventoryVendorSelected}
											cityOptions={cityOptions}
											inventoryCitySelected={inventoryCitySelected}
											makeOptions={acdbMakeOptions}
											inventoryMakeSelected={inventoryMakeSelected}
											currentSelected={currentSelected}
											applyFilter={applyFilterInventory}
											data={inventoryDataView && inventoryDataView[currentSelected]?inventoryDataView[currentSelected]:[]}
											headers={(inventoryConfig[currentSelected]||[]).map((val,index) => {return {label:typeof val.Header!='function'?val.Header:val.accessor,key:val.accessor}})}
										/>
										<ReactTable
											data={inventoryDataView[currentSelected]}
											columns={(inventoryConfig[currentSelected] || [])}
											defaultSorted={[{ id: "date", desc: true }]}
											filterable={true}
											style={{ textAlign: 'center' }}
											defaultPageSize={10}
											defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
										/></div> : ""
								}
								{
									currentSelected == "DCDB" ? <div style={{ marginTop: '30px' }}>
										<FilterViewInventory
											handleOnChange={handleOnSelectChangeTransaction}
											vendorOptions={vendorMap}
											inventoryVendorSelected={inventoryVendorSelected}
											cityOptions={cityOptions}
											inventoryCitySelected={inventoryCitySelected}
											makeOptions={acdbMakeOptions}
											inventoryMakeSelected={inventoryMakeSelected}
											currentSelected={currentSelected}
											applyFilter={applyFilterInventory}
											data={inventoryDataView && inventoryDataView[currentSelected]?inventoryDataView[currentSelected]:[]}
											headers={(inventoryConfig[currentSelected]||[]).map((val,index) => {return {label:typeof val.Header!='function'?val.Header:val.accessor,key:val.accessor}})}
										/>
										<ReactTable
											data={inventoryDataView[currentSelected]}
											columns={(inventoryConfig[currentSelected] || [])}
											defaultSorted={[{ id: "date", desc: true }]}
											filterable={true}
											style={{ textAlign: 'center' }}
											defaultPageSize={10}
											defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
										/></div> : ""
								}
							</div> : ""
						}
					</div>
				</div>
			)
		}

		return (
			<div style={styleSheet.frameOne} className="container-fluid">
				<Loader />
			</div>
		);
	}
}

function mapStateToProps({ user, vendortickets, mapping }) {
	let { loading, quoteIdList, quoteIdDetails, dataReference, dueAmount, processLedgerData, error, success, duePayment, bankData, vendorSummary, invoiceData, inventoryData } = vendortickets;
	let { employeeMap, vendorMap } = mapping;
	return {
		user: user,
		loading,
		quoteIdList,
		error,
		quoteIdDetails,
		dataReference,
		dueAmount,
		processLedgerData,
		employeeMap,
		vendorMap,
		error,
		success,
		duePayment,
		bankData,
		vendorSummary,
		invoiceData,
		inventoryData
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchvendorquoteids, fetchquoteid, requestNewPayment, fetchrequestlist, fetchmapping, fetchrequestsummary, approvePayment, requestNewTransaction, fetchquoteidprocessedtransaction, fetchvendorduepayment, fetchBankTransactions, fetchVendorSummary, fetchInvoiceData, updateInvoiceData, uploadInvoiceFile, downloadInvoice, updateInvoiceApprovalData, fetchInventory, approveRejectSeletedPayments }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Vendor); 
