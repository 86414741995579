import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import { Restrict } from '../components/Restrict';
import '../styles/orderStageTracker.css';
import { fetchmainview } from '../actions/fetchmainview';
import { fetchmapping } from '../actions/fetchmapping';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';

const netMeteringAppliedReport = [
    { label: 'Applied (<20)', key: 'nm_a__lt_20' },
    { label: 'Applied (20-40)', key: 'nm_a__20_40' },
    { label: 'Applied (>40)', key: 'nm_a__gt_40' }
];

const netMeteringNotAppliedReport = [
    { label: 'Not Applied (<10)', key: 'nm_na__lt_10' },
    { label: 'Not Applied (10-20)', key: 'nm_na__10_20' },
    { label: 'Not Applied (>20)', key: 'nm_na__gt_20' }
];

const installationReport = [
    { label: '< 10', key: 'i__lt_10' },
    { label: '11 - 15', key: 'i__10_15' },
    { label: '> 15', key: 'i__gt_15' }
];

const subsidyNotAppliedReport = [
    { label: '< 5', key: 'sna__lt_5' },
    { label: '>= 5', key: 'sna__gte_5' }
];

const ebNotAppliedReport = [
    { label: 'Name change (< 15)', key: 'ebna_nc__lt_15' },
    { label: 'Name change (>= 15)', key: 'ebna_nc__gte_15' },
    { label: 'Load change (< 15)', key: 'ebna_lc__lt_15' },
    { label: 'Load change (>= 15)', key: 'ebna_lc__gte_15' },
    { label: 'EB other (< 15)', key: 'ebna_o__lt_15' },
    { label: 'EB other (>= 15)', key: 'ebna_o__gte_15' }
];

const ebAppliedReport = [
    { label: 'Name change (< 45)', key: 'eba_nc__lt_45' },
    { label: 'Name change (>= 45)', key: 'eba_nc__gte_45' },
    { label: 'Load change (< 45)', key: 'eba_lc__lt_45' },
    { label: 'Load change (>= 45)', key: 'eba_lc__gte_45' },
    { label: 'EB other (< 45)', key: 'eba_o__lt_45' },
    { label: 'EB other (>= 45)', key: 'eba_o__gte_45' }
];

const selectCustom = (options, name, placeholder, handleMultiSelectChange, value = []) => {
	const changeHandle = (value) => { handleMultiSelectChange(value,name) }
	return (
		<div className="drop_down1">
			<Select
				isMulti
				isSearchable
			    onChange = {(e) => {changeHandle(e)}}
			    placeholder={placeholder}
			    name={name}
				options={options}
                value={options.filter(row => (value || []).includes(row.value))}
			/>
		</div>
	)
}

class OrderStageTracker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stage1Count: {},
            stage2Count: {},
            stage1CountNC: {},
            stage2CountNC: {},
            stageData: [],
            structureData: [],
            panelData: [],
            v2Data: [],
            inverterData: [],
            selectedStates: [],
            selectedCities: [],
            cityOptions: [],
            loanProviderData: [],
            selectedNextAction: [],
            nextActionOptions: [],
            salesDateStart: '',
            salesDateEnd: '',
            dcrPendingData: []
        };
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.level >= 1600) {
                this.setStateCity();
                this.props.fetchmainview();
                this.props.fetchmapping();
            }
        }
    }

    setStateCity = () => {
        try {
            let selectedStatesST = localStorage.getItem("selectedStatesST") || "[]";
            let selectedCitiesST = localStorage.getItem("selectedCitiesST") || "[]";
            let cityOptionsST = localStorage.getItem("cityOptionsST") || "[]";
            this.setState({
                selectedStates: JSON.parse(selectedStatesST),
                selectedCities: JSON.parse(selectedCitiesST),
                cityOptions: JSON.parse(cityOptionsST)
            })
        }
        catch(error) {
            console.log("Error", error);
        }
    }

    componentWillReceiveProps(props) {
        const { data, completed } = props;
        if(completed && Object.keys(data).length) {
            this.applyFilter();
        }
    }

    applyFilter = () => {
        const { data, completed } = this.props;
        let { selectedStates = [], selectedCities = [], cityOptions = [], selectedNextAction = [], salesDateStart = '', salesDateEnd = '' } = this.state;
        console.log(salesDateStart, salesDateEnd);
        console.log("selected", selectedStates, selectedCities);
        localStorage.setItem("selectedStatesST", JSON.stringify(selectedStates));
        localStorage.setItem("selectedCitiesST", JSON.stringify(selectedCities));
        localStorage.setItem("cityOptionsST", JSON.stringify(cityOptions));
        let newData = Object.values({ ...data }).filter(val => val.stage && val.stage != 11 && (selectedStates.length == 0  || selectedStates.includes(val.stateD)) && (selectedCities.length == 0 || selectedCities.includes(val.city)) && (selectedNextAction.length == 0 || selectedNextAction.includes((val.nextAction || "").trim().toLowerCase()))
        && (!salesDateStart || +val.salesdate >= +moment(salesDateStart).startOf('day').format('x'))
        && (!salesDateEnd || +val.salesdate <= +moment(salesDateEnd).endOf('day').format('x')));
        console.log(newData)
        let nextActionOptions = {};
        Object.values({ ...data }).forEach(row => {
            if(row.nextAction) {
                nextActionOptions[(row.nextAction || "").trim().toLowerCase()] = 1;
            }
        })
        let stage1Count = {};
        let stage2Count = {};
        let stageMap = {};
        newData.map(row => {
            stage1Count[row.stage1] = (stage1Count[row.stage1] || 0) + 1;
            stage2Count[`${row.stage1}__${row.stage2}`] = (stage2Count[`${row.stage1}__${row.stage2}`] || 0) + 1;
            stageMap[row.stage] = (stageMap[row.stage] || 0) + 1;
        })
        let stageData = newData.map(row => {
            if (!row.stageVariables) return {};
            try {
                return { ...JSON.parse(row.stageVariables), city: row.city || "", loanProvider: row.loanProvider || "", panelExpectedDate: row.panelExpectedDate || 0 };
            }
            catch (error) {
                return {};
            }
        })
        let structureDataMap = {}, structureData = [];
        stageData.forEach(row => {
            if (row.structurePending) {
                structureDataMap[row.city] = (structureDataMap[row.city] || 0) + 1;
            }
        })
        Object.keys(structureDataMap).forEach(city => {
            structureData.push({ label: city, value: structureDataMap[city] })
        })
        structureData = structureData.sort((a, b) => b.value - a.value);
        let panelDataMap = {}, panelData = [];
        stageData.forEach(row => {
            if (row.panelPending) {
                panelDataMap[row.city] = (panelDataMap[row.city] || 0) + 1;
            }
        })
        Object.keys(panelDataMap).forEach(city => {
            panelData.push({ label: city, value: panelDataMap[city] })
        })
        panelData = panelData.sort((a, b) => b.value - a.value);
        let inverterDataMap = {}, inverterData = [];
        stageData.forEach(row => {
            if (row.inverterPending) {
                inverterDataMap[row.city] = (inverterDataMap[row.city] || 0) + 1;
            }
        })
        Object.keys(inverterDataMap).forEach(city => {
            inverterData.push({ label: city, value: inverterDataMap[city] })
        })
        inverterData = inverterData.sort((a, b) => b.value - a.value);
        let dcrPendingMap = {}, dcrPendingData = [];
        stageData.forEach(row => {
            if (row.dcrPending) {
                let panelMonth = "NA";
                console.log("row.panelExpectedDate", row.panelExpectedDate);
                if(row.panelExpectedDate && moment(+row.panelExpectedDate).isValid()) panelMonth = moment(+row.panelExpectedDate).format("MMM YYYY");
                dcrPendingMap[panelMonth] = (dcrPendingMap[panelMonth] || 0) + 1;
            }
        })
        Object.keys(dcrPendingMap).forEach(panelMonth => {
            dcrPendingData.push({ label: panelMonth, value: dcrPendingMap[panelMonth] })
        })
        dcrPendingData = dcrPendingData.sort((a, b) => b.value - a.value);
        let v2DataMap = {}, v2Data = [];
        stageData.forEach(row => {
            if (row.v2NotAssigned) {
                v2DataMap[row.city] = (v2DataMap[row.city] || 0) + 1;
            }
        })
        Object.keys(v2DataMap).forEach(city => {
            v2Data.push({ label: city, value: v2DataMap[city] })
        })
        v2Data = v2Data.sort((a, b) => b.value - a.value);
        let loanProviderMap = {}, loanProviderData = [];
        stageData.forEach(row => {
            if (row.stage2 == 'Loan Approval') {
                row.loanProvider = row.loanProvider || "NA";
                loanProviderMap[row.loanProvider] = (loanProviderMap[row.loanProvider] || 0) + 1;
            }
        })
        Object.keys(loanProviderMap).forEach(loanProvider => {
            loanProviderData.push({ label: loanProvider, value: loanProviderMap[loanProvider] })
        })
        loanProviderData = loanProviderData.sort((a, b) => b.value - a.value);
        console.log(stageMap, newData.length);
        let clientNotContacted = stageData.filter(row => row[`lc__gt_15`]);
        let stage1CountNC = {};
        let stage2CountNC = {};
        clientNotContacted.map(row => {
            stage1CountNC[row.stage1] = (stage1CountNC[row.stage1] || 0) + 1;
            stage2CountNC[`${row.stage1}__${row.stage2}`] = (stage2CountNC[`${row.stage1}__${row.stage2}`] || 0) + 1;
        })
        this.setState({
            stage1Count,
            stage2Count,
            stageData: stageData,
            structureData: structureData,
            stage1CountNC,
            stage2CountNC,
            panelData,
            inverterData,
            v2Data,
            loanProviderData,
            nextActionOptions: Object.keys(nextActionOptions),
            dcrPendingData: dcrPendingData
        })
    }

    handleMultiSelectChange = (value, name) => {
        console.log(value, name);
        let { stateCityMap = {} } = this.props;
        let { selectedCities = [] } = this.state;
        if(name == "selectedStates") {
            let cityList = [];
            (value || []).map(row => {
                cityList = [ ...cityList, ...(stateCityMap[row.value] || []) ]
            })
            selectedCities = selectedCities.filter(city => cityList.includes(city));
            this.setState({ cityOptions: cityList, selectedCities: selectedCities });
        }
        this.setState({ [name]: (value || []).map(row => row.value) });
    }

    handleInputFieldChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {

        if (this.props.user === null) {
            return <Redirect to={"/login?redirect_uri=stage-tracker"} />;
        }

        let { stage1Count, stage2Count, stageData = [], structureData = [], stage1CountNC, stage2CountNC, panelData = [], inverterData = [], v2Data = [], loanProviderData = [], dcrPendingData = [] } = this.state;

        return (
            <div style={{ "marginTop": 50 }} className="container-fluid">
                <div className="stageTrackerMain">
                    <div className="heading">
                        <h1>Order Stage Tracker</h1>
                    </div>
                    <div className="stageTrackerFilters row">
                        {/* <div className="col-md-3"></div> */}
                        <div className="col-md-2 col-6 filter_box ">
                            {selectCustom((this.props.stateMap || []).map(row => ({ label: row, value: row })), "selectedStates", "Choose State", this.handleMultiSelectChange, this.state.selectedStates || [])}
                        </div>
                        <div className="col-md-2 col-6 filter_box ">
                            {selectCustom((this.state.cityOptions || []).map(row => ({ label: row, value: row })), "selectedCities", "Choose City", this.handleMultiSelectChange, this.state.selectedCities || [])}
                        </div>
                        <div className="col-md-2 col-6 filter_box ">
                            {selectCustom((this.state.nextActionOptions || []).map(row => ({ label: row, value: row })), "selectedNextAction", "Choose next action", this.handleMultiSelectChange, this.state.selectedNextAction || [])}
                        </div>
                        <div className="col-lg-2 col-6" style={{ padding: '0px 15px', margin: 'auto 0' }}>
                            <input
                                style={{ width: "100%", borderRadius: 5, padding: '8px 8px', border: '1px solid grey' }}
                                onChange={this.handleInputFieldChange}
                                name="salesDateStart"
                                type='date'
                            />
                        </div>
                        <div className="col-lg-2 col-6" style={{ padding: '0px 10px', margin: 'auto 0' }}>
                            <input
                                style={{ width: "100%", borderRadius: 5, padding: '8px 8px', border: '1px solid grey' }}
                                onChange={this.handleInputFieldChange}
                                name="salesDateEnd"
                                type='date'
                            />
                        </div>
                        <div className="col-md-2 col-6 filter_box filter_box_button" style={{ margin: "auto 0" }}>
							<div className="filter_selector__drop_down_label1"></div>
							<button className="btn btn-success applyButton" onClick={this.applyFilter}>Apply Filter</button>
						</div>
                    </div>
                    <div className="container-custom">
                        <div className="stage">
                            <div className="stage-header">
                                <h3>
                                    <Link to={"/deals?selectedStage=Project Preparation"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation"); }}>Project Preparation</Link>
                                </h3>
                                <div className="order-count">Orders: {stage1Count['Project Preparation'] || 0}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Layout Approval"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Layout Approval"); }}>Layout Approval
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Project Preparation__Layout Approval']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__EB Issue"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__EB Issue"); }}>EB Issue
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Project Preparation__EB Issue']}</div>
                            </div>
                            {/* <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Subsidy Application"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Subsidy Application"); }}>Subsidy Application
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Project Preparation__Subsidy Application']}</div>
                            </div> */}
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Subsidy Approval"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Subsidy Approval"); }}>Subsidy Approval
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Project Preparation__Subsidy Approval']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Loan Approval"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Loan Approval"); }}>Loan Approval
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Project Preparation__Loan Approval']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Payment Completion"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Payment Completion"); }}>Payment Completion
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Project Preparation__Payment Completion']}</div>
                            </div>
                        </div>
                        <div className="stage">
                            <div className="stage-header">
                                <h3>
                                    <Link to={"/deals?selectedStage=Product Delivery"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery"); }}>Product Delivery</Link>
                                </h3>
                                <div className="order-count">Orders: {stage1Count['Product Delivery'] || 0}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Inverter"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Inverter"); }}>Inverter
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Product Delivery__Inverter']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Payment Completion"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Payment Completion"); }}>Payment Completion
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Product Delivery__Payment Completion']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Structure"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Structure"); }}>Structure
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Product Delivery__Structure']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Panel"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Panel"); }}>Panel
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Product Delivery__Panel']}</div>
                            </div>
                        </div>
                        <div className="stage">
                            <div className="stage-header">
                                <h3>Installation</h3>
                                <div className="order-count">Orders: {(stage2Count['Installation__Installation'] || 0) + (stage2Count['Net metering not applied__Net metering not applied'] || 0) + (stage2Count['Net metering applied__Net metering applied'] || 0) + (stage2Count['Subsidy closure__Subsidy closure'] || 0)}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Installation__Installation"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Installation__Installation"); }}>Installation
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Installation__Installation']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Net metering not applied__Net metering not applied"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Net metering not applied__Net metering not applied"); }}>Net metering not applied
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Net metering not applied__Net metering not applied']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Net metering applied__Net metering applied"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Net metering applied__Net metering applied"); }}>Net metering applied
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Net metering applied__Net metering applied']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Subsidy closure__Subsidy closure"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Subsidy closure__Subsidy closure"); }}>Subsidy closure
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Subsidy closure__Subsidy closure']}</div>
                            </div>
                        </div>
                        <div className="stage">
                        <div className="stage-header">
                                <h3>Completed</h3>
                                <div className="order-count">Orders: {(stage2Count['Delivered__Delivered'] || 0) + (stage2Count['Serviced__Serviced'] || 0) + (stage2Count['Canceled__Canceled'] || 0)}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Delivered__Delivered"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Delivered__Delivered"); }}>Delivered
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Delivered__Delivered']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Serviced__Serviced"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Serviced__Serviced"); }}>Serviced
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Serviced__Serviced']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Canceled__Canceled"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Canceled__Canceled"); }}>Canceled
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2Count['Canceled__Canceled']}</div>
                            </div>
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                                <h3><Link to={"/deals?selectedStage=Net metering not applied__Net metering not applied"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Net metering not applied__Net metering not applied"); }}>Net metering not applied
                                </Link></h3>
                                <div className="order-count">Orders: {stage2Count['Net metering not applied__Net metering not applied']}</div>
                            </div>
                            {
                                netMeteringNotAppliedReport.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        <Link to={"/deals?selectedVariable=" + row.key} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=" + row.key); }}>{row.label}
                                        </Link>
                                    </div>
                                    <div className="order-count">Orders: {stageData.filter(orderRow => orderRow[row.key]).length}</div>
                                </div>
                                })
                            }
                            <div className='mt-3'></div>
                            <div className="stage-header">
                                <h3><Link to={"/deals?selectedStage=Net metering applied__Net metering applied"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Net metering applied__Net metering applied"); }}>Net metering applied
                                </Link></h3>
                                <div className="order-count">Orders: {stage2Count['Net metering applied__Net metering applied']}</div>
                            </div>
                            {
                                netMeteringAppliedReport.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        <Link to={"/deals?selectedVariable=" + row.key} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=" + row.key); }}>{row.label}
                                        </Link>
                                    </div>
                                    <div className="order-count">Orders: {stageData.filter(orderRow => orderRow[row.key]).length}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                                <h3><Link to={"/deals?selectedStage=Installation__Installation"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Installation__Installation"); }}>Installation
                                </Link></h3>
                                <div className="order-count">Orders: {stage2Count['Installation__Installation']}</div>
                            </div>
                            {
                                installationReport.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        <Link to={"/deals?selectedVariable=" + row.key} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=" + row.key); }}>{row.label}
                                        </Link>
                                    </div>
                                    <div className="order-count">Orders: {stageData.filter(orderRow => orderRow[row.key]).length}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                            <h3><Link to={"/deals?selectedVariable=panelPending"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=panelPending"); }}>Panel pending
                                </Link></h3>
                                <div className="order-count">Orders: {panelData.reduce((sum, val) => sum+ val.value, 0)}</div>
                            </div>
                            {
                                panelData.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        {row.label}
                                    </div>
                                    <div className="order-count">Orders: {row.value || 0}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                            <h3><Link to={"/deals?selectedVariable=structurePending"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=structurePending"); }}>Structure pending
                                </Link></h3>
                                <div className="order-count">Orders: {structureData.reduce((sum, val) => sum+ val.value, 0)}</div>
                            </div>
                            {
                                structureData.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        {row.label}
                                    </div>
                                    <div className="order-count">Orders: {row.value || 0}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                            <h3><Link to={"/deals?selectedVariable=inverterPending"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=inverterPending"); }}>Inverter pending
                                </Link></h3>
                                <div className="order-count">Orders: {inverterData.reduce((sum, val) => sum+ val.value, 0)}</div>
                            </div>
                            {
                                inverterData.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        {row.label}
                                    </div>
                                    <div className="order-count">Orders: {row.value || 0}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                            <h3><Link to={"/deals?selectedStage=Project Preparation__Loan Approval"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Loan Approval"); }}>Loan Provider
                                </Link></h3>
                                <div className="order-count">Orders: {stage2Count['Project Preparation__Loan Approval']}</div>
                            </div>
                            {
                                loanProviderData.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        {row.label}
                                    </div>
                                    <div className="order-count">Orders: {row.value || 0}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                                {/* <h3><Link to={"/deals?selectedStage=Installation__Installation"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Installation__Installation"); }}>Subsidy Not Applied
                                </Link></h3>
                                <div className="order-count">Orders: {stage2Count['Installation__Installation']}</div> */}
                                <h3>Subsidy not applied</h3>
                            </div>
                            {
                                subsidyNotAppliedReport.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        <Link to={"/deals?selectedVariable=" + row.key} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=" + row.key); }}>{row.label}
                                        </Link>
                                    </div>
                                    <div className="order-count">Orders: {stageData.filter(orderRow => orderRow[row.key]).length}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                                <h3>EB not applied</h3>
                            </div>
                            {
                                ebNotAppliedReport.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        <Link to={"/deals?selectedVariable=" + row.key} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=" + row.key); }}>{row.label}
                                        </Link>
                                    </div>
                                    <div className="order-count">Orders: {stageData.filter(orderRow => orderRow[row.key]).length}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                                <h3>EB Applied</h3>
                            </div>
                            {
                                ebAppliedReport.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        <Link to={"/deals?selectedVariable=" + row.key} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=" + row.key); }}>{row.label}
                                        </Link>
                                    </div>
                                    <div className="order-count">Orders: {stageData.filter(orderRow => orderRow[row.key]).length}</div>
                                </div>
                                })
                            }
                        </div>
                        <div className="stage-new">
                            <div className="stage-header">
                            <h3><Link to={"/deals?selectedVariable=v2NotAssigned"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=v2NotAssigned"); }}>V2 not assigned
                                </Link></h3>
                                <div className="order-count">Orders: {v2Data.reduce((sum, val) => sum+ val.value, 0)}</div>
                            </div>
                            {
                                v2Data.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        {row.label}
                                    </div>
                                    <div className="order-count">Orders: {row.value || 0}</div>
                                </div>
                                })
                            }
                            <div className='mt-3'></div>
                            <div className="stage-header">
                                <h3><Link to={"/deals?selectedVariable=dcrPending"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedVariable=dcrPending"); }}>DCR Pending
                                </Link></h3>
                                <div className="order-count">Orders: {dcrPendingData.reduce((sum, val) => sum+ val.value, 0)}</div>
                            </div>
                            {
                                dcrPendingData.map(row => {
                                    return <div className="sub-stage">
                                    <div className="sub-stage-name">
                                        {row.label}
                                    </div>
                                    <div className="order-count">Orders: {row.value || 0}</div>
                                </div>
                                })
                            }
                        </div>
                        <p>Client not reached out for more than 15 days</p>
                        <div className="stage">
                            <div className="stage-header">
                                <h3>
                                    <Link to={"/deals?selectedStage=Project Preparation___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation___lc__gt_15"); }}>Project Preparation</Link>
                                </h3>
                                <div className="order-count">Orders: {stage1CountNC['Project Preparation'] || 0}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Layout Approval___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Layout Approval___lc__gt_15"); }}>Layout Approval
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Project Preparation__Layout Approval']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__EB Issue___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__EB Issue___lc__gt_15"); }}>EB Issue
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Project Preparation__EB Issue']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Subsidy Application___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Subsidy Application___lc__gt_15"); }}>Subsidy Application
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Project Preparation__Subsidy Application']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Loan Approval___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Loan Approval___lc__gt_15"); }}>Loan Approval
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Project Preparation__Loan Approval']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Project Preparation__Payment Completion___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Project Preparation__Payment Completion___lc__gt_15"); }}>Payment Completion
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Project Preparation__Payment Completion']}</div>
                            </div>
                        </div>
                        <div className="stage">
                            <div className="stage-header">
                                <h3>
                                    <Link to={"/deals?selectedStage=Product Delivery___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery___lc__gt_15"); }}>Product Delivery</Link>
                                </h3>
                                <div className="order-count">Orders: {stage1CountNC['Product Delivery'] || 0}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Inverter___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Inverter___lc__gt_15"); }}>Inverter
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Product Delivery__Inverter']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Subsidy Approval___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Subsidy Approval___lc__gt_15"); }}>Subsidy Approval
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Product Delivery__Subsidy Approval']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Payment Completion___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Payment Completion___lc__gt_15"); }}>Payment Completion
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Product Delivery__Payment Completion']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Structure___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Structure___lc__gt_15"); }}>Structure
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Product Delivery__Structure']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Product Delivery__Panel___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Product Delivery__Panel___lc__gt_15"); }}>Panel
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Product Delivery__Panel']}</div>
                            </div>
                        </div>
                        <div className="stage">
                            <div className="stage-header">
                                <h3>Installation</h3>
                                <div className="order-count">Orders: {(stage2CountNC['Installation__Installation'] || 0) + (stage2CountNC['Net metering__Net metering'] || 0) + (stage2CountNC['Subsidy closure__Subsidy closure'] || 0)}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Installation__Installation___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Installation__Installation___lc__gt_15"); }}>Installation
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Installation__Installation']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Net metering__Net metering___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Net metering__Net metering___lc__gt_15"); }}>Net metering
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Net metering__Net metering']}</div>
                            </div>
                            <div className="sub-stage">
                                <div className="sub-stage-name">
                                    <Link to={"/deals?selectedStage=Subsidy closure__Subsidy closure___lc__gt_15"} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/deals?selectedStage=Subsidy closure__Subsidy closure___lc__gt_15"); }}>Subsidy closure
                                    </Link>
                                </div>
                                <div className="order-count">Orders: {stage2CountNC['Subsidy closure__Subsidy closure']}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    let { data, lastUpdatedTime, completed } = state.dealsdata;
    let { stateMap = [], stateCityMap = {} } = state.mapping || {};
    return {
        user: state.user,
        completed,
        data,
        stateMap: stateMap,
        stateCityMap: stateCityMap
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchmainview, fetchmapping }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderStageTracker);
