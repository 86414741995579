import React, { Component } from 'react';
import Select from 'react-select';

import { styleSheet } from '../styles/styleSheet';
import { dateChanger, changeToViewDate, inputDateFormat, inputDateFormatChange } from '../helper/dateChanger';
import { vendorDataName, vendorPaymentQuoteIdColumns, columnsPerComments } from '../config/column_fields';
import moment from 'moment';
import ReactTable from "react-table";
import Modal from 'react-responsive-modal';

const stageMapping = {
	'Project Preparation': [ 'Layout Approval', 'EB Issue', 'Subsidy Approval', 'Loan Approval', 'Payment Completion' ],
	'Product Delivery': [ 'Inverter', 'Payment Completion', 'Structure', 'Panel' ],
	'Installation': [ 'Installation', 'Net metering not applied', 'Net metering applied', 'Subsidy closure' ],
	'Delivered': [ 'Delivered' ]
}

export class FrameOne extends Component {

	constructor(props) {
	  super(props);

	  this.state = {
	  	edit: false,
	  	hover:false,
	  	name:'',
	  	vendor:'',
	  	inc:'',
	  	ebissue:[],
		loanissue:"",
	  	date: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' +  new Date().getFullYear(),
	  	ebissueselected:[],
	  	ebIssueList:[],
		viewContent:"Summary",
		panelVendorOptions:[],
		panelVendorSelected:{},
		panelVendorEditOption:false,
		verifyOptionClicked:false,
		otpPanelVendor:"",
		panelOtpVerifiedStatus:0,
		vendorList:[],
		structureVendors:[],
		structureVendorSelected:'',
		certificateData:[{item:'Panel',testLab:'Vikram'},{item:'Inverter',testLab:'Delta'}],
		certificateDataEdited:false,
		amcPeriod:'',
		warrantyYears80: '',
		warrantyYears90: '',
		isPrime : Boolean,
		tenderYearOptions: [],
		tenderYear: '',
		tenderYearChanged: false,
		size_del: '',
		size_delChange: false,
		vendorPayments: [],
		commentStage1: '',
		commentStage2: '',
		commentsData: []
	  };

	  this.hoverOn 							= this.hoverOn.bind(this);
	  this.hoverOff 						= this.hoverOff.bind(this);
	  this.handleEdit 						= this.handleEdit.bind(this);
	  this.handleClick 						= this.handleClick.bind(this);
	  this.handleInputChange 				= this.handleInputChange.bind(this);
	  this.handleInputEBIssueChange 		= this.handleInputEBIssueChange.bind(this);
	  this.handleInputLoanIssueChange 		= this.handleInputLoanIssueChange.bind(this);
	  this.handleSelectVendor1Change 		= this.handleSelectVendor1Change.bind(this);
	  this.handleSelectVendor2Change 		= this.handleSelectVendor2Change.bind(this);
	  this.handleInputDateChange 			= this.handleInputDateChange.bind(this);
	  this.handleInputRefDateChange 		= this.handleInputRefDateChange.bind(this);
	  this.handleInputSalesDateChange 		= this.handleInputSalesDateChange.bind(this);
	  this.handleSelectCityChange 			= this.handleSelectCityChange.bind(this);
	  this.handleInputStageChange 			= this.handleInputStageChange.bind(this);
	  this.handleInputElevationDetailsChange= this.handleInputElevationDetailsChange.bind(this);
	  this.handleInputDeliveredPanelChange 	= this.handleInputDeliveredPanelChange.bind(this);
	  this.handleSubsidyStatusInputChange 	= this.handleSubsidyStatusInputChange.bind(this);
	  this.handleSubsidyStatusDateInputChange= this.handleSubsidyStatusDateInputChange.bind(this);
	  this.handleInputDeliveredInverterChange= this.handleInputDeliveredInverterChange.bind(this);
	  this.handleInputSubsidyDChange 		= this.handleInputSubsidyDChange.bind(this);
	  this.handleInputStateDChange 			= this.handleInputStateDChange.bind(this);
	  this.handleInputSanctionedLoadChange 	= this.handleInputSanctionedLoadChange.bind(this);
	  this.handleInputPhaseChange 			= this.handleInputPhaseChange.bind(this);
	  this.handleInputInverterDateChange 	= this.handleInputInverterDateChange.bind(this);
	  this.handleInputStructureDateChange 	= this.handleInputStructureDateChange.bind(this);
	  this.handleInputPanelDateChange 		= this.handleInputPanelDateChange.bind(this);
	  this.handleInputPromisedPanelChange 	= this.handleInputPromisedPanelChange.bind(this);
	  this.handleInputPromisedInverterChange= this.handleInputPromisedInverterChange.bind(this);
	  this.handleResponsibleChange			= this.handleResponsibleChange.bind(this);
	  this.handleInputEmailChange			= this.handleInputEmailChange.bind(this);
	  this.handleTeamChange					= this.handleTeamChange.bind(this);
	  this.handleInputAddressChange			= this.handleInputAddressChange.bind(this);
	  this.handleInputLatLonChange			= this.handleInputLatLonChange.bind(this);
	  this.handleInputNetMeteringDateChange	= this.handleInputNetMeteringDateChange.bind(this);
		this.handleInputNetMeteringStageChange = this.handleInputNetMeteringStageChange.bind(this);
		this.handlePanelVendorChange = this.handlePanelVendorChange.bind(this);
	  this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleGenerateOtpClick = this.handleGenerateOtpClick.bind(this);
		this.handleVerifyOtpClick = this.handleVerifyOtpClick.bind(this);
		this.handleInputOtpChange = this.handleInputOtpChange.bind(this);
		this.handleSubmitOtpClick = this.handleSubmitOtpClick.bind(this);

	  this.vendor1 = ["NA","Boond","Chemitech","SmartRoof","Aryav","Zivah","SDSL","Oakridge","ZunRoof","SunEnergy","Volks Energie","Sirius","Greenon","GeoPower","GM Trading","Alien","Adhunik"];
	  this.vendor2 = vendorDataName;
	  this.cities = ["Noida","Mathura","Patiala","Amritsar","Faridabad","Delhi","Gurgaon","Greater Noida","Ghaziabad","Haridwar","Faizabad","Agra","Haldwani","Chandigarh","Mohali","Sonipat","Kharar","Meerut","Dera Bassi","Jaipur","Lucknow","Kanpur","Ludhiana","Zirakpur","Jalandhar"];
		this.editDetails = {}
	}

	componentDidMount() {
		let panelVendorSelected = {};
		let editOption = false;
		console.log("panelVendorOtpStatus",this.props.system.panelOtpVerifiedStatus);
		console.log("PANELDATE",this.props.system.paneldate);
		if(this.props.system.panelVendor && this.props.system.panelVendor!=""){
		let panelVendorSelectedId = this.props.system.panelVendor;
		let panelVendorName = this.props.panelVendorMap[panelVendorSelectedId];


		panelVendorSelected = {
			id : panelVendorSelectedId,
			name : panelVendorName
		};
		editOption = false;
	}
	else{
		panelVendorSelected = {
			id : "",
			name : ""
		};
		editOption = true;
	}
		console.log("quotePanelVendorMap",this.props.panelVendorMap);
		let panelVendorOption = [];
		Object.keys(this.props.panelVendorMap).forEach(key => {
			let obj = {
				id : key,
				name : this.props.panelVendorMap[key]
			};
			panelVendorOption.push(obj);
		});
		let structureVendors = this.props.structureVendors;
		let structureVendorSelected = this.props.system.structureVendor;
		this.setState({
			panelVendorOptions : panelVendorOption,
			panelVendorSelected : panelVendorSelected,
			panelVendorEditOption : editOption,
			panelOtpVerifiedStatus : this.props.system.panelOtpVerifiedStatus,
			structureVendors,
			structureVendorSelected
		})

		let issueList = []
		this.props.ebissues.forEach((ebissue, index)=>{
			issueList.push({label:ebissue,value:index})
		})

		this.setState({
			ebIssueList:issueList,
			ebissue:this.props.system.ebissue
		})

		this.props.system.ebissue.forEach((issue,index)=>{
			this.setState({
				ebissueselected: [...this.state.ebissueselected,{value:issue,label:index}],
			})
		});

		let startingYear = 2017, currentYear = moment().year();
		let tenderYearOptions = [];
		while(startingYear < currentYear) {
			tenderYearOptions.push(`${startingYear}-${startingYear+1}`);
			startingYear++;
		}

		let vendorPayments = this.props.vendorPayments || [];
		let vendorMap = this.props.vendorMap || {};
		vendorPayments = vendorPayments.map(row => {
			row.vendorName = (vendorMap[row.vendor] || {}).name || "";
			return row;
		})

		this.setState({
			selectedOption: this.props.system.ebissueindex,
			name:this.props.data.name,
			inc:this.props.system.inc,
			city:this.props.data.city,
			pincode:this.props.data.pincode,
			vendor:this.props.data.vendor,
			dateNADChange:this.props.system.nextActionDate?dateChanger(this.props.system.nextActionDate):null,
			dateNAD:"",
			dateRD:"",
			dateSD:"",
			dateRDChange:this.props.system.refdate?dateChanger(this.props.system.refdate):null,
			dateSDChange:this.props.system.salesdate?dateChanger(this.props.system.salesdate):null,
			user :this.props.user,
			loanissue:this.props.system.loanissue?this.props.system.loanissue:"",
			email:this.props.data.email,
			panelVendorChange:false,
			vendorChange:false,
			incChange:false,
			cityChange:false,
			actionDateChange:false,
			ebIssueChange:false,
			loanIssueChange:false,
			stageChange:false,
			refDateChange:false,
			salesDateChange:false,
			elevationDetailsChange:false,
			deliveredPanelChange:false,
			deliveredInverterChange:false,
			subsidy_DChange:false,
			state_DChange:false,
			subsidyStatusChange:false,
			subsidyStatusDateChange:false,
			sanctioned_Load_DChange:false,
			phase_DChange:false,
			deliveredInverterDateChange:false,
			deliveredPanelDateChange:false,
			addressChange: false,
			latlonChange: false,
			deliveredStructureDateChange:false,
			promisedPanelChange:false,
			promisedInverterChange:false,
			emailChange:false,
			responsibleChange:false,
			teamIdChange:false,
			netMeteringDateChange:false,
			connectionHolderNameChange:false,
			pincodeChange:false,
			responsible:this.props.data.responsibleDeliveryNumber,
			netMetringDate:this.props.system.netMetringDate,
			netMeteringStage:this.props.system.netMeteringStage,
			responsibleName:this.props.data.responsibleDelivery,
			teamId: this.props.system.teamId,
			dateInverterChange:this.props.system.inverterdate,
			maintenanceRequired:this.props.system.maintenanceRequired,
			datePanelChange:this.props.system.paneldate,
			dateStructureChange:this.props.system.structuredate,
			stage:this.props.system.stage,
			size:this.props.system.size,
			specialRequirement:this.props.system.specialRequirement,
			discom:this.props.system.discom,
			elevationdetails:this.props.system.elevationdetails,
			deliveredpanel:this.props.system.deliveredpanel,
			deliveredinverter:this.props.system.deliveredinverter,
			phase_D:this.props.system.phase_D,
			sanctioned_Load_D:this.props.system.sanctioned_Load_D,
			state_D:this.props.system.state_D?this.props.system.state_D:this.props.data.state,
			subsidy_D:this.props.system.subsidy_D,
			subsidyStatus:this.props.system.subsidyStatus || "Not Applied",
			subsidyStatusCheck:this.props.system.subsidyStatus || "Not Applied",
			netMeteringStage: this.props.system.netMeteringStage || "Not Applied",
			netMeteringStagesCheck: this.props.system.netMeteringStage || "Not Applied",
			subsidyAppliedDate:this.props.system.subsidyAppliedDate,
			promisedPanel:this.props.system.promisedPanel?this.props.system.promisedPanel:this.props.system.panel_promised,
			promisedInverter:this.props.system.promisedInverter?this.props.system.promisedInverter:this.props.system.inv_promised,
			address_D:this.props.data.address_D?this.props.data.address_D:this.props.data.address,
			latlon_D:this.props.data.latlon_D?this.props.data.latlon_D:this.props.data.latlon,
			connectionHolderName:this.props.data.connectionHolderName?this.props.data.connectionHolderName:'',
			invoiceText:this.props.system.invoiceText?this.props.system.invoiceText:"",
			idMovedBy_D:this.props.system.idMovedBy_D?this.props.system.idMovedBy_D:"",
			invoiceDate:this.props.system.invoiceDate?this.props.system.invoiceDate:"",
			paymentTerms:this.props.system.paymentTerms?this.props.system.paymentTerms:"",
			GST_B:this.props.system.GST_B?this.props.system.GST_B:"",
			BillingAddress_B:this.props.system.BillingAddress_B?this.props.system.BillingAddress_B:"",
			City_B:this.props.system.City_B?this.props.system.City_B:"",
			State_B:this.props.system.State_B?this.props.system.State_B:"",
			connectionHolderName_B:this.props.system.connectionHolderName_B?this.props.system.connectionHolderName_B:"",
			mandatoryAttachment : false,
			vendorList : this.props.vendorList,
			certificateData:this.props.system.certificateData,
			amcPeriod:this.props.system.amcPeriod,
			warrantyYears90:this.props.system.warrantyYears90,
			warrantyYears80:this.props.system.warrantyYears80,
			mppVerificationStatus: this.props.system.mppVerificationStatus,
			acdbDcdb: this.props.system.acdbDcdb,
			qualityVisit: this.props.system.qualityVisit,
			feasibility: this.props.system.feasibility,
			ppa: this.props.system.ppa,
			isPrime : this.props.system.isPrime,
			tenderYearOptions,
			tenderYear: this.props.system.tenderYear,
			size_del: this.props.system.size_del,
			customerVideoUrl: this.props.system.customerVideoUrl,
			ebIssueResolutionDate: this.props.system.ebIssueResolutionDate,
			ebIssueAppliedDate: this.props.system.ebIssueAppliedDate,
			nextAction: this.props.system.nextAction,
			ebResolutionDateChanged: false,
			loanResolutionDate: this.props.system.loanResolutionDate,
			loanResolutionDateChanged: false,
			subsidyApprovalDate: this.props.system.subsidyApprovalDate,
			subsidyApprovalDateChanged: false,
			netMeteringAppliedDate: this.props.system.netMeteringAppliedDate,
			netMeteringAppliedDateChanged: false,
			ebAppliedDateChanged: false,
			inverterCourierPartner: this.props.system.inverterCourierPartner,
			inverterCourierPartnerChanged: false,
			trackingId: this.props.system.trackingId,
			trackingIdChanged: false,
			vendorPayments: vendorPayments,
			v2HandoverDate:this.props.system.v2HandoverDate,
			loanProvider: this.props.system.loanProvider,
			inverterPhase: this.props.system.inverterPhase,
			commentsData: this.props.commentsData
		})
		this.prevState = this.state
		console.log(this.props.system.discom,"Pranav 1", this.props.system.nextAction)
	}

	componentWillReceiveProps(props){
		console.log('componentWillReceivePropsCalled');
		console.log(this.state.discom,"Pranav 1")
		console.log(props);
		let { generateVerifyOtp } = props;
		console.log(generateVerifyOtp);
		if(generateVerifyOtp){
			console.log(generateVerifyOtp);
			if(generateVerifyOtp.generate){
				if(generateVerifyOtp.generate == 1){
					alert('Otp Sent.')
				}
				else{
					alert('Otp Not Sent.Please try again.')
				}
			}
			if(generateVerifyOtp.verify){
				if(generateVerifyOtp.verify == 1){
					alert('Otp Verified.');
					this.props.sendFeedbackMsg(this.props.data.quoteid, this.props.data.phone, 'panel', this.props.data.name);
					this.setState({
						datePanelChange : generateVerifyOtp.epoch,
						panelOtpVerifiedStatus : 1,
						verifyOptionClicked : false
					});
				}
				else{
					alert('Otp Not Verified.Please try again.')
				}
			}
		}
	}

	handleGenerateOtpClick() {
		console.log("handle generate function");
		console.log(this.props.user.phoneNumber);
		console.log(this.props.data.phone);
		this.props.generateOtp(this.props.data.phone, this.props.user.phoneNumber, this.props.siteid);
	}

	handleVerifyOtpClick(){
		console.log("verify otp clicked");
		this.setState({
			verifyOptionClicked : !this.state.verifyOptionClicked
		})
	}

	handleInputOtpChange(e){
		if(e.target.value){
			console.log(e.target.value);
			this.setState({
				otpPanelVendor:e.target.value
			})
		}
	}

	handleSubmitOtpClick(){
		console.log("handle submit otp function");
		console.log("Otp to submit",this.state.otpPanelVendor);
		console.log("vendor Id", this.state.panelVendorSelected.id);
		console.log("SiteId",this.props.siteid);
		this.props.verifyOtp(this.props.data.phone, this.props.user.phoneNumber, this.state.otpPanelVendor, this.state.panelVendorSelected.id, this.props.siteid);
	}

	handleInputChange(e) {
		this.setState({
			name:e.target.value
		})
	}

	handleSelectVendor1Change(e) {
		console.log(e.target.value)

		if(e.target.value){
			if(this.editDetails.v1){
				this.editDetails.v1.new = e.target.value;
			}
			else{
			this.editDetails.v1 = {}
			this.editDetails.v1.prev = this.state.vendor;
			this.editDetails.v1.new = e.target.value;
			}
			console.log(this.editDetails)
			this.setState({
				vendor:e.target.value,
				vendorChange:true
			})
		}
	}

	handlePanelVendorChange(e){
		console.log("Panel Vendor change",e.target.value);
		//console.log("Panel Vendor change",e.target);

		if(e.target.value){
			let selectedObj = {
				id : e.target.value,
				name : this.props.panelVendorMap[e.target.value]
			};
			console.log("selectedObj",selectedObj)
			this.setState({
				panelVendorSelected:selectedObj,
				panelVendorChange:true
			})
		}
	}

	handleSelectVendor2Change(e) {
		console.log(e.target.value)

		if(e.target.value){
			if(this.editDetails.inc){
				this.editDetails.inc.new = e.target.value;
			}
			else{
			this.editDetails.inc = {}
			this.editDetails.inc.prev = this.state.inc;
			this.editDetails.inc.new = e.target.value;
			}
			this.setState({
				inc:e.target.value,
				incChange:true
			})
		}
	}

	handleSelectStructureVendorChange = (e) => {
		console.log("STRUCTURE VENDOR CHANGE",e.target.value);
		if(e.target && e.target.value){
			this.setState({
				structureVendorChanged:true,
				structureVendorSelected:e.target.value
			})
		}
	}

	handleSelectCityChange(e) {
		if(this.editDetails.city){
			this.editDetails.city.new = e.target.value;
		}
		else{
		this.editDetails.city = {}
		this.editDetails.city.prev = this.state.city;
		this.editDetails.city.new = e.target.value;
		}
		this.setState({
			city:e.target.value,
			cityChange:true
		})
	}

	handleResponsibleChange(e) {
		if(this.editDetails.Responsible){
			this.editDetails.Responsible.new = this.props.employeeMap[""+e.target.value];
		}
		else{
		this.editDetails.Responsible = {}
		this.editDetails.Responsible.prev = this.state.responsibleName;
		this.editDetails.Responsible.new = this.props.employeeMap[""+e.target.value];
		}
		this.setState({
			responsibleName:this.props.employeeMap[""+e.target.value],
			responsible:e.target.value,
			responsibleChange:true
		})
	}

	handleTeamChange(e) {

		if(e.target.value) {
			if(this.editDetails.team){
				this.editDetails.team.new = e.target.value;
			}
			else{
			this.editDetails.team = {}
			this.editDetails.team.prev = this.state.teamId;
			this.editDetails.team.new = e.target.value;
			}
			this.setState({
				teamId:e.target.value,
				teamIdChange:true
			})
		}
	}

	handleInputSubsidyDChange(e) {
		if(this.props.subsidyD[""+e.target.value]) {
			if(this.editDetails.subsidy){
				this.editDetails.subsidy.new = this.props.subsidyD[""+e.target.value];
			}
			else{
			this.editDetails.subsidy = {}
			this.editDetails.subsidy.prev = this.state.subsidy_D;
			this.editDetails.subsidy.new = this.props.subsidyD[""+e.target.value];
			}
			this.setState({
				subsidy_D:this.props.subsidyD[""+e.target.value],
				subsidy_DChange:true
			})
		}
	}

	handleInputStateDChange(e) {
		if(this.props.stateD[""+e.target.value]){
			if(this.editDetails.stateD){
				this.editDetails.stateD.new = this.props.stateD[""+e.target.value];
			}
			else{
			this.editDetails.stateD = {}
			this.editDetails.stateD.prev = this.state.state_D;
			this.editDetails.stateD.new = this.props.stateD[""+e.target.value];
			}
			this.setState({
				state_D:this.props.stateD[""+e.target.value],
				state_DChange:true
			})
		}
	}

	handleInputConnectionHolderNameChange =(e) => {
		if(this.editDetails.ConnectionHolderName){
			this.editDetails.ConnectionHolderName.new = e.target.value;
		}
		else{
		this.editDetails.ConnectionHolderName = {}
		this.editDetails.ConnectionHolderName.prev = this.state.connectionHolderName;
		this.editDetails.ConnectionHolderName.new = e.target.value;
		}
		this.setState({
			connectionHolderName:e.target.value,
			connectionHolderNameChange:true
		})
	}

	handleInputSanctionedLoadChange(e) {
		if(e.target.value>-1){
		if(this.editDetails.InputSanctionedLoad){
			this.editDetails.InputSanctionedLoad.new = e.target.value;
		}
		else{
		this.editDetails.InputSanctionedLoad = {}
		this.editDetails.InputSanctionedLoad.prev = this.state.sanctioned_Load_D;
		this.editDetails.InputSanctionedLoad.new = e.target.value;
		}
		this.setState({
			sanctioned_Load_D:e.target.value,
			sanctioned_Load_DChange:true
		})}
	}

	handleInputPhaseChange(e) {
		console.log(e.target.value)
		if(e.target.value){
			if(this.editDetails.Phase){
				this.editDetails.Phase.new = e.target.value;
			}
			else{
			this.editDetails.Phase = {}
			this.editDetails.Phase.prev = this.state.phase_D;
			this.editDetails.Phase.new = e.target.value;
			}
			this.setState({
				phase_D:e.target.value,
				phase_DChange:true
			})
		}
	}

	handleInputDateChange(e) {
		if(this.editDetails.InputDate){
			this.editDetails.InputDate.new = e.target.value;
		}
		else{
		this.editDetails.InputDate = {}
		this.editDetails.InputDate.prev = this.state.dateNADChange;
		this.editDetails.InputDate.new = e.target.value;
		}
		this.setState({
			dateNADChange:new Date(""+e.target.value).getTime(),
			dateNAD:e.target.value,
			actionDateChange:true
		})
	}

	handleInputNetMeteringDateChange(e) {
		console.log("Net Meter date chnage",e.target.value);
		let netMeterDate = '';
		if(e.target.value && e.target.value!=null && e.target.value!=''){
			netMeterDate = new Date(""+e.target.value).getTime();
		}
		if(this.editDetails.NetMeterDate){
			this.editDetails.NetMeterDate.new = new Date(""+e.target.value).getTime();
		}
		else{
		this.editDetails.NetMeterDate = {}
		this.editDetails.NetMeterDate.prev = this.state.netMetringDate;
		this.editDetails.NetMeterDate.new = new Date(""+e.target.value).getTime();
		}
		console.log("New DAte net meter", netMeterDate);
		this.setState({
			netMetringDate:netMeterDate,
			netMeteringDateChange:true
		})
	}

	handleInputNetMeteringStageChange(e) {
		console.log(e.target.value);
		let val = e.target.value;
		let mandatoryAttachment = false;
		let fileTypeName = "";
		if (val == "Stage 1 Approved" || val == "Stage 2 Approved" || val == "Net Meter Installed") {
			mandatoryAttachment = true;
		}
		switch (val) {
			case "Stage 1 Approved": fileTypeName = "type1";break;
			case "Stage 2 Approved": fileTypeName = "type2";break;
			case "Net Meter Installed": fileTypeName = "type3";break;
		}
		if(this.editDetails.NetMeteringStage){
			this.editDetails.NetMeteringStage.new = e.target.value;
		}
		else{
			this.editDetails.NetMeteringStage = {};
			this.editDetails.NetMeteringStage.prev = this.state.netMeteringStage;
			this.editDetails.NetMeteringStage.new = e.target.value;
		}
		this.setState({
			netMeteringStage:e.target.value,
			netMeteringStageChange:true,
			mandatoryAttachment : mandatoryAttachment,
			fileTypeName : fileTypeName
		})
	}

	handleOnUpload = (e) => {
		let file = e.target.files[0];
		console.log(file);
		const formData = new FormData();
		formData.append('data',file);
		console.log("FORM DATA : ",formData);
		var blob = new Blob([file], { type: file.type, name:new Date().getTime() });
		if (file.size > 20000000) {
			alert("File Size can not exceed 25 MB");
		}
		else {
			this.setState({
				blob,
				file,
				folderType : e.target.dataset.foldertype
			});
		}
	}

	handleUploadClick = () => {
		let userId 		= this.props.user.phoneNumber;
		let quoteId 	= this.props.data.quoteid;
		let name 		= this.state.file?this.state.file.name:"";
		//let indexFound  = {...{},...this.state.typeVersionMap};
		let folderType 	= this.state.folderType;
		let type 	    = this.state.fileTypeName;
		let file    	= this.state.blob;
		// indexFound[""+type] = (indexFound[""+type]||indexFound[""+type]==0?indexFound[""+type]:-1) + 1;
		// console.log(this.state.typeVersionMap,indexFound);
		let uploadObj = { userId, quoteId, name, version:0, type, folderType, file };
		console.log(uploadObj);
		if(file && folderType && type){
			this.props.fileUpload(uploadObj);
			// this.setState({
			// 	typeVersionMap:indexFound
			// })
		}
	}

	handleOnDownloadClick = (requestId) => {
		console.log(this.props);
		console.log(this.state);
		let userId 	= this.props.user.phoneNumber;
		let quoteId = this.props.data.quoteid;
		let fileName= this.props.fmsData[""+requestId].name;
		let downloadObj = {userId, quoteId, requestId, fileName };
		console.log(downloadObj);
		this.props.fileDownload(downloadObj);
	}

	handleInputEBIssueChange(selectedOption) {
		let issueList=[];
		selectedOption.forEach(val=>{
			issueList.push(val.label)
		})
		if(this.editDetails.EBIssue){
			this.editDetails.EBIssue.new = issueList;
		}
		else{
		this.editDetails.EBIssue = {}
		this.editDetails.EBIssue.prev = this.state.ebissue;
		this.editDetails.EBIssue.new = issueList;
		}
		this.setState({
			selectedOption,
			ebissue:issueList,
			ebIssueChange:true
		});
	}

	// SUBSIDY STATUS CHANGE
	handleSubsidyStatusInputChange(e) {
		let val = e.target.value;
		let mandatoryAttachment = false;
		let fileTypeName = "";
		if (val == "Sanction Letter" || val == "JCR") {
			mandatoryAttachment = true;
		}
		switch (val) {
			case "Sanction Letter": fileTypeName = "type1";break;
			case "JCR": fileTypeName = "type2";break;
		}
		if(this.editDetails.subsidyStatus){
			this.editDetails.subsidyStatus.new = e.target.value;
		}
		else{
		this.editDetails.subsidyStatus = {}
		this.editDetails.subsidyStatus.prev = this.state.subsidyStatus;
		this.editDetails.subsidyStatus.new = e.target.value;
		}
		this.setState({
			subsidyStatus:e.target.value,
			subsidyStatusChange:true,
			mandatoryAttachment : mandatoryAttachment,
			fileTypeName : fileTypeName
		})
	}

	handleSubsidyStatusDateInputChange(e) {
		if(this.editDetails.subsidyStatusDate){
			this.editDetails.subsidyStatusDate.new = new Date(""+e.target.value).getTime();
		}
		else{
		this.editDetails.subsidyStatusDate = {}
		this.editDetails.subsidyStatusDate.prev = this.state.subsidyAppliedDate;
		this.editDetails.subsidyStatusDate.new = new Date(""+e.target.value).getTime();
		}
		this.setState({
			subsidyAppliedDate:new Date(""+e.target.value).getTime(),
			subsidyStatusDateChange:true
		})
	}

	// PORMISED INVERTeR AND PANEL
	handleInputPromisedPanelChange(e) {
		if(this.editDetails.PromisedPanel){
			this.editDetails.PromisedPanel.new = e.target.value;
		}
		else{
		this.editDetails.PromisedPanel = {}
		this.editDetails.PromisedPanel.prev = this.state.promisedPanel;
		this.editDetails.PromisedPanel.new = e.target.value;
		}
		this.setState({
			promisedPanel:e.target.value,
			promisedPanelChange:true
		})
	}
	handleInputPromisedInverterChange(e) {
		if(this.editDetails.PromisedInverter){
			this.editDetails.PromisedInverter.new = e.target.value;
		}
		else{
		this.editDetails.PromisedInverter = {}
		this.editDetails.PromisedInverter.prev = this.state.promisedInverter;
		this.editDetails.PromisedInverter.new = e.target.value;
		}
		this.setState({
			promisedInverter:e.target.value,
			promisedInverterChange:true
		})
	}

	// EB RELATED

	handleInputLoanIssueChange(e) {
		if(this.editDetails.LoanIssue){
			this.editDetails.LoanIssue.new = e.target.value;
		}
		else{
		this.editDetails.LoanIssue = {}
		this.editDetails.LoanIssue.prev = this.state.loanissue;
		this.editDetails.LoanIssue.new = e.target.value;
		}
		this.setState({
			loanissue:e.target.value,
			loanIssueChange:true
		})
	}

	handleInputStageChange(e) {
		if(this.props.stages[e.target.value]){
			if(this.editDetails.Stage){
				this.editDetails.Stage.new = this.props.stages[e.target.value];
			}
			else{
			this.editDetails.Stage = {}
			this.editDetails.Stage.prev = this.state.stage;
			this.editDetails.Stage.new = this.props.stages[e.target.value];
			}
			this.setState({
				stage:this.props.stages[e.target.value],
				stageChange:true
			})
		}
	}

	handleInputRefDateChange(e) {
		if(this.editDetails.RefDate){
			this.editDetails.RefDate.new = e.target.value;
		}
		else{
		this.editDetails.RefDate = {}
		this.editDetails.RefDate.prev = this.state.dateRD;
		this.editDetails.RefDate.new = e.target.value;
		}
		this.setState({
			dateRDChange:new Date(""+e.target.value).getTime(),
			dateRD:e.target.value,
			refDateChange:true
		})
	}

	handleInputSalesDateChange(e) {
		if(this.editDetails.SalesDate){
			this.editDetails.SalesDate.new = e.target.value;
		}
		else{
		this.editDetails.SalesDate = {}
		this.editDetails.SalesDate.prev = this.state.dateSD;
		this.editDetails.SalesDate.new = e.target.value;
		}
		this.setState({
			dateSDChange:new Date(""+e.target.value).getTime(),
			dateSD:e.target.value,
			salesDateChange:true
		})
	}
	handleInputElevationDetailsChange(e) {
		if(this.editDetails.ElevationDetails){
			this.editDetails.ElevationDetails.new = e.target.value;
		}
		else{
		this.editDetails.ElevationDetails = {}
		this.editDetails.ElevationDetails.prev = this.state.elevationdetails;
		this.editDetails.ElevationDetails.new = e.target.value;
		}
		this.setState({
			elevationdetails:e.target.value,
			elevationDetailsChange:true
		})
	}

	handleInputDeliveredPanelChange(e) {
		if(this.editDetails.DeliveredPanel){
			this.editDetails.DeliveredPanel.new = e.target.value;
		}
		else{
		this.editDetails.DeliveredPanel = {}
		this.editDetails.DeliveredPanel.prev = this.state.deliveredpanel;
		this.editDetails.DeliveredPanel.new = e.target.value;
		}
		this.setState({
			deliveredpanel:e.target.value,
			deliveredPanelChange:true
		})
	}

	handleInputDeliveredInverterChange(e) {
		if(this.editDetails.DeliveredInverter){
			this.editDetails.DeliveredInverter.new = e.target.value;
		}
		else{
		this.editDetails.DeliveredInverter = {}
		this.editDetails.DeliveredInverter.prev = this.state.deliveredinverter;
		this.editDetails.DeliveredInverter.new = e.target.value;
		}
		this.setState({
			deliveredinverter:e.target.value,
			deliveredInverterChange:true
		})
	}

	handleEdit() {
		console.log(this.state.edit)
		console.log('Clicked')
		this.editDetails = {}
		if(this.state.edit){
			this.setState({
				edit:false
			})
		}else{
			this.setState({
				edit:true
			})
		}
	}

	handleClick() {
		if(this.state.edit){
			this.setState({
				edit:false
			})
		}
	}

	handleInputInverterDateChange(e) {
		if(this.editDetails.InverterDate){
			this.editDetails.InverterDate.new = new Date(""+e.target.value).getTime();
		}
		else{
		this.editDetails.InverterDate = {}
		this.editDetails.InverterDate.prev = this.state.dateInverterChange;
		this.editDetails.InverterDate.new = new Date(""+e.target.value).getTime();
		}
		this.setState({
			dateInverterChange:new Date(""+e.target.value).getTime(),
			deliveredInverterDateChange:true
		})
	}

	handleInputPanelDateChange(e) {
		if(this.editDetails.PanelDate){
			this.editDetails.PanelDate.new = new Date(""+e.target.value).getTime();
		}
		else{
		this.editDetails.PanelDate = {}
		this.editDetails.PanelDate.prev = this.state.datePanelChange;
		this.editDetails.PanelDate.new = new Date(""+e.target.value).getTime();
		}
		this.setState({
			datePanelChange:new Date(""+e.target.value).getTime(),
			deliveredPanelDateChange:true
		})
	}

	handleInputStructureDateChange(e) {
		if(this.editDetails.StructureDate){
			this.editDetails.StructureDate.new = new Date(""+e.target.value).getTime();
		}
		else{
		this.editDetails.StructureDate = {}
		this.editDetails.StructureDate.prev = this.state.dateStructureChange;
		this.editDetails.StructureDate.new = new Date(""+e.target.value).getTime();
		}
		this.setState({
			dateStructureChange:new Date(""+e.target.value).getTime(),
			deliveredStructureDateChange:true
		})
	}

	handleInputV2HandoverDateChange = (e) => {
		this.setState({
			v2HandoverDateChanged: true,
			v2HandoverDate: new Date(""+e.target.value).getTime()
		})
	}

	handleInputLoanProviderChange = (e) => {
		this.setState({
			loanProviderChanged: true,
			loanProvider: e.target.value
		})
	}

	handleInputInverterPhaseChange = (e) => {
		this.setState({
			inverterPhaseChanged: true,
			inverterPhase: e.target.value
		})
	}

	// Address And Email Change
	handleInputAddressChange (e) {
		if(this.editDetails.Address){
			this.editDetails.Address.new = e.target.value;
		}
		else{
		this.editDetails.Address = {}
		this.editDetails.Address.prev = this.state.address_D;
		this.editDetails.Address.new = e.target.value;
		}
		this.setState({
			address_D:e.target.value,
			addressChange: true
		})
	}

	handleInputLatLonChange (e) {
		if(this.editDetails.LatLon){
			this.editDetails.LatLon.new = e.target.value;
		}
		else{
		this.editDetails.LatLon = {}
		this.editDetails.LatLon.prev = this.state.latlon_D;
		this.editDetails.LatLon.new = e.target.value;
		}
		this.setState({
			latlon_D:e.target.value,
			latlonChange:true
		})
	}


	// USER EMAIL CHANGE
	handleInputEmailChange(e) {
		if(this.editDetails.Email){
			this.editDetails.Email.new = e.target.value;
		}
		else{
		this.editDetails.Email = {}
		this.editDetails.Email.prev = this.state.email;
		this.editDetails.Email.new = e.target.value;
		}
		this.setState({
			email:e.target.value,
			emailChange:true
		})
	}

	// INVOICE INPUT HANDLE
	handleSingleInputChange = (e) => {
		let value = e.target.value;
		if(e.target.name.includes("date")||e.target.name.includes("Date")) {
			value = new Date(e.target.value).getTime();
		}
		console.log(e.target.name,value)
		let changeName = `${e.target.name}Change`
		this.setState({
			[e.target.name] : value,
			[changeName] : true
		})
	}

	handleSingleInput_DChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleSaveClick() {
		let { GST_B, BillingAddress_B, City_B, State_B, connectionHolderName_B } = this.state;
		//let updatedValues = []
		if(this.state.panelVendorChange){
			console.log("panel vendor changed",this.state.panelVendorSelected.id);
			if(this.state.panelVendorSelected.id!=0){
			console.log("panel vendor changed",this.state.panelVendorSelected.id);
			this.props.setPanelVendorData(this.props.siteid,this.state.panelVendorSelected.id, this.props.data.quoteid);
			this.setState({
				panelVendorEditOption : false
			});
			}
		}
		if(this.state.vendorChange){
			this.props.setvendordata(this.props.siteid,this.state.vendor)
			console.log("Vendor Changed")
		}
		if(this.state.incChange){
			this.props.setincdata(this.props.siteid,this.state.inc, this.props.user.phoneNumber, this.props.system.inc)
		}
		if(this.state.cityChange){
			this.props.setcitydata(this.props.data.phone,this.state.city)
			// const updatedData = {
			// 	'field' : 'city',
			// 	'previous' : this.props.data.city,
			// 	'changed' : this.state.city
			// }
			// updatedValues.push(updatedData)
			// this.props.data.city = this.state.city
		}
		if(this.state.pincodeChange){
			this.props.setPincode(this.props.data.phone,this.state.pincode)
		}
		if(this.state.ebIssueChange){
			let arrayIssue = [];
			this.state.ebissue.forEach(val=>{
				arrayIssue.push(""+this.props.ebissues.indexOf(val));
			})
			this.props.setebissue(this.props.siteid,this.props.user.phoneNumber,arrayIssue,this.state.ebissue,this.props.system.ebissue)
			// const updatedData = {
			// 	'field' : 'ebIssue',
			// 	'previous' : this.props.data.ebissues,
			// 	'changed' : this.state.ebissues
			// }
			// updatedValues.push(updatedData)
			// this.props.data.ebissues = this.state.ebissues
		}
		if(this.state.loanIssueChange){
			this.props.setloanissue(this.props.siteid,this.state.loanissue)
			// const updatedData = {
			// 	'field' : 'loanissue',
			// 	'previous' : this.props.system.loanissue,
			// 	'changed' : this.state.loanissue
			// }
			// updatedValues.push(updatedData)
			// this.props.system.loanissue = this.state.loanissue
		}
		if(this.state.stageChange || this.state.actionDateChange || this.state.salesDateChange || this.state.refDateChange){
			this.props.setnextactiondate(this.props.siteid,this.state.dateNADChange,this.state.user.phoneNumber,this.state.dateSDChange,this.state.dateRDChange,this.props.stages.indexOf(this.state.stage),this.props.stages, this.props.data.quoteid,this.props.system.commissioningDate,this.state.email);
			// const updatedData = {
			// 	'field' : 'nextactiondate',
			// 	'previous' : this.props.data.loanissue,
			// 	'changed' : this.state.loanissue
			// }
			// updatedValues.push(updatedData)
			// this.props.data.loanissue = this.state.loanissue
			// if(this.state.stageChange){
			// 	const updatedData = {
			// 		'field' : 'Stage',
			// 		'previous' : this.props.system.stage,
			// 		'changed' : this.state.stage
			// 	}
			// 	updatedValues.push(updatedData)
			// 	this.props.system.stage = this.state.stage
			// }
			// if(this.state.actionDateChange){
			// 	const updatedData = {
			// 		'field' : 'actionDate',
			// 		'previous' : this.props.data.actionDate,
			// 		'changed' : this.state.dateNAD
			// 	}
			// 	updatedValues.push(updatedData)
			// 	this.props.data.actionDate = this.state.actionDate
			// }
			// if(this.state.salesDateChange){
			// 	const updatedData = {
			// 		'field' : 'salesDateChange',
			// 		'previous' : this.props.data.dateSD,
			// 		'changed' : this.state.dateSD
			// 	}
			// 	updatedValues.push(updatedData)
			// 	this.props.data.dateSD = this.state.dateSD
			// }
			// if(this.state.refDateChange){
			// 	const updatedData = {
			// 		'field' : 'refDateChange',
			// 		'previous' : this.props.data.dateRD,
			// 		'changed' : this.state.dateRD
			// 	}
			// 	updatedValues.push(updatedData)
			// 	this.props.data.dateRD = this.state.dateRD
			//}
		}
		if(this.state.elevationDetailsChange || this.state.deliveredPanelChange ||  this.state.deliveredInverterChange|| this.state.subsidy_DChange|| this.state.state_DChange|| this.state.sanctioned_Load_DChange|| this.state.phase_DChange || this.state.subsidyStatusChange || this.state.netMeteringStageChange || this.state.subsidyStatusDateChange || this.state.promisedInverterChange || this.state.promisedPanelChange || this.state.addressChange || this.state.latlonChange || this.state.connectionHolderNameChange || this.state.invoiceTextChange || this.state.invoiceDateChange || this.state.sizeChange || this.state.discomChange || this.state.specialRequirementChange || this.state.idMovedBy_DChange || this.state.maintenanceRequiredChange || GST_B|| BillingAddress_B|| City_B|| State_B|| connectionHolderName_B || this.state.tenderYearChanged || this.state.size_delChange || this.state.ebResolutionDateChanged || this.state.loanResolutionDateChanged || this.state.subsidyApprovalDateChanged || this.state.netMeteringAppliedDateChanged || this.state.ebAppliedDateChanged || this.state.inverterCourierPartnerChanged || this.state.trackingIdChanged || this.state.v2HandoverDateChanged || this.state.loanProviderChanged || this.state.nextActionChanged || this.state.inverterPhaseChanged){

			if (this.state.mandatoryAttachment) {
				{
					let userId 		= this.props.user.phoneNumber;
					let quoteId 	= this.props.data.quoteid;
					let name 		= this.state.file?this.state.file.name:"";
					//let indexFound  = {...{},...this.state.typeVersionMap};
					let folderType 	= this.state.folderType;
					let type 	    = this.state.fileTypeName;
					let file    	= this.state.blob;
					// indexFound[""+type] = (indexFound[""+type]||indexFound[""+type]==0?indexFound[""+type]:-1) + 1;
					// console.log(this.state.typeVersionMap,indexFound);
					let uploadObj = { userId, quoteId, name, version:0, type, folderType, file };
					console.log(uploadObj);
					if(file && folderType && type){
						console.log("Uploading");
						console.log(this.props.fileUpload);
						this.props.fileUpload(uploadObj);
						// this.setState({
						// 	typeVersionMap:indexFound
						// })
					}
					else {
						alert("Attachment is Mandatory");
						return;
					}
				}

			}

			console.log(this.props.siteid,this.state.elevationdetails,this.state.deliveredpanel,this.state.deliveredinverter,this.state.phase_D,this.state.sanctioned_Load_D,this.state.state_D,this.state.subsidy_D,this.state.subsidyStatus,this.state.netMeteringStage,this.state.subsidyAppliedDate,this.state.promisedPanel,this.state.promisedInverter, this.state.address_D, this.state.latlon_D, this.state.connectionHolderName, this.props.user.phoneNumber,this.state.invoiceText,this.state.invoiceDate,this.state.size,this.state.discom,this.state.specialRequirement,this.state.idMovedBy_D,this.state.maintenanceRequired,GST_B, BillingAddress_B, City_B, State_B, connectionHolderName_B, this.state.ebIssueResolutionDate, this.state.loanResolutionDate, this.state.subsidyApprovalDate, this.state.ebIssueAppliedDate, this.state.v2HandoverDate, this.state.loanProvider);

			this.props.addorupdate(this.props.siteid,this.state.elevationdetails,this.state.deliveredpanel,this.state.deliveredinverter,this.state.phase_D,this.state.sanctioned_Load_D,this.state.state_D,this.state.subsidy_D,this.state.subsidyStatus,this.state.netMeteringStage,this.state.subsidyAppliedDate,this.state.promisedPanel,this.state.promisedInverter, this.state.address_D, this.state.latlon_D, this.state.connectionHolderName, this.props.user.phoneNumber,this.state.invoiceText,this.state.invoiceDate,this.state.size,this.state.discom,this.state.specialRequirement,this.state.idMovedBy_D,this.state.maintenanceRequired,GST_B,BillingAddress_B,City_B,State_B,connectionHolderName_B, this.props.system.subsidyStatus, this.props.system.netMeteringStage, this.state.email,this.props.data.quoteid, this.state.tenderYear, this.state.size_del, this.state.ebIssueResolutionDate, this.state.loanResolutionDate, this.state.subsidyApprovalDate, this.state.netMeteringAppliedDate, this.state.ebIssueAppliedDate, this.state.inverterCourierPartner, this.state.trackingId, this.state.v2HandoverDate, this.state.loanProvider, this.state.nextAction, this.state.inverterPhase);

		}

		if(this.state.deliveredInverterDateChange||this.state.deliveredPanelDateChange||this.state.deliveredStructureDateChange){
			this.props.setorderstatus(this.props.siteid,this.state.dateInverterChange,this.state.datePanelChange,this.state.dateStructureChange,this.state.email,this.props.data.quoteid);

			if(this.state.dateInverterChange && !this.props.system.inverterdate){
				this.props.sendFeedbackMsg(this.props.data.quoteid, this.props.data.phone, 'panel', this.props.data.name);
			}
			// if(this.state.deliveredInverterDateChange){
			// 	const updatedData = {
			// 		'field' : 'deliveredInverterDateChange',
			// 		'previous' : this.props.data.dateInverterChange,
			// 		'changed' : this.state.dateInverterChange
			// 	}
			// 	updatedValues.push(updatedData)
			// 	this.props.data.dateInverterChange = this.state.dateInverterChange
			// }
			// if(this.state.deliveredPanelDateChange){
			// 	const updatedData = {
			// 		'field' : 'deliveredPanelDateChange',
			// 		'previous' : this.props.data.datePanelChange,
			// 		'changed' : this.state.datePanelChange
			// 	}
			// 	updatedValues.push(updatedData)
			// 	this.props.data.datePanelChange = this.state.datePanelChange
			// }
			// if(this.state.deliveredStructureDateChange){
			// 	const updatedData = {
			// 		'field' : 'deliveredStructureDateChange',
			// 		'previous' : this.props.data.dateStructureChange,
			// 		'changed' : this.state.dateStructureChange
			// 	}
			// 	updatedValues.push(updatedData)
			// 	this.props.data.dateStructureChange = this.state.dateStructureChange
			// }
		}

		if(this.state.emailChange) {
			this.props.setuserinfo(this.props.siteid,this.state.user.phoneNumber,this.props.data.phone,this.props.data.email,this.state.email);
			// const updatedData = {
			// 	'field' : 'emailChange',
			// 	'previous' : this.props.data.email,
			// 	'changed' : this.state.email
			// }
			// updatedValues.push(updatedData)
			// this.props.data.email = this.state.email
		}

		if(this.state.responsibleChange) {
			this.props.setresponsible(this.props.siteid,this.state.user.phoneNumber,this.props.system.teamId,this.props.data.responsibleDeliveryNumber,this.props.data.phone,this.props.data.quoteid,this.state.teamId,this.state.responsible,this.props.data.responsibleDelivery,this.props.employeeMap[""+this.state.responsible]);
			// const updatedData = {
			// 	'field' : 'responsibleChange',
			// 	'previous' : this.props.data.responsibleName,
			// 	'changed' : this.state.responsibleName
			// }
			// updatedValues.push(updatedData)
			// this.props.data.responsibleName = this.state.responsibleName
		}

		if(this.state.netMeteringDateChange) {
			this.props.setNetMeteringDate(this.props.siteid,this.state.netMetringDate,this.state.email,this.props.data.quoteid);
			// const updatedData = {
			// 	'field' : 'netMeterDate',
			// 	'previous' : this.props.data.netMetringDate,
			// 	'changed' : this.state.netMetringDate
			// }
			// updatedValues.push(updatedData)
			// this.props.data.netMetringDate = this.state.netMetringDate
		}

		if(this.state.structureVendorChanged){
			console.log("Structure frameone", this.props.siteid, this.state.structureVendorSelected);
			this.props.updateStructureVendor(this.props.siteid, this.state.structureVendorSelected);
		}

		if(this.state.certificateDataEdited){
			console.log(this.state.certificateData);
			this.props.setCertificateData(this.props.siteid, this.state.certificateData, this.state.amcPeriod, this.state.warrantyYears80, this.state.warrantyYears90);
		}
		if(this.state.mppVerificationStatusChanged){
			this.props.updateMppVerificationStatus(this.props.siteid, this.state.mppVerificationStatus, this.props.system.mppVerificationStatus, this.props.user.phoneNumber)
		}
		if(this.state.acdbDcdbChanged){
			this.props.updateAcdbDcdbVariable(this.props.siteid, this.state.acdbDcdb);
		}
		if(this.state.customerVideoUrlChanged){
			this.props.updateCustomerVideoUrlVariable(this.props.siteid, this.state.customerVideoUrl);
		}
		if(this.state.qualityVisitChanged){
			this.props.updateQualityVisitStatus(this.props.siteid, this.state.qualityVisit);
		}
		if(this.state.feasiblityChanged){
			this.props.updateFeasiblityStatus(this.props.siteid, this.state.feasibility,this.state.email,this.props.system.feasibility,this.props.data.quoteid)
		}
		if(this.state.ppaChanged){
			this.props.updatePPAVariable(this.props.siteid, this.state.ppa);
		}
		/*if (this.state.netMeteringStageChange) {
			if (this.state.mandatoryAttachment) {
				{
					let userId 		= this.props.user.phoneNumber;
					let quoteId 	= this.props.data.quoteid;
					let name 		= this.state.file?this.state.file.name:"";
					//let indexFound  = {...{},...this.state.typeVersionMap};
					let folderType 	= this.state.folderType;
					let type 	    = this.state.fileTypeName;
					let file    	= this.state.blob;
					// indexFound[""+type] = (indexFound[""+type]||indexFound[""+type]==0?indexFound[""+type]:-1) + 1;
					// console.log(this.state.typeVersionMap,indexFound);
					let uploadObj = { userId, quoteId, name, version:0, type, folderType, file };
					console.log(uploadObj);
					if(file && folderType && type){
						console.log("Uploading");
						console.log(this.props.fileUpload);
						this.props.fileUpload(uploadObj);
						// this.setState({
						// 	typeVersionMap:indexFound
						// })
					}
					else {
						alert("Attachment is Mandatory");
						return;
					}
				}

			}
			else {

			}
		}*/
		// console.log(updatedValues)
		// updatedValues = []
		// console.log(this.props)
		// console.log(this.state)
		// console.log(this.editDetails)
		// let i = 0;
		// for(i=1;i<this.editDetails.length;i++){
		// 	if(this.editDetails[i].field==this.editDetails[i-1].field){
		// 		this.editDetails.splice(i-1,1);
		// 		i=i-1;
		// 	}
		// }
		console.log(this.editDetails)
		console.log(this.props.user.phoneNumber)
		console.log(this.props.data.quoteid)
		if(Object.keys(this.editDetails).length>0 && this.props.user.level == 1900){
		const subject = String(this.props.data.quoteid) + " edited by " + String(this.props.user.phoneNumber)
		this.props.sendEmailOnEdit(this.editDetails, subject)
		}
		this.editDetails = {}
		this.setState({
			edit:false,
			vendorChange: false,
			incChange: false,
			cityChange: false,
			subsidy_DChange: false,
			state_DChange: false,
			sanctioned_Load_DChange: false,
			phase_DChange: false,
			actionDateChange: false,
			ebIssueChange: false,
			loanIssueChange: false,
			stageChange: false,
			refDateChange: false,
			salesDateChange: false,
			elevationDetailsChange: false,
			deliveredPanelChange: false,
			deliveredInverterChange: false,
			deliveredInverterDateChange:false,
			deliveredPanelDateChange:false,
			deliveredStructureDateChange:false,
			subsidyStatusDateChange:false,
			subsidyStatusChange:false,
			promisedInverterChange:false,
			promisedPanelChange:false,
			emailChange:false,
			responsibleChange:false,
			teamIdChange:false,
			certificateDataEdited: false,
			mppVerificationStatusChanged: false,
			qualityVisitChanged: false,
			acdbDcdbChanged: false,
			ppaChanged: false,
			tenderYearChanged: false,
			size_delChange: false,
			customerVideoUrlChanged: false,
			netMeteringAppliedDateChanged: false,
			ebResolutionDateChanged: false,
			loanResolutionDateChanged: false,
			subsidyApprovalDateChanged: false,
			ebAppliedDateChanged: false,
			inverterCourierPartnerChanged: false,
			trackingIdChanged: false
		})
	}

	hoverOn(){
		if(!this.state.edit)
      		this.setState({ hover: true });
    }
    hoverOff(){
      this.setState({ hover: false });
    }

    changeView = (e) => {
    	this.setState({
    		viewContent: e.target.name
    	})
	}

	handleCertificateFormChange = (index, e) => {
		if(index>=0){
			console.log(index, e.target.name, e.target.value);
			let certificateData = [...this.state.certificateData];
			certificateData[index][e.target.name] = e.target.value;
			this.setState({
				certificateData,
				certificateDataEdited: true
			})
		}
		else{
			this.setState({
				[e.target.name]:e.target.value,
				certificateDataEdited: true
			})
		}
	}

	generateCertificate = () => {
		let { quoteid } = this.props.data;
		let { address_D, size, certificateData, amcPeriod, warrantyYears80, warrantyYears90, netMetringDate } = this.state;
		let { phoneNumber } = this.props.user;
		let { commissioningDate } = this.props.system;
		let name = this.state.connectionHolderName || this.props.data.name;
		if(!commissioningDate){
			alert("Commissioning Date is not present.");
			return;
		}
		if(!netMetringDate) {
			alert("Net metering Date is not present.");
			return;
		}
		if(name && quoteid && address_D && size && certificateData[0].item && certificateData[0].make && certificateData[0].capacity && certificateData[0].warranty && certificateData[1].item && certificateData[1].make && certificateData[1].capacity && certificateData[1].warranty && amcPeriod && warrantyYears80 && warrantyYears90){
			console.log(quoteid, name, address_D, size, certificateData, phoneNumber);
			this.props.generateCertificate(quoteid, name, address_D, size+"kW", certificateData, phoneNumber, amcPeriod, warrantyYears80, warrantyYears90, changeToViewDate(netMetringDate));
			alert("Generating Certificates.");
			console.log("generate certificate");
		}
		else{
			alert("Please fill all details!");
		}
	}

	handleMppVerificationStatusChange = (e) => {
		this.setState({
			mppVerificationStatusChanged: true,
			mppVerificationStatus: e.target.value
		})
	}

	handleAcdbDcdbChange = (e) => {
		this.setState({
			acdbDcdbChanged: true,
			acdbDcdb: e.target.value
		})
	}

	handleCustomerVideoUrlChange = (e) => {
		this.setState({
			customerVideoUrl: e.target.value,
			customerVideoUrlChanged: true
		})
	}

	handleQualityVisitChange = (e) => {
		this.setState({
			qualityVisitChanged: true,
			qualityVisit: e.target.value
		})
	}

	handleTenderYearChange = (e) => {
		this.setState({
			tenderYearChanged: true,
			tenderYear: e.target.value
		})
	}

	handleFeasibilityChange = (e) => {
		this.setState({
			feasiblityChanged: true,
			feasibility: e.target.value
		})
	}

	handlePPAChange = (e) => {
		this.setState({
			ppaChanged: true,
			ppa: e.target.value
		})
	}

	markZunprime = (e) => {
		console.log("mark zunprime clicked",e.target.name);
		let name = e.target.name;
		let siteId = this.props.siteid;
		console.log(siteId);
		this.props.changeZunprimeStatus(siteId,name);
		this.setState({
			isPrime : !this.state.isPrime
		})
	}
	
	handleInputEbResolutionDateChange = (e) => {
		this.setState({
			ebResolutionDateChanged: true,
			ebIssueResolutionDate: new Date(""+e.target.value).getTime()
		})
	}

	handleInputEbAppliedDateChange = (e) => {
		this.setState({
			ebAppliedDateChanged: true,
			ebIssueAppliedDate: new Date(""+e.target.value).getTime()
		})
	}

	handleInputLoanResolutionDateChange = (e) => {
		this.setState({
			loanResolutionDateChanged: true,
			loanResolutionDate: new Date(""+e.target.value).getTime()
		})
	}

	handleInputNetMeteringAppliedDateChange = (e) => {
		this.setState({
			netMeteringAppliedDateChanged: true,
			netMeteringAppliedDate: new Date(""+e.target.value).getTime()
		})
	}

	handleInputInverterCourierPartnerChange = (e) => {
		this.setState({
			inverterCourierPartner: e.target.value,
			inverterCourierPartnerChanged: true
		})
	}

	handleTrackingIdChange = (e) => {
		this.setState({
			trackingIdChanged: true,
			trackingId: e.target.value
		})
	}

	handleSubsidyApprovalDateInputChange = (e) => {
		this.setState({
			subsidyApprovalDate: new Date(""+e.target.value).getTime(),
			subsidyApprovalDateChanged: true
		})
	}

	handleCommentStageChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleCommentChange = (e) => {
		this.setState({
			text:e.target.value
		})
		console.log(this.state.text);
	}

	handleOnClickComment = () => {
		this.setState({
			show: !this.state.show
		})
	}

    onOpenModal = () => {
		this.setState({ open: true, commentStage1: this.props.currentStage ? this.props.currentStage.stage1 : "", commentStage2: this.props.currentStage ? this.props.currentStage.stage2 : "" });
	};
	 
	onCloseModal = () => {
		this.setState({ open: false });
	};

	handleCommentSubmit = () => {
		//Perform comment send
		var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		let date = new Date();
		let commentDate = date.toLocaleDateString("en-US", options)
		this.setState({
			open: false,
			commentsData : [...this.state.commentsData , {text:this.state.text,date:commentDate,responsible:this.state.user.phoneNumber, stage1: this.state.commentStage1, stage2: this.state.commentStage2}]
		});
		console.log("BEFORE POSTING : ",this.props.siteid,this.state.text,this.state.user.phoneNumber, this.state.commentStage1, this.state.commentStage2);
		this.props.setquotedata(this.props.siteid,this.state.text,this.state.user.phoneNumber, { stage1: this.state.commentStage1, stage2: this.state.commentStage2 });
	}

	handleInputNextActionChange = (e) => {
		this.setState({
			nextAction: e.target.value,
			nextActionChanged: true
		})
	}

	render() {
		console.log(this.state)
		console.log(this.prevState)
		console.log(this.state.name)
		console.log("panelvendormapRender",this.state.panelVendorMap)
		const style = {
			hide : {
				display:'none'
			},
			show : {
				display:''
			},
			iconShow:{
				float: 'right',
				display:''
			},
			iconHide:{
				float: 'right',
				display:'none'
			}
		}

		const { name,phone,quoteid,responsibleDelivery } = this.props.data;
		const { elevationtype,propertytype,size,type } = this.props.system;
		const { selectedOption, viewContent, GST_B, BillingAddress_B, City_B, State_B, connectionHolderName_B } = this.state;
		const { amcData } = this.props;
		console.log("sysytem ",this.props.system)
		let blockedFromIndex = 50;
		if((new Date(this.state.dateSDChange)) >= (new Date("01-01-2019"))){
			// if(!(this.state.datePanelChange && this.state.datePanelChange > 0)){
			// 	blockedFromIndex = 2
			// } else if(!(this.state.dateStructureChange && this.state.dateStructureChange > 0)){
			// 	blockedFromIndex = 3
			if(!(this.state.dateInverterChange && this.state.dateInverterChange > 0)){
				blockedFromIndex = 4
			} else if(!this.state.acdbDcdb || this.state.qualityVisit!="Completed") {
				blockedFromIndex = 5
			}
		}
		return (
			<div>
				<div className="row">
					<div className="col-12">
						<div className="page-title">
							<div style={styleSheet.titlePage}>
								<div style={styleSheet.titlePage.quote}>
									<span style={styleSheet.titlePage.quote1}>Quote ID: {quoteid}</span>
									<span style={styleSheet.titlePage.quote2}>
										Name: {name.toUpperCase()}
									</span>
									<span style={styleSheet.titlePage.quote2}>Stage 1: {this.props.currentStage ? this.props.currentStage.stage1 : ""}</span>
									<span style={styleSheet.titlePage.quote2}>
										Stage 2: {this.props.currentStage ? this.props.currentStage.stage2 : ""}
									</span>
								</div>
								<div style={styleSheet.titlePage.name}>
									<i onClick={this.handleEdit} className="fas fa-pen"></i>
								</div>
							</div>
						</div>
					</div>
					<Modal open={this.state.open} onClose={this.onCloseModal} center>
						<div style={styleSheet.commentForm} className="form-group">
							<label for="comment">Comment:</label>
							<textarea value={this.state.text} onChange={this.handleCommentChange} className="form-control" rows="5" id="comment"></textarea>
							<label for="comment">Stage 1:</label>
							<select className="custom-select" value={this.state.commentStage1} onChange={this.handleCommentStageChange} name='commentStage1'>
								<option>Select Stage 1</option>
								{
									Object.keys(stageMapping).map(stage1Option => {
										return (
											<option key={stage1Option} value={stage1Option}>{stage1Option}</option>
										)
									})
								}
							</select>
							<label for="comment">Stage 2:</label>
							<select className="custom-select" value={this.state.commentStage2} onChange={this.handleCommentStageChange} name='commentStage2'>
								<option>Select Stage 2</option>
								{
									(stageMapping[this.state.commentStage1] || []).map(stage2Option => {
										return (
											<option key={stage2Option} value={stage2Option}>{stage2Option}</option>
										)
									})
								}
							</select>
							<div style={styleSheet.commentFormButton}><button style={styleSheet.commentFormButton} className="btn btn-success" onClick={this.handleCommentSubmit}>Submit</button></div>
						</div>
					</Modal>
					<div className="col-12">
						<div className="page_data page_data__background">
							<button className="btn btn-primary" style={{backgroundColor:"#47abce",margin:"0 5px"}} name="Summary" onClick={this.changeView}>Summary</button>
							<button className="btn btn-primary" style={{backgroundColor:"#47abce",margin:"0 5px"}} name="Comments" onClick={this.changeView}>Comments</button>
							<button className="btn btn-primary" style={{backgroundColor:"#47abce",margin:"0 5px"}} name="PaymentTerms" onClick={this.changeView}>Payment Terms</button>
							<button className="btn btn-primary" style={{backgroundColor:"#47abce",margin:"0 5px"}} name="BillingSection" onClick={this.changeView}>Billing</button>
							{this.props.user.level>=1700?<button className="btn btn-primary" style={{backgroundColor:"#47abce",margin:"0 5px"}} name="CertificateSection" onClick={this.changeView}>Certificate</button>:''}
							<button className="btn btn-primary" style={{backgroundColor:"#47abce",margin:"0 5px"}} name="OtherDetails" onClick={this.changeView}>Other Details</button>
							<button className="btn btn-primary" style={{backgroundColor:"#47abce",margin:"0 5px"}} name="VendorPayment" onClick={this.changeView}>Vendor Payment</button>
							{/* <b style={(this.state.isPrime)?style.show:style.hide} className="primeCust">PRIME CUSTOMER</b>
							{(this.props.user.level ==1900 || this.props.user.level == 2300 || this.props.user.level >=2500) ?
								<span>
									<button name="markZunprime" style={( this.state.isPrime) ? style.hide : style.show} onClick={(e) => this.markZunprime(e)} className="btn btn-success markZunprimeButton">Mark Zunprime</button>
									<button name="removeZunprime" style={(this.state.isPrime) ? style.show : style.hide} onClick={(e) => this.markZunprime(e)} className="btn btn-warning markZunprimeButton">Remove Zunprime</button>
								</span> 

								: ""} */}
							<div
								// style={styleSheet.addComment}
								style={viewContent === "Comments" ? styleSheet.addCommentQuoteSection : style.hide}
								onMouseEnter={this.hoverOn}
								onMouseLeave={this.hoverOff}>
								<i style={this.state.hover ? styleSheet.plusCircleOnHover : styleSheet.plusCircle} onClick={this.onOpenModal} className="fas fa-plus-circle"></i>
							</div>
						</div>
						<div className="page_data page_data__background" style={viewContent==="Summary"?style.show:style.hide}>
							<div className="row row_data">
								<div className="col-md-4 page_data__1">
									<div className="row_head">
										<div className="row row_data">User Details</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Phone :  </span>
										<span className="row_data__value col-8"> {phone}</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Email : </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.email}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.email?this.state.email:""} onChange={this.handleInputEmailChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Address :  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.address_D}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.address_D?this.state.address_D:""} onChange={this.handleInputAddressChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">City :  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.city}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
												<select className="custom-select"  value ={this.state.city} onChange={this.handleSelectCityChange}>
												<option>Select City</option>
												{
													this.props.stateToCityMap[this.state.state_D?this.state.state_D:"-Not Selected-"].map(city => {
														return (
															<option key={city} value={city}>{city}</option>
														)
													})
												}
											</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">State: </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.state_D}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.props.stateD.indexOf(this.state.state_D)} onChange={this.handleInputStateDChange}>
											  	<option>Select State</option>
												{
													this.props.stateD.map((state,index) => {
														return (
															<option key={index} value={index}>{state}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Lat-Lon :  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.latlon_D}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.latlon_D?this.state.latlon_D:""} onChange={this.handleInputLatLonChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Pincode :  </span>
										<span className="row_data__value col-8"><span style={this.state.edit?style.hide:style.show}>{this.state.pincode}</span>
													<span style={this.state.edit?style.show:style.hide} className="input-group">
														<input className="form-control" value ={this.state.pincode?this.state.pincode:""} name="pincode" onChange={this.handleSingleInputChange} type="number" />
														<div className="input-group-append">
													    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
													  	</div>
													</span></span>

									</div>
									<div className="row_head">
										<div className="row row_data">EB Details</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Holder Name</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.connectionHolderName}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.connectionHolderName?this.state.connectionHolderName:""} onChange={this.handleInputConnectionHolderNameChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Discom</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.discom}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
												<select className="custom-select"  value ={this.state.discom?this.state.discom:""} name="discom" onChange={this.handleSingleInputChange}>
												<option>Select Discom</option>
												{
													this.props.stateToDiscomMap[this.state.state_D?this.state.state_D:"-Not Selected-"].map(discom => {
														return (
															<option key={discom} value={discom}>{discom}</option>
														)
													})
												}
											</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Phase</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.phase_D}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.phase_D} onChange={this.handleInputPhaseChange}>
											  	<option>Select Phase</option>
												{
													["1","3"].map((phase) => {
														return (
															<option key={phase} value={phase}>{phase}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
									<span className="row_data__key col-4">Load</span>
									<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
            							onMouseLeave={this.hoverOff}>
										<span style={this.state.edit?style.hide:style.show}>{this.state.sanctioned_Load_D}</span>
										<span style={this.state.edit?style.show:style.hide} className="input-group">

											<input className="form-control" value ={this.state.sanctioned_Load_D?this.state.sanctioned_Load_D:""} onChange={this.handleInputSanctionedLoadChange} type="number" />
											<div className="input-group-append">
										    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										  	</div>
										</span>

									</span>
								</div>
								<div className="row row_data">
										<span className="row_data__key col-4">EB Issue</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.ebissue.toString()}</span>
											<span className="input-group save_control" style={this.state.edit?style.show:style.hide}>
											  <div style={{width:'79%'}}><Select
												isMulti
										        value={selectedOption}
										        onChange={this.handleInputEBIssueChange}
										        options={this.state.ebIssueList}
										      /></div>
											<div className="input-group-append">
										    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">EB Issue Applied Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.ebIssueAppliedDate?changeToViewDate(this.state.ebIssueAppliedDate):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control"  onChange={this.handleInputEbAppliedDateChange} type="date" value={inputDateFormatChange(this.state.ebIssueAppliedDate)} max="3000-12-31" min="1000-01-01" placeholder="dd/mm/yyyy"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">EB Issue Resolution Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.ebIssueResolutionDate?changeToViewDate(this.state.ebIssueResolutionDate):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control"  onChange={this.handleInputEbResolutionDateChange} type="date" value={inputDateFormatChange(this.state.ebIssueResolutionDate)} max="3000-12-31" min="1000-01-01" placeholder="dd/mm/yyyy"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Loan Issue</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.loanissue}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.loanissue} onChange={this.handleInputLoanIssueChange}>
											  	<option>Select Loan Issue</option>
												{
													["Yes", "No", "Yes And Granted", "Yes And Declined", "Converted to Normal", "In Process"].map(loanissue => {
														return (
															<option key={loanissue} value={loanissue}>{loanissue}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Loan Provider</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.loanProvider}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.loanProvider} onChange={this.handleInputLoanProviderChange}>
											  	<option>Select Loan Provider</option>
												{
													["CreditFair", "Ecofy", "SBI", "Canara", "PNB", "BOB", "Bank of India", "Union Bank of India", "Indian Bank", "UCO Bank", "Capri Global"].map(providerOption => {
														return (
															<option key={providerOption} value={providerOption}>{providerOption}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Loan Resolution Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.loanResolutionDate?changeToViewDate(this.state.loanResolutionDate):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control"  onChange={this.handleInputLoanResolutionDateChange} type="date" value={inputDateFormatChange(this.state.loanResolutionDate)} max="3000-12-31" min="1000-01-01" placeholder="dd/mm/yyyy"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Moved By</span>
										{
											this.props.user.level>=1900?
											<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
		            							onMouseLeave={this.hoverOff}>
												<span style={this.state.edit?style.hide:style.show}>{this.state.idMovedBy_D}</span>
												<span style={this.state.edit?style.show:style.hide} className="input-group">
													<input className="form-control" name="idMovedBy_D" value ={this.state.idMovedBy_D?this.state.idMovedBy_D:""} onChange={this.handleSingleInputChange} type="text" />
													<div className="input-group-append">
												    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
												  	</div>
												</span>
											</span>
											: <span className="row_data__value col-8"> {this.state.idMovedBy_D}</span>
										}
									</div>
								</div>
								<div className="col-md-4 page_data__2">
								<div className="row_head">
										<div className="row row_data">Internal</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Responsible</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
				            							onMouseLeave={this.hoverOff}>
														<span style={this.state.edit?style.hide:style.show}>{this.state.responsibleName}</span>
														<span className="input-group" style={this.state.edit?style.show:style.hide}>
															<select className="custom-select"  value ={this.state.teamId} onChange={this.handleTeamChange}>
															  	<option>Team</option>
															{
																Object.keys(this.props.teamEmployeeMap?this.props.teamEmployeeMap:{}).map( data => {
																	return (
																		<option key={data} value={data}>{this.props.teamEmployeeMap[""+data].name}</option>
																	)
																})
															}
															</select>
															<select className="custom-select"  value ={this.state.responsible} onChange={this.handleResponsibleChange}>
															  	<option>Responsible</option>
															{
																this.state.teamId?((this.props.teamEmployeeMap[""+this.state.teamId]||{}).employeelist||[]).map( data => {
																	return (
																		<option key={data} value={data}>{this.props.employeeMap[""+data]}</option>
																	)
																}):""
															}
															</select>
															<div className="input-group-append">
														    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
														  	</div>
														</span>
													</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Action Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.dateNADChange?changeToViewDate(this.state.dateNADChange):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control"  onChange={this.handleInputDateChange} type="date" value={inputDateFormat(this.state.dateNADChange)} max="3000-12-31" min="1000-01-01"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Payment Link :  </span>
										<span className="row_data__value col-8"><a href={`https://zunroof.com/zunpay/${this.props.siteid}`} target="_blank">{`https://zunroof.com/zunpay/${this.props.siteid}`}</a></span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Next Action</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.nextAction}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control" value ={this.state.nextAction?this.state.nextAction:""} onChange={this.handleInputNextActionChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row_head">
										<div className="row row_data">System Details</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Size</span>
										{
											this.props.user.level>=1900?(
												<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
			            							onMouseLeave={this.hoverOff}>
													<span style={this.state.edit?style.hide:style.show}>{this.state.size}</span>
													<span style={this.state.edit?style.show:style.hide} className="input-group">
														<input className="form-control" value ={this.state.size?this.state.size:""} name="size" onChange={this.handleSingleInputChange} type="number" />
														<div className="input-group-append">
													    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
													  	</div>
													</span>

												</span>
											):(<span className="row_data__value col-8"> {size} </span>)
										}
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Size_del</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
											onMouseLeave={this.hoverOff}>
											<span style={this.state.edit ? style.hide : style.show}>{this.state.size_del}</span>
											<span style={this.state.edit ? style.show : style.hide} className="input-group">
												<input className="form-control" value={this.state.size_del ? this.state.size_del : ""} name="size_del" onChange={this.handleSingleInputChange} type="number" />
												<div className="input-group-append">
													<button className="btn btn-outline-secondary" style={this.state.edit ? style.show : style.hide} onClick={this.handleSaveClick}>Save</button>
												</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Property Type</span>
										<span className="row_data__value col-8"> {propertytype} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Subsidy Type</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.subsidy_D}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.props.subsidyD.indexOf(this.state.subsidy_D)} onChange={this.handleInputSubsidyDChange}>
											  	<option>Select Subsidy</option>
												{
													this.props.subsidyD.map((subsidy,index) => {
														return (
															<option key={index} value={index}>{subsidy}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Subsidy Status</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.subsidyStatus}
											</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.subsidyStatus} onChange={this.handleSubsidyStatusInputChange}>
											  	<option>Select Subsidy Status</option>
												{
													this.props.subsidyStatusMap.map((subsidyStatus,index) => {
														return (
															<option key={subsidyStatus} value={subsidyStatus} disabled={this.props.subsidyStatusMap.indexOf(subsidyStatus) > this.props.subsidyStatusMap.indexOf(this.state.subsidyStatusCheck) + 1}>{subsidyStatus}</option>
														)
													})
												}
												</select>
												<div className="input-group-append" style={{"pointer-events": this.state.mandatoryAttachment?"auto":"none"}}>
													<label for="subsidyStatusUpload"><i className="fa fa-paperclip fa-lg btn"></i></label>
													<input type="file" id="subsidyStatusUpload"  data-folderType="Subsidy Status" onChange={this.handleOnUpload} style={{display:"none"}}/>
												</div>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									{
										this.state.subsidy_D != "No Subsidy" ? <div className="row row_data">
											<span className="row_data__key col-4">Tender Year : </span>
											<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
												onMouseLeave={this.hoverOff}>
												<span style={this.state.edit ? style.hide : style.show}>{this.state.tenderYear}</span>
												<span className="input-group" style={this.state.edit ? style.show : style.hide}>
													<select className="custom-select" value={this.state.tenderYear} onChange={this.handleTenderYearChange}>
														<option value="">Select</option>
														{
															this.state.tenderYearOptions.map(option => {
																return (
																	<option key={option} value={option}>{option}</option>
																)
															})
														}
													</select>
													<div className="input-group-append">
														<button className="btn btn-outline-secondary" style={this.state.edit ? style.show : style.hide} onClick={this.handleSaveClick}>Save</button>
													</div>
												</span>
											</span>
										</div> : ""
									}
									<div className="row row_data">
										<span className="row_data__key col-4">Applied Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.subsidyAppliedDate?changeToViewDate(this.state.subsidyAppliedDate):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control"  onChange={this.handleSubsidyStatusDateInputChange} type="date" value={inputDateFormat(this.state.subsidyAppliedDate)} max="3000-12-31" min="1000-01-01" placeholder="dd/mm/yyyy"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Approval Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.subsidyApprovalDate?changeToViewDate(this.state.subsidyApprovalDate):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control"  onChange={this.handleSubsidyApprovalDateInputChange} type="date" value={inputDateFormatChange(this.state.subsidyApprovalDate)} max="3000-12-31" min="1000-01-01" placeholder="dd/mm/yyyy"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">System Type</span>
										<span className="row_data__value col-8"> {type} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Elevation</span>
										<span className="row_data__value col-8"> {elevationtype} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Elevation Details  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.elevationdetails}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.elevationdetails?this.state.elevationdetails:""} onChange={this.handleInputElevationDetailsChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Site visit</span>
										<span className="row_data__value col-8"> {this.props.siteVisitStatus.siteVisited} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Date</span>
										<span className="row_data__value col-8"> {(this.props.siteVisitStatus.visitTime)?(changeToViewDate(parseInt(this.props.siteVisitStatus.visitTime))):''} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Stage </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.stage}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.props.stages.indexOf(this.state.stage)} onChange={this.handleInputStageChange}>
											  	<option>Select Stage</option>
												{
													this.props.stages.map((stage,index) => {
														if(["Net Metering"].indexOf(stage)>-1) {
															return (
																<option key={index+1} value={index+1} disabled={index >= blockedFromIndex}>{"P4 Remaining"}</option>
															)
														}else if(["P4 Remaining"].indexOf(stage)>-1) {
															return (
																<option key={index-1} value={index-1} disabled={this.state.stage != 'Commission' && index >= blockedFromIndex}>{"Net Metering"}</option>
															)
														}else if(["Truncated","Canceled","Serviced"].indexOf(stage)>-1){
															if(this.props.user.level>=1900){
																return (
																	<option key={index} value={index}>{stage}</option>
																)
															}
														}else if(["Delivered"].indexOf(stage)>-1){
															if(this.props.user.level===1900 || this.props.user.level===1700 || this.props.user.level>=2300){
																return (
																	<option key={index} value={index} disabled={index >= blockedFromIndex}>{stage}</option>
																)
															}
														}else{
															return (
																<option key={index} value={index} disabled={index >= blockedFromIndex && index < 8}>{stage}</option>
															)
														}

													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Quality Visit : </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.qualityVisit}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.qualityVisit} onChange={this.handleQualityVisitChange}>
											  	<option value="">Select</option>
												{
													["Pending", "Completed"].map(option => {
														return (
															<option key={option} value={option}>{option}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Sales Date </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.dateSDChange?changeToViewDate(this.state.dateSDChange):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control"  onChange={this.handleInputSalesDateChange} type="date" value={inputDateFormat(this.state.dateSDChange)} max="3000-12-31" min="1000-01-01" placeholder="dd/mm/yyyy"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									{/* INVOICE PART */}
									<div className="row row_data">
										<span className="row_data__key col-4">Invoice No.</span>
										<span className="row_data__value col-8">
											<span style={this.props.user.level>=1800?(this.state.edit?style.hide:style.show):style.show}>{this.state.invoiceText?this.state.invoiceText:""}</span>
											<span style={this.state.edit&&this.props.user.level>=1800?style.show:style.hide} className="input-group">

												<input className="form-control"  onChange={this.handleSingleInputChange} name = "invoiceText" type="text" value={this.state.invoiceText} />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Invoice Date </span>
										<span className="row_data__value col-8">
											<span style={this.props.user.level>=1800?(this.state.edit?style.hide:style.show):style.show}>{this.state.invoiceDate?changeToViewDate(this.state.invoiceDate):""}</span>
											<span style={this.state.edit&&this.props.user.level>=1800?style.show:style.hide} className="input-group">
												<input className="form-control"  onChange={this.handleSingleInputChange} name = "invoiceDate" type="date" value={this.state.invoiceDate?inputDateFormat(this.state.invoiceDate):inputDateFormat(new Date())} max="3000-12-31" min="1000-01-01"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									{(this.state.state_D=='Telangana'||this.state.state_D=='Karnataka'||this.state.state_D=='Delhi')?<div className="row row_data">
										<span className="row_data__key col-4">Feasibility</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.feasibility}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.feasibility} onChange={this.handleFeasibilityChange}>
											  	<option>Select Status</option>
												{
													["Not Applied", "Applied", "Approved"].map(status => {
														return (
															<option key={status} value={status}>{status}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>:""}
									{(this.state.state_D=='Karnataka')?<div className="row row_data">
										<span className="row_data__key col-4">PPA</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.ppa}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.ppa} onChange={this.handlePPAChange}>
											  	<option>Select Status</option>
												{
													["Not Applied", "Applied", "Approved"].map(status => {
														return (
															<option key={status} value={status}>{status}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>:""}
								</div>
								<div className="col-md-4 page_data__3">
									<div className="row_head">
										<div className="row row_data">System Details</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">V1 </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.vendor}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
												  	<select className="custom-select"  value ={this.state.vendor} onChange={this.handleSelectVendor1Change}>
												  	<option>Select Vendor</option>
												{
													this.vendor1.map(vendor1 => {
														return (
															<option key={vendor1} value={vendor1}>{vendor1}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">V2 </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
										<span style={this.state.edit?style.hide:style.show}>{this.state.inc}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
												  	<select className="custom-select"  value ={this.state.inc} onChange={this.handleSelectVendor2Change}>
												  	<option>Select InC</option>
												{
													this.state.vendorList.map(vendor2 => {
														return (
															<option key={vendor2} value={vendor2}>{vendor2}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">V2 Handover Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.v2HandoverDate?changeToViewDate(this.state.v2HandoverDate):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control"  onChange={this.handleInputV2HandoverDateChange} type="date" value={inputDateFormatChange(this.state.v2HandoverDate)} max="3000-12-31" min="1000-01-01"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Structure Vendor</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
										<span style={this.state.edit?style.hide:style.show}>{this.state.structureVendorSelected?this.state.structureVendors[this.state.structureVendorSelected]:''}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
												  	<select className="custom-select"  value ={this.state.structureVendorSelected} onChange={this.handleSelectStructureVendorChange}>
												  	<option>Select Vendor</option>
												{
													Object.keys(this.state.structureVendors).map(vendor2 => {
														return (
															<option key={vendor2} value={vendor2}>{vendor2?this.state.structureVendors[vendor2]:''}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Structure Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.dateStructureChange?changeToViewDate(this.state.dateStructureChange):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control"  onChange={this.handleInputStructureDateChange} type="date" value={inputDateFormatChange(this.state.dateStructureChange)} max="3000-12-31" min="1000-01-01"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Panel Promised</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.promisedPanel}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.promisedPanel?this.state.promisedPanel:""} onChange={this.handleInputPromisedPanelChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Panel Vendor</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.panelVendorEditOption?(this.state.edit?style.hide:style.show):style.show}>{this.state.panelVendorSelected.name}</span>
											<span className="btn-group" style={!this.state.panelVendorEditOption&&!this.state.panelOtpVerifiedStatus?style.show:style.hide}>
												<button className="btn btn-outline-secondary" onClick = {this.handleGenerateOtpClick}>Generate</button>
												<button className="btn btn-outline-secondary" onClick = {this.handleVerifyOtpClick}>Verify</button>
											</span>
											<div style={this.state.verifyOptionClicked?style.show:style.hide}>
											<div class="input-group mb-3" style={{width:'50%',margin:'0 auto'}}>
												<input type="text" className="form-control" placeholder="OTP" onChange={this.handleInputOtpChange}/>
												<div class="input-group-append">
													<button class="btn btn-outline-secondary" onClick={this.handleSubmitOtpClick}>Submit</button>
												</div>
											</div>
											</div>
											<span className="input-group" style={this.state.panelVendorEditOption?(this.state.edit?style.show:style.hide):style.hide}>
												  	<select className="custom-select" value={this.state.panelVendorSelected.id} onChange={this.handlePanelVendorChange}>
												  	<option value="0">Select Panel Vendor</option>
												{

													this.state.panelVendorOptions.map((val,index) => {
														return (
															<option key={index} value={val.id}>{val.name}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Panel_Del</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.deliveredpanel}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.deliveredpanel?this.state.deliveredpanel:""} onChange={this.handleInputDeliveredPanelChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Panel Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={(this.state.edit&&this.props.user.level>=1900)?style.hide:style.show}>{this.state.datePanelChange?changeToViewDate(this.state.datePanelChange):""}</span>
											<span style={(this.state.edit&&this.props.user.level>=1900)?style.show:style.hide} className="input-group">
												<input className="form-control"  onChange={this.handleInputPanelDateChange} type="date" value={inputDateFormatChange(this.state.datePanelChange)} max="3000-12-31" min="1000-01-01"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Panel Status :  </span>
										<span className="row_data__value col-8"> {this.props.panelDeliveryStatus}</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Inverter Promised</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.promisedInverter}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.promisedInverter?this.state.promisedInverter:""}onChange={this.handleInputPromisedInverterChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Inverter_Del</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.deliveredinverter}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control" value ={this.state.deliveredinverter?this.state.deliveredinverter:""} onChange={this.handleInputDeliveredInverterChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Inverter Phase</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.inverterPhase}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.inverterPhase} onChange={this.handleInputInverterPhaseChange}>
											  	<option>Select Inverter Phase</option>
												{
													["Single Phase", "Three Phase"].map(phaseOption => {
														return (
															<option key={phaseOption} value={phaseOption}>{phaseOption}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Inverter Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.dateInverterChange?changeToViewDate(this.state.dateInverterChange):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control"  onChange={this.handleInputInverterDateChange} type="date" value={inputDateFormatChange(this.state.dateInverterChange)} max="3000-12-31" min="1000-01-01"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Inverter Status :  </span>
										<span className="row_data__value col-8"> {this.props.inverterDeliveryStatus}</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Inverter Courier Partner</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.inverterCourierPartner}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.inverterCourierPartner} onChange={this.handleInputInverterCourierPartnerChange}>
											  	<option>Select Courier Partner</option>
												{
													["Bluedart", "Tirupati", "Gati", "Porter", "DTDC", "Delhivery", "Xpressbees", "SafeXpress", "Gracious", "VRL", "Trackon", "DP WORLD", "Ecom Express", "Amazon"].map(partner => {
														return (
															<option key={partner} value={partner}>{partner}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Tracking Id</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.trackingId}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
												<input className="form-control"  onChange={this.handleTrackingIdChange} type="text" value={this.state.trackingId} />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Net Metering Date</span>
										{
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}	onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.netMetringDate?changeToViewDate(this.state.netMetringDate):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control"  onChange={this.handleInputNetMeteringDateChange} type="date" value={inputDateFormatChange(this.state.netMetringDate)} max="3000-12-31" min="1000-01-01"/>
												{/* <div className="input-group-append">
													<button className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleDeleteDateClick}><i className="fa fa-trash fa-lg btn"></i></button>
												</div> */}
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
										}
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Net Metering Applied Date</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.netMeteringAppliedDate?changeToViewDate(this.state.netMeteringAppliedDate):""}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">

												<input className="form-control"  onChange={this.handleInputNetMeteringAppliedDateChange} type="date" value={inputDateFormatChange(this.state.netMeteringAppliedDate)} max="3000-12-31" min="1000-01-01" placeholder="dd/mm/yyyy"/>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Net Metering</span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
														<span style={this.state.edit?style.hide:style.show}>{this.state.netMeteringStage}
														</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.netMeteringStage} onChange={this.handleInputNetMeteringStageChange}>
											  	<option>Select Net Metering Stage</option>
												{
													this.props.netMeteringStagesMap.map((netMeteringStage,index) => {
														return (
															<option key={netMeteringStage} value={netMeteringStage} disabled={this.props.netMeteringStagesMap.indexOf(netMeteringStage) > this.props.netMeteringStagesMap.indexOf(this.state.netMeteringStagesCheck) + 1}>{netMeteringStage}</option>
														)
													})
												}
												</select>
												<div className="input-group-append" style={{"pointer-events": this.state.mandatoryAttachment?"auto":"none"}}>
													<label for="netMeteringUpload"><i className="fa fa-paperclip fa-lg btn"></i></label>
													<input type="file" id="netMeteringUpload"  data-folderType="Net Metering" onChange={this.handleOnUpload} style={{display:"none"}}/>
												</div>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">ACDB/DCDB : </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.acdbDcdb}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select"  value ={this.state.acdbDcdb} onChange={this.handleAcdbDcdbChange}>
											  	<option value="">Select</option>
												{
													["ZunRoof", "Other"].map(option => {
														return (
															<option key={option} value={option}>{option}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Video Url : </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
	            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{this.state.customerVideoUrl}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
												<input className="form-control"  onChange={this.handleCustomerVideoUrlChange} type="text" value={this.state.customerVideoUrl} />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Commissioning Date</span>
										<span className="row_data__value col-8"> {this.props.system.commissioningDate?changeToViewDate(this.props.system.commissioningDate):""}</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Delayed :  </span>
										<span className="row_data__value col-8"> {this.props.isProjectDelayed}</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Special Req.</span>
										{
											this.props.user.level>=1900?(
												<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
			            							onMouseLeave={this.hoverOff}>
													<span style={this.state.edit?style.hide:style.show}>{this.state.specialRequirement}</span>
													<span style={this.state.edit?style.show:style.hide} className="input-group">
														<textarea  className="form-control" value ={this.state.specialRequirement?this.state.specialRequirement:""} name="specialRequirement" onChange={this.handleSingleInputChange} ></textarea>
														<div className="input-group-append">
													    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
													  	</div>
													</span>

												</span>
											):(<span className="row_data__value col-8"> {this.state.specialRequirement} </span>)
										}
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Delivery Comment :  </span>
										<span className="row_data__value col-8"> {this.props.deliveryComment}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="page_data" style={viewContent==="Comments"?style.show:style.hide}>
							{/* <FrameFour user={this.props.user} setquotedata = {this.props.setquotedata} siteid={this.props.siteid} data = {this.props.commentsData}/> */}
							<ReactTable
								columns={columnsPerComments}
								defaultPageSize={5}
								data={this.state.commentsData}
								defaultSorted={[{ id: "time", desc: true }]}
							/>
						</div>
						<div className="page_data page_data__background" style={viewContent==="PaymentTerms"?style.show:style.hide}>
							<div className="row row_data">
								<span className="col-2">Payment Terms</span>
								<span className="col-10" style={{"textAlign": "left"}}>
									<span style={{"whiteSpace": "pre-line"}}>{this.state.paymentTerms}</span>
								</span>
							</div>
						</div>
						<div className="page_data page_data__background" style={viewContent==="BillingSection"?style.show:style.hide}>
							<div className="row">
								<div className="col-md-4 page_data__1">
									<div className="row_head">
										<div className="row row_data">Billing Section</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Billing Name :  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{connectionHolderName_B}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control" value = {connectionHolderName_B?connectionHolderName_B:""} name="connectionHolderName_B" onChange={this.handleSingleInput_DChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">GST_B :  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{GST_B}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control" value = {GST_B?GST_B:""} name="GST_B" onChange={this.handleSingleInput_DChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">BillingAddress_B :  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{BillingAddress_B}</span>
											<span style={this.state.edit?style.show:style.hide} className="input-group">
												<input className="form-control" value = {BillingAddress_B?BillingAddress_B:""} name="BillingAddress_B" onChange={this.handleSingleInput_DChange} type="text" />
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">State_B :  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{State_B}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
											  	<select className="custom-select" name="State_B" value ={State_B} onChange={this.handleSingleInput_DChange}>
											  	<option>Select State</option>
												{
													this.props.stateD.map((state,index) => {
														return (
															<option key={index} value={state}>{state}</option>
														)
													})
												}
												</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">City_B :  </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
		            							onMouseLeave={this.hoverOff}>
											<span style={this.state.edit?style.hide:style.show}>{City_B}</span>
											<span className="input-group" style={this.state.edit?style.show:style.hide}>
												<select className="custom-select" name="City_B" value ={City_B} onChange={this.handleSingleInput_DChange}>
												<option>Select City</option>
												{
													this.props.stateToCityMap[State_B?State_B:"-Not Selected-"].map(city => {
														return (
															<option key={city} value={city}>{city}</option>
														)
													})
												}
											</select>
												<div className="input-group-append">
											    	<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
											  	</div>
											</span>
										</span>
									</div>
								</div>
								<div className="col-md-6 page_data__1">
									<div className="row_head">
										<br/>
									</div>
									<div className="row row_data">

									</div>
									<div className="row row_data">
										Connection holder name in case of subsidy related order, Company name is not applicable in subsidy related order
									</div>
									<div className="row row_data">
										Not applicable in subsidy related order
									</div>
									<div className="row row_data">

									</div>
									<div className="row row_data">

									</div>
								</div>
							</div>
						</div>
						{this.props.user.level>=1700?<div className="page_data page_data__background" style={viewContent==="CertificateSection"?style.show:style.hide}>

								<div className="row">
									{/* <div className="col-2">
										AMC Period:
									</div> */}

								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>AMC Period : {this.state.amcPeriod}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										AMC Period :
										<input className="form-control" name="amcPeriod" value ={this.state.amcPeriod?this.state.amcPeriod:""} onChange={(e) => this.handleCertificateFormChange(-1,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div>
								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>Years(90%) : {this.state.warrantyYears90}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										Years(90%) :
										<input className="form-control" name="warrantyYears90" value ={this.state.warrantyYears90?this.state.warrantyYears90:""} onChange={(e) => this.handleCertificateFormChange(-1,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div>
								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>Years(80%) : {this.state.warrantyYears80}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										Years(80%) :
										<input className="form-control" name="warrantyYears80" value ={this.state.warrantyYears80?this.state.warrantyYears80:""} onChange={(e) => this.handleCertificateFormChange(-1,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div>
								</div>
							<div className="row" style={{marginTop:'16px', fontSize:'16px'}}>
								<div className="col-2">
									Item
								</div>
								<div className="col-2">
									Make
								</div>
								<div className="col-2">
									Capacity Of Item Listed
								</div>
								<div className="col-2">
									Warranty
								</div>
								{/* <div className="col-2">
									Test Laboratory
								</div> */}
								{/* <div className="col-2">
									Test Report No. and Date
								</div> */}
							</div>
							<div className="row" style={{marginTop:'12px'}}>
								<div className="col-2">
									{/* <span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[0].item}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="item" value ={this.state.certificateData[0].item?this.state.certificateData[0].item:""} onChange={(e) => this.handleCertificateFormChange(0,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span> */}
									{this.state.certificateData[0].item}
								</div>
								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[0].make}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="make" value ={this.state.certificateData[0].make?this.state.certificateData[0].make:""} onChange={(e) => this.handleCertificateFormChange(0,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div>
								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[0].capacity}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="capacity" value ={this.state.certificateData[0].capacity?this.state.certificateData[0].capacity:""} onChange={(e) => this.handleCertificateFormChange(0,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div>
								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[0].warranty}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="warranty" value ={this.state.certificateData[0].warranty?this.state.certificateData[0].warranty:""} onChange={(e) => this.handleCertificateFormChange(0,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div>
								{/* <div className="col-2">
									{this.state.certificateData[0].testLab}
								</div> */}
								{/* <div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[0].testReportNo}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="testReportNo" value ={this.state.certificateData[0].testReportNo?this.state.certificateData[0].testReportNo:""} onChange={(e) => this.handleCertificateFormChange(0,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div> */}
							</div>
							<div className="row" style={{marginTop:'12px'}}>
								<div className="col-2">
									{/* <span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[1].item}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="item" value ={this.state.certificateData[1].item?this.state.certificateData[1].item:""} onChange={(e) => this.handleCertificateFormChange(1,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span> */}
									{this.state.certificateData[1].item}
								</div>
								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[1].make}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="make" value ={this.state.certificateData[1].make?this.state.certificateData[1].make:""} onChange={(e) => this.handleCertificateFormChange(1,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div>
								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[1].capacity}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="capacity" value ={this.state.certificateData[1].capacity?this.state.certificateData[1].capacity:""} onChange={(e) => this.handleCertificateFormChange(1,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div>
								<div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[1].warranty}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="warranty" value ={this.state.certificateData[1].warranty?this.state.certificateData[1].warranty:""} onChange={(e) => this.handleCertificateFormChange(1,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>

								</div>
								{/* <div className="col-2">
									{this.state.certificateData[1].testLab}
								</div> */}
								{/* <div className="col-2">
									<span style={this.state.edit?style.hide:style.show}>{this.state.certificateData[1].testReportNo}</span>
									<span style={this.state.edit?style.show:style.hide} className="input-group">
										<input className="form-control" name="testReportNo" value ={this.state.certificateData[1].testReportNo?this.state.certificateData[1].testReportNo:""} onChange={(e) => this.handleCertificateFormChange(1,e)} type="text" />
										<div className="input-group-append">
											<button  className="btn btn-outline-secondary" style={this.state.edit?style.show:style.hide}  onClick = {this.handleSaveClick}>Save</button>
										</div>
									</span>
								</div> */}
							</div>
							<div style={{marginTop:'12px'}}>
								<button  className="btn btn-success" onClick = {(e) => this.generateCertificate()}>Generate Certificate</button>
							</div>
						</div>:''}
						<div className="page_data page_data__background" style={viewContent === "OtherDetails" ? style.show : style.hide}>
							<div className="row row_data">
								<div className="col-md-4 page_data__1">
									<div className="row_head">
										<div className="row row_data">AMC Details</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Plan Type :  </span>
										<span className="row_data__value col-8">{amcData.planType}</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Start Date :  </span>
										<span className="row_data__value col-8"> {amcData.startDate}</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">End Date :  </span>
										<span className="row_data__value col-8"> {amcData.endDate}</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Payment :  </span>
										<span className="row_data__value col-8"> {amcData.payment}</span>
									</div>
									<div className="row_head">
										<div className="row row_data">MPP</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">MPP Verification Status</span>
										{(this.props.user.level >= 2500 || this.props.user.level === 1950) ? <span className="row_data__value col-8" onMouseEnter={this.hoverOn}
											onMouseLeave={this.hoverOff}>
											<span style={this.state.edit ? style.hide : style.show}>{this.state.mppVerificationStatus}</span>
											<span className="input-group" style={this.state.edit ? style.show : style.hide}>
												<select className="custom-select" value={this.state.mppVerificationStatus} onChange={this.handleMppVerificationStatusChange}>
													<option>Select Status</option>
													{
														["Documents Not Uploaded", "In Review", "Order Verified", "Rejected"].map(status => {
															return (
																<option key={status} value={status}>{status}</option>
															)
														})
													}
												</select>
												<div className="input-group-append">
													<button className="btn btn-outline-secondary" style={this.state.edit ? style.show : style.hide} onClick={this.handleSaveClick}>Save</button>
												</div>
											</span>

										</span> : <span className="row_data__value col-8"> {this.state.mppVerificationStatus}</span>}
									</div>
								</div>
								<div className="col-md-4 page_data__1">
									<div className="row_head">
										<div className="row row_data">System Details</div>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Ref Date </span>
										<span className="row_data__value col-8" onMouseEnter={this.hoverOn}
											onMouseLeave={this.hoverOff}>
											<span style={this.state.edit ? style.hide : style.show}>{this.state.dateRDChange ? changeToViewDate(this.state.dateRDChange) : ""}</span>
											<span style={this.state.edit ? style.show : style.hide} className="input-group">

												<input className="form-control" onChange={this.handleInputRefDateChange} type="date" value={inputDateFormat(this.state.dateRDChange)} max="3000-12-31" min="1000-01-01" />
												<div className="input-group-append">
													<button className="btn btn-outline-secondary" style={this.state.edit ? style.show : style.hide} onClick={this.handleSaveClick}>Save</button>
												</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">MainT. Req.</span>
										<span className="row_data__value col-8">
											<span style={this.props.user.level >= 1600 ? (this.state.edit ? style.hide : style.show) : style.show}>{this.state.maintenanceRequired}</span>
											<span style={this.state.edit && this.props.user.level >= 1600 ? style.show : style.hide} className="input-group">
												<select className="custom-select" name="maintenanceRequired" value={this.state.maintenanceRequired} onChange={this.handleSingleInputChange}>
													<option value="Not Selected">Not Selected</option>
													<option value="Yes">Yes</option>
													<option value="No">No</option>
												</select>
												<div className="input-group-append">
													<button className="btn btn-outline-secondary" style={this.state.edit ? style.show : style.hide} onClick={this.handleSaveClick}>Save</button>
												</div>
											</span>

										</span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">RMS Installed</span>
										<span className="row_data__value col-8"> {this.props.system.rmsInstalled} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">RMS Status</span>
										<span className="row_data__value col-8"> {this.props.system.rmsStatus} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">RMS Device</span>
										<span className="row_data__value col-8"> {this.props.system.rmsDeviceId} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">RMS Type</span>
										<span className="row_data__value col-8"> {this.props.system.rmsDeviceType} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">RMS Installation Date</span>
										<span className="row_data__value col-8"> {this.props.system.rmsInstallationDate} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">WhatsApp Status</span>
										<span className="row_data__value col-8"> {this.props.system.whatsappStatus || "Have Not Opted"} </span>
									</div>
									<div className="row row_data">
										<span className="row_data__key col-4">Chat Link : </span>
										<span className="row_data__value col-8">{this.props.system.whatsappId?<a href={`http://zrm.zunroof.com/whatsapp/dashboard/${this.props.system.whatsappId}`} target="_blank">{`http://zrm.zunroof.com/whatsapp/dashboard/${this.props.system.whatsappId}`}</a>:""}</span>
									</div>
								</div>
								<div className="col-md-4 page_data__1">
								</div>
							</div>
						</div>
						<div className="page_data" style={viewContent === "VendorPayment" ? style.show : style.hide}><ReactTable 
							columns={vendorPaymentQuoteIdColumns}
							defaultPageSize={5}
							data = {this.state.vendorPayments || []}
							defaultSorted={[{id: "date",desc: true}]}
						/></div>
					</div>
				</div>
			</div>
		);
	}
}
